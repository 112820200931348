

export type bulletSize = 'sm' | 'md' | 'lg' 
export type bulletColor = 'neutral' | 'primary' | 'success'
export type bulletSymbol = 'check' | 'number' | 'dot'

export interface BulletPointProps {
    mapLength: number,
    stepNumber: number,
    size: bulletSize,
    color: bulletColor,
    symbol: bulletSymbol,
    valueToCompare : number
}

export function getBulletSize( size: bulletSize){
    switch(size){
        case 'sm': return 20; 
        case 'md': return 24; 
        case 'lg': return 28; 
    }
}


