import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../../../lib/utilis/index.ts"
import { DocumentDTO, DocumentF24Status, DocumentF24StatusUpdateDTO, DocumentFilters, DocumentReportDTO, DocumentType, FindAllDocumentsResponseDTO, FindExtensionsFileRequestDTO, FindExtensionsFileResponseDTO } from "./dto.ts"
import { NewDocumentService } from "./service.ts"

interface DocumentState {
    openAddDocumentsModal: boolean
    findAllDocumentsResponse?: FindAllDocumentsResponseDTO
    findAllDocumentsStatus: PromiseStatuses
    documentFilters: DocumentFilters
    saveDocumentStatus: PromiseStatuses
    document: string | null
    deleteDocumentStatus: PromiseStatuses
    deleteConfirmOrDenyModal: boolean
    findAllReceivedDocumentsStatus: PromiseStatuses
    findAllReceivedDocumentsResponse?: FindAllDocumentsResponseDTO
    findAllSentDocumentsStatus: PromiseStatuses
    findAllSentDocumentsResponse?: FindAllDocumentsResponseDTO
    selectedSentOrReceivedDocumentTab: number
    findByIdDocumentStatus: PromiseStatuses
    findByIdDocumentResponse?: DocumentDTO
    documentReportStatus: PromiseStatuses
    documentReportReponse?: DocumentReportDTO
    updateStatusF24Status: PromiseStatuses
    updateStatusF24Request: DocumentF24StatusUpdateDTO
    updateStatusF24Modal: boolean,
    documentId: string,
    findExtensionsFilesStatus: PromiseStatuses
    findExtensionsFilesRequest: FindExtensionsFileRequestDTO
    findExtensionsFilesResponse?: FindExtensionsFileResponseDTO
}

const initialState: DocumentState = {
    openAddDocumentsModal: false,
    findAllDocumentsStatus: 'idle',
    documentFilters: {
        companyId: 0,
        itemsPerPage: 15,
        order: false,
        page: 0,
        receiverGroup: '',
        referenceYear: 0,
        referenceMonth: 0,
        senderGroup: "",
        sort: "",
        type: DocumentType.balance,
        typology: "",
        fileName: "",
        status: ""
    },
    saveDocumentStatus: 'idle',
    document: null,
    deleteDocumentStatus: 'idle',
    deleteConfirmOrDenyModal: false,
    findAllReceivedDocumentsStatus: 'idle',
    findAllSentDocumentsStatus: 'idle',
    selectedSentOrReceivedDocumentTab: 0,
    findByIdDocumentStatus: 'idle',
    documentReportStatus: 'idle',
    updateStatusF24Status: 'idle',
    updateStatusF24Request: {
        f24Status: DocumentF24Status.Idle,
    },
    updateStatusF24Modal: false,
    documentId: '',
    findExtensionsFilesStatus: 'idle',
    findExtensionsFilesRequest: {
        ids: []
    }
}

export const findAllDocuments = createAsyncThunk(
    'document/findAllDocument',
    async (filters: DocumentFilters, thunkApi): Promise<FindAllDocumentsResponseDTO> => {
        const documentService = NewDocumentService()

        return documentService.findAllDocuments(filters)
    }
)

export const findAllSentDocuments = createAsyncThunk(
    'document/findAllSentDocuments',
    async (filters: DocumentFilters, thunkApi): Promise<FindAllDocumentsResponseDTO> => {
        const documentService = NewDocumentService()

        return documentService.findAllDocuments(filters)
    }
)

export const findAllReceivedDocuments = createAsyncThunk(
    'document/findAllReceivedDocuments',
    async (filters: DocumentFilters, thunkApi): Promise<FindAllDocumentsResponseDTO> => {
        const documentService = NewDocumentService()

        return documentService.findAllDocuments(filters)
    }
)

export const saveDocument = createAsyncThunk(
    'document/saveDocument',
    async (request: FormData[], thunkApi): Promise<void[]> => {
        let promises: Promise<void>[] = []
        promises = request.map(async (document) => {
            const documentService = NewDocumentService()

            return documentService.saveDocument(document)
        })
        return Promise.all(promises)
    }
)

export const findByIdDocument = createAsyncThunk(
    'document/findByIdDocument',
    async (id: string, thunkApi): Promise<DocumentDTO> => {
        const documentService = NewDocumentService()

        return documentService.findByIdDocument(id)
    }
)

export const deleteDocument = createAsyncThunk(
    'document/deleteDocument',
    async (id: string, thunkApi): Promise<void> => {
        const documentService = NewDocumentService()

        return documentService.deleteDocument(id)
    }
)

export const documentReport = createAsyncThunk(
    'document/documentReport',
    async (id: string, thunkApi): Promise<DocumentReportDTO> => {
        const documentService = NewDocumentService()

        return documentService.documentReport(id)
    }
)

export const updateStatusF24 = createAsyncThunk(
    'documents/updateStatusF24',
    async (request: { id: string, data: DocumentF24StatusUpdateDTO }): Promise<void> => {
        const documentService = NewDocumentService()

        return documentService.updateStatusF24(request.id, request.data)
    },
)

export const findExtensionsFiles = createAsyncThunk(
    'documents/findExtensionsFiles',
    async (request: FindExtensionsFileRequestDTO): Promise<FindExtensionsFileResponseDTO> => {
        const documentService = NewDocumentService()

        return documentService.findExtensionsFiles(request)
    }
)

const DocumentSlice = createSlice({
    name: 'document/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findAllDocuments.pending, (state) => {
                state.findAllDocumentsStatus = 'loading'
            })
            .addCase(findAllDocuments.fulfilled, (state, action) => {
                state.findAllDocumentsStatus = 'successfully'
                state.findAllDocumentsResponse = action.payload
            })
            .addCase(findAllDocuments.rejected, (state) => {
                state.findAllDocumentsStatus = 'failed'
            })
            .addCase(findAllSentDocuments.pending, (state) => {
                state.findAllSentDocumentsStatus = 'loading'
            })
            .addCase(findAllSentDocuments.fulfilled, (state, action) => {
                state.findAllSentDocumentsStatus = 'successfully'
                state.findAllSentDocumentsResponse = action.payload
            })
            .addCase(findAllSentDocuments.rejected, (state) => {
                state.findAllSentDocumentsStatus = 'failed'
            })
            .addCase(findAllReceivedDocuments.pending, (state) => {
                state.findAllReceivedDocumentsStatus = 'loading'
            })
            .addCase(findAllReceivedDocuments.fulfilled, (state, action) => {
                state.findAllReceivedDocumentsStatus = 'successfully'
                state.findAllReceivedDocumentsResponse = action.payload
            })
            .addCase(findAllReceivedDocuments.rejected, (state) => {
                state.findAllReceivedDocumentsStatus = 'failed'
            })
            .addCase(saveDocument.pending, (state) => {
                state.saveDocumentStatus = 'loading'
            })
            .addCase(saveDocument.fulfilled, (state, action) => {
                state.saveDocumentStatus = 'successfully'
            })
            .addCase(saveDocument.rejected, (state) => {
                state.saveDocumentStatus = 'failed'
            })
            .addCase(findByIdDocument.pending, (state) => {
                state.findByIdDocumentStatus = 'loading'
            })
            .addCase(findByIdDocument.fulfilled, (state, action) => {
                state.findByIdDocumentStatus = 'successfully'
                state.findByIdDocumentResponse = action.payload
            })
            .addCase(findByIdDocument.rejected, (state) => {
                state.findByIdDocumentStatus = 'failed'
            })
            .addCase(deleteDocument.pending, (state) => {
                state.deleteDocumentStatus = 'loading'
            })
            .addCase(deleteDocument.fulfilled, (state, action) => {
                state.deleteDocumentStatus = 'successfully'
            })
            .addCase(deleteDocument.rejected, (state) => {
                state.deleteDocumentStatus = 'failed'
            })
            .addCase(documentReport.pending, (state) => {
                state.documentReportStatus = 'loading'
            })
            .addCase(documentReport.fulfilled, (state, action) => {
                state.documentReportStatus = 'successfully'
                state.documentReportReponse = action.payload
            })
            .addCase(documentReport.rejected, (state) => {
                state.documentReportStatus = 'failed'
            })
            .addCase(updateStatusF24.pending, (state) => {
                state.updateStatusF24Status = 'loading'
            })
            .addCase(updateStatusF24.fulfilled, (state, action) => {
                state.updateStatusF24Status = 'successfully'
            })
            .addCase(updateStatusF24.rejected, (state) => {
                state.updateStatusF24Status = 'failed'
            })
            .addCase(findExtensionsFiles.pending, (state) => {
                state.findExtensionsFilesStatus = 'loading'
            })
            .addCase(findExtensionsFiles.fulfilled, (state, action) => {
                state.findExtensionsFilesStatus = 'successfully'
                state.findExtensionsFilesResponse = action.payload
            })
            .addCase(findExtensionsFiles.rejected, (state) => {
                state.findExtensionsFilesStatus = 'failed'
            })
    },
    reducers: {
        setOpenAddDocumentsModal: (state, action) => {
            state.openAddDocumentsModal = action.payload
        },
        setDocumentFilterCompanyId: (state, action) => {
            state.documentFilters.companyId = action.payload
        },
        setDocumentFilterType: (state, action) => {
            state.documentFilters.type = action.payload
        },
        setDocumentFilterFileName: (state, action) => {
            state.documentFilters.fileName = action.payload
        },
        setDocumentFilterTypology: (state, action) => {
            state.documentFilters.typology = action.payload
        },
        setDocumentFilterYear: (state, action) => {
            state.documentFilters.referenceYear = action.payload
        },
        setDocumentFilterMonth: (state, action) => {
            state.documentFilters.referenceMonth = action.payload
        },
        setDocumentFilterReceivedGroup: (state, action) => {
            state.documentFilters.receiverGroup = action.payload
        },
        setDocumentFilterSenderGroup: (state, action) => {
            state.documentFilters.senderGroup = action.payload
        },
        setDocumentFilterPage: (state, action) => {
            state.documentFilters.page = action.payload
        },
        setDocumentFilterStatus: (state, action) => {
            state.documentFilters.status = action.payload
        },
        setFindAllDocumentsStatus: (state, action) => {
            state.findAllDocumentsStatus = action.payload
        },
        setSaveDocumentStatus: (state, action) => {
            state.saveDocumentStatus = action.payload
        },
        setDeleteConfirmOrDenyModal: (state, action) => {
            state.deleteConfirmOrDenyModal = action.payload
        },
        setDeleteDocumentStatus: (state, action) => {
            state.deleteDocumentStatus = action.payload
        },
        setSelectedSentOrReceivedTab: (state, action) => {
            state.selectedSentOrReceivedDocumentTab = action.payload
        },
        setFindByIdDocumentStatus: (state, action) => {
            state.findByIdDocumentStatus = action.payload
        },
        setFindAllReceivedDocumentsStatus: (state, action) => {
            state.findAllReceivedDocumentsStatus = action.payload
        },
        setDocumentFilterItemsPerPage: (state, action) => {
            state.documentFilters.itemsPerPage = action.payload
        },
        setDocumentReportStatus: (state, action) => {
            state.documentReportStatus = action.payload
        },
        setUpdateStatusF24Modal: (state, action) => {
            state.updateStatusF24Modal = action.payload
        },
        setUpdateF24Status: (state, action) => {
            state.updateStatusF24Request.f24Status = action.payload
        },
        setUpdateF24Date: (state, action) => {
            state.updateStatusF24Request.updateDate = action.payload
        },
        setDocumentId: (state, action) => {
            state.documentId = action.payload
        },
        setUpdateF24StatusStatus: (state, action) => {
            state.updateStatusF24Status = action.payload
        },
        setFindExtensionsFilesStatus: (state, action) => {
            state.findExtensionsFilesStatus = action.payload
        }
    },
})

export const {
    setOpenAddDocumentsModal,
    setDocumentFilterCompanyId,
    setDocumentFilterType,
    setFindAllDocumentsStatus,
    setSaveDocumentStatus,
    setDeleteConfirmOrDenyModal,
    setDeleteDocumentStatus,
    setDocumentFilterFileName,
    setDocumentFilterMonth,
    setDocumentFilterTypology,
    setDocumentFilterYear,
    setDocumentFilterReceivedGroup,
    setDocumentFilterSenderGroup,
    setDocumentFilterPage,
    setSelectedSentOrReceivedTab,
    setFindByIdDocumentStatus,
    setDocumentFilterStatus,
    setFindAllReceivedDocumentsStatus,
    setDocumentFilterItemsPerPage,
    setDocumentReportStatus,
    setUpdateStatusF24Modal,
    setUpdateF24Date,
    setDocumentId,
    setUpdateF24StatusStatus,
    setUpdateF24Status,
    setFindExtensionsFilesStatus
} = DocumentSlice.actions

export default DocumentSlice.reducer
