import axios from "axios";
import { keycloak } from "../../lib/keycloak/index.ts";
import { UserInfoDTO } from "../company/chat/dto.ts";
import { CompanyDTO } from "../company/dto.ts";
import { ReferrersDTO } from "../company/referrers/dto.ts";
import { CollaboratorDTO } from "../financialAdvisor/collaborator/dto.ts";
import { FinancialAdvisorDTO } from "../financialAdvisor/dto.ts";
import { OperatorDTO } from "../operator/dto.ts";
import { AuthService } from "./service.ts";

export class AuthServiceImpl implements AuthService {

    public findMeFinancialAdvisor(): Promise<FinancialAdvisorDTO> {
        let url: string = "/api/financialAdvisors/find-me"
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findMeCollaborator(): Promise<CollaboratorDTO> {
        let url: string = "/api/collaborators/find-me"
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findMeCompany(): Promise<CompanyDTO> {
        let url: string = "/api/companies/find-me"
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findMeOperator(): Promise<OperatorDTO> {
        let url: string = "/api/operators/find-me"
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findMeReferrer(): Promise<ReferrersDTO> {
        let url: string = "/api/administrativeCompany/find-me"
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findByUserId(request: string): Promise<UserInfoDTO> {
        let url: string = "/api/users/" + request
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}
