import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../lib/utilis/index.ts";
import { FindAllNoticeResponseDTO, NoticeCreationDTO, NoticeDTO, NoticeFiltersDTO, NoticeStatus, NoticeUpsertDTO } from "./dto.ts";
import { NewNoticeService } from "./service.ts";

interface NoticeState {
    selectedTab: number
    findAllNoticeResponse?: FindAllNoticeResponseDTO
    findAllNoticeStatus: PromiseStatuses
    createNotice: NoticeCreationDTO
    createNoticeStatus: PromiseStatuses
    noticeFilters: NoticeFiltersDTO
    findByIdNoticeStatus: PromiseStatuses
    findByIdNoticeResponse?: NoticeDTO
    editNoticeStatus: PromiseStatuses
    editNoticeRequest: NoticeUpsertDTO
    deleteNoticeStatus: PromiseStatuses
    openConfirmOrDenyModal: boolean
    saveOrEditNotice: 'save' | 'edit'
    draft: boolean
}

const initialState: NoticeState = {
    selectedTab: 0,
    createNotice: {
        type: '',
        status: NoticeStatus.SENT,
        subjectLine: "",
        message: "",
        prominent: false,
        userIds: []
    },
    findAllNoticeStatus: 'idle',
    noticeFilters: {
        itemsPerPage: 20,
        order: false,
        page: 0,
        sort: "",
        status: NoticeStatus.SENT,
        type: '',
        receiver: '',
        read: ''
    },
    createNoticeStatus: 'idle',
    findByIdNoticeStatus: 'idle',
    editNoticeStatus: 'idle',
    editNoticeRequest: {
        type: '',
        status: '',
        subjectLine: "",
        message: "",
        prominent: false,
        userIds: []
    },
    deleteNoticeStatus: 'idle',
    openConfirmOrDenyModal: false,
    saveOrEditNotice: 'save',
    draft: false
}

export const findAllNotice = createAsyncThunk(
    'notice/findAllNotice',
    async (filters: NoticeFiltersDTO, thunkApi): Promise<FindAllNoticeResponseDTO> => {
        const noticeService = NewNoticeService()

        return noticeService.findAllNotice(filters)
    }
)

export const createNotice = createAsyncThunk(
    'notice/createNotice',
    async (request: NoticeCreationDTO, thunkApi): Promise<void> => {
        const noticeService = NewNoticeService()

        return noticeService.createNotice(request)
    }
)

export const findByIdNotice = createAsyncThunk(
    'notice/findById',
    async (id: string, thunkApi): Promise<NoticeDTO> => {
        const noticeService = NewNoticeService()

        return noticeService.findById(id)
    }
)

export const editNotice = createAsyncThunk(
    'notice/editNotice',
    async (request: { id: string, request: NoticeUpsertDTO }, thunkApi): Promise<void> => {
        const noticeService = NewNoticeService()

        return noticeService.editNotice(request.id, request.request)
    }
)

export const deleteNotice = createAsyncThunk(
    'notice/deleteNotice',
    async (id: string, thunkApi): Promise<void> => {
        const noticeService = NewNoticeService()

        return noticeService.deleteNotice(id)
    }
)

const noticeSlice = createSlice({
    name: 'notice/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findAllNotice.pending, (state) => {
                state.findAllNoticeStatus = 'loading'
            })
            .addCase(findAllNotice.fulfilled, (state, action) => {
                state.findAllNoticeStatus = 'successfully'
                state.findAllNoticeResponse = action.payload
            })
            .addCase(findAllNotice.rejected, (state) => {
                state.findAllNoticeStatus = 'failed'
            })
            .addCase(createNotice.pending, (state) => {
                state.createNoticeStatus = 'loading'
            })
            .addCase(createNotice.fulfilled, (state, action) => {
                state.createNoticeStatus = 'successfully'
            })
            .addCase(createNotice.rejected, (state) => {
                state.createNoticeStatus = 'failed'
            })
            .addCase(findByIdNotice.pending, (state) => {
                state.findByIdNoticeStatus = 'loading'
            })
            .addCase(findByIdNotice.fulfilled, (state, action) => {
                state.findByIdNoticeStatus = 'successfully'
                state.findByIdNoticeResponse = action.payload
            })
            .addCase(findByIdNotice.rejected, (state) => {
                state.findByIdNoticeStatus = 'failed'
            })
            .addCase(editNotice.pending, (state) => {
                state.editNoticeStatus = 'loading'
            })
            .addCase(editNotice.fulfilled, (state, action) => {
                state.editNoticeStatus = 'successfully'
            })
            .addCase(editNotice.rejected, (state) => {
                state.editNoticeStatus = 'failed'
            })
            .addCase(deleteNotice.pending, (state) => {
                state.deleteNoticeStatus = 'loading'
            })
            .addCase(deleteNotice.fulfilled, (state, action) => {
                state.deleteNoticeStatus = 'successfully'
            })
            .addCase(deleteNotice.rejected, (state) => {
                state.deleteNoticeStatus = 'failed'
            })
    },
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
        setNoticeCreateMessage: (state, action) => {
            state.createNotice.message = action.payload
        },
        setNoticeFilterStatus: (state, action) => {
            state.noticeFilters.status = action.payload
        },
        setNoticeFiltersRead: (state, action) => {
            state.noticeFilters.read = action.payload
        },
        setNoticeFilterReceiver: (state, action) => {
            state.noticeFilters.receiver = action.payload
        },
        setNoticeFilterPage: (state, action) => {
            state.noticeFilters.page = action.payload
        },
        resetNoticeCreationRequest: (state) => {
            state.createNotice = {
                type: '',
                status: NoticeStatus.SENT,
                userIds: [],
                subjectLine: "",
                message: "",
                prominent: false
            }
        },
        setNoticeCreateUserIds: (state, action) => {
            state.createNotice.userIds = action.payload
        },
        setNoticeCreateType: (state, action) => {
            state.createNotice.type = action.payload
        },
        setNoticeCreateProminent: (state, action) => {
            state.createNotice.prominent = action.payload
        },
        setNoticeCreateSubjectLine: (state, action) => {
            state.createNotice.subjectLine = action.payload
        },
        setNoticeCreateStatus: (state, action) => {
            state.createNotice.status = action.payload
        },
        setCreateNoticeStatus: (state, action) => {
            state.createNoticeStatus = action.payload
        },
        setDeleteNoticeStatus: (state, action) => {
            state.deleteNoticeStatus = action.payload
        },
        setOpenConfirmOrDenyModal: (state, action) => {
            state.openConfirmOrDenyModal = action.payload
        },
        setSaveOrEditNotice: (state, action) => {
            state.saveOrEditNotice = action.payload
        },
        setEditNoticeType: (state, action) => {
            state.editNoticeRequest.type = action.payload
        },
        setEditNoticeMessage: (state, action) => {
            state.editNoticeRequest.message = action.payload
        },
        setEditNoticeSubjectLine: (state, action) => {
            state.editNoticeRequest.subjectLine = action.payload
        },
        setEditNoticeUserIds: (state, action) => {
            state.editNoticeRequest.userIds = action.payload
        },
        setEditNoticeProminent: (state, action) => {
            state.editNoticeRequest.prominent = action.payload
        },
        setEditNoticeRequestStatus: (state, action) => {
            state.editNoticeRequest.status = action.payload
        },
        setEditNoticeStatus: (state, action) => {
            state.editNoticeStatus = action.payload
        },
        setDraft: (state, action) => {
            state.draft = action.payload
        },
        setFindByIdNoticeStatus: (state, action) => {
            state.findByIdNoticeStatus = action.payload
        }
    },
})

export const {
    setSelectedTab,
    setNoticeCreateMessage,
    setNoticeFilterStatus,
    resetNoticeCreationRequest,
    setNoticeCreateUserIds,
    setNoticeCreateType,
    setNoticeCreateProminent,
    setNoticeCreateSubjectLine,
    setNoticeCreateStatus,
    setCreateNoticeStatus,
    setOpenConfirmOrDenyModal,
    setDeleteNoticeStatus,
    setSaveOrEditNotice,
    setEditNoticeMessage,
    setEditNoticeProminent,
    setEditNoticeSubjectLine,
    setEditNoticeType,
    setEditNoticeStatus,
    setEditNoticeRequestStatus,
    setEditNoticeUserIds,
    setNoticeFilterReceiver,
    setNoticeFilterPage,
    setDraft,
    setFindByIdNoticeStatus,
    setNoticeFiltersRead
} = noticeSlice.actions

export default noticeSlice.reducer

