import { AcubeAccountDTO, AcubeReconnectResponseDTO } from "./dto.ts";
import { BankServiceImpl } from "./serviceImpl.ts";

export interface BankService {
    findAcubeConnectRequest(id: string): Promise<string>
    findByIdAcubeGetAccount(id: string, uuid: string): Promise<AcubeAccountDTO>
    acubeReconnectRequest(id: string, uuid: string): Promise<AcubeReconnectResponseDTO>
    acubeGetTransaction(fiscalId: string, uuid: string, madeOnAfter: string, madeOnBefore: string): Promise<string>
}

export function NewBankService(): BankService {
    return new BankServiceImpl();
}