import { format } from "date-fns"
import { it } from 'date-fns/locale/it'
import React, { useEffect, useRef, useState } from "react"
import InfiniteScroll from 'react-infinite-scroll-component'
import { keycloak } from "../../../lib/keycloak/index.ts"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts"
import { colors } from "../../../ui/colors.ts"
import { ArrowRightIcon } from "../../../ui/icons/arrowRightIcon.tsx"
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx"
import { InputComponent } from "../../../ui/molecules/input/index.tsx"
import { SpinnerComponent } from "../../../ui/molecules/spinner/index.tsx"
import { findByUserId } from "../../auth/slice.ts"
import { CompanyStatus } from "../dto.ts"
import { MessageDTO } from "./dto.ts"
import { MessageComponent } from "./message.tsx"
import { findChats, findMessages } from "./slice.ts"
import "./style.css"


export function ChatComponent() {
    const layoutState = useAppSelector(state => state.layout)
    const chatState = useAppSelector(state => state.chat)
    const keycloakState = useAppSelector(state => state.secured)
    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()

    const [page, setPage] = useState<number>(0)
    const [messages, setMessages] = useState<MessageDTO[]>([])
    const [textMessage, setTextMessage] = useState<string>('')

    const messagesRef = useRef(null)

    useEffect(() => {
        setPage(0)
        setMessages([])
        setTextMessage("")
    }, [chatState.selectedChat])

    useEffect(() => {
        if (
            chatState.findMessagesStatus === 'successfully' &&
            chatState.findMessagesResponse !== undefined &&
            chatState.findMessagesResponse.data !== undefined &&
            chatState.findMessagesResponse.data.length > 0
        ) {
            let messagesList = [...messages, ...chatState.findMessagesResponse.data]
            chatState.findMessagesResponse.data.forEach((message, index) => {
                messagesList.concat(
                    message
                )
            })
            setMessages(messagesList)
            if (chatState.findMessagesResponse.page > 0) {
                //@ts-ignore
                messagesRef.current?.lastElementChild?.lastElementChild?.scrollIntoView({ block: 'start' })
            }
        }
    }, [chatState.findMessagesStatus])

    if (keycloakState.socket === undefined) {
        return <></>
    }

    keycloakState.socket.onmessage = () => {
        if (chatState.selectedChat !== 0) {
            const chat = chatState.findChatResponse.find(chat => chat.id === chatState.selectedChat)
            setMessages([])
            dispatch(findMessages({ chatId: chatState.selectedChat, filter: { page: 0, itemsPerPage: (chat && chat.notSeenMessages > 0 ? (chat.notSeenMessages + 25) : 25), order: chatState.messageFilter.order, sort: chatState.messageFilter.sort } }))
        }
        dispatch(findChats(companyState.findByIdCompanyResponse?.id || ''))
    }

    const chat = chatState.findChatResponse.find(chat => chat.id === chatState.selectedChat)

    return (
        <div style={{
            height: '100%',
            backgroundColor: colors.white,
            width: '100%',
            borderRadius: 12,
            border: '1px solid ' + colors.neutral[200],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            {
                chatState.selectedChat === 0 ? (
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='text-text-md font-medium text-neutral-700'>
                        Seleziona una chat per mandare messaggi.
                    </div>
                ) : (
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ padding: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="flex flex-row items-center gap-3 flex-grow">
                                <div className="w-[48px] h-[48px] rounded-[240px] flex justify-center items-center" style={{ backgroundColor: layoutState.theme[500] }}>
                                    {chatState.chatList?.icon}
                                </div>
                                <div className="flex flex-col gap-1">
                                    <span className="text-text-md font-bold text-neutral-800">
                                        {chatState.chatList?.name}
                                    </span>
                                    <span className="text-text-sm font-normal text-neutral-500">
                                        {chatState.chatList?.members + " membri"}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="chat"
                            style={{
                                backgroundColor: colors.neutral[100],
                                position: 'relative',
                                height: 'calc(100% - 147.98px)'
                            }}
                        >
                            {
                                chatState.findMessagesStatus === 'loading' ?
                                    <div className="flex justify-center items-center h-full">
                                        <SpinnerComponent />
                                    </div>
                                    :
                                    <div id='scrollableDivMessages' ref={messagesRef} style={{ display: 'flex', flexDirection: "column-reverse", overflow: "scroll", height: '100%' }}>
                                        <InfiniteScroll
                                            key={chatState.selectedChat + '-chat-list-message'}
                                            inverse
                                            style={{ display: 'flex', flexDirection: 'column-reverse', marginBottom: 4 }}
                                            scrollableTarget="scrollableDivMessages"
                                            dataLength={messages.length}
                                            next={() => {
                                                if (chatState.findMessagesStatus !== 'loading') {
                                                    setPage(page + 1)
                                                    dispatch(findMessages({ chatId: chatState.selectedChat, filter: { page: 0, itemsPerPage: (chat && chat.notSeenMessages > 0 ? (chat.notSeenMessages + 25) : 25), order: chatState.messageFilter.order, sort: chatState.messageFilter.sort } }))
                                                }
                                            }}
                                            hasMore={chatState.findMessagesResponse?.next || false}
                                            endMessage={
                                                <span className='text-text-md font-semibold text-neutral-800 text-center p-4'>
                                                    Non sono presenti altri messaggi
                                                </span>
                                            }
                                            loader={
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        padding: 10,
                                                        margin: 0,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <SpinnerComponent />
                                                </div>
                                            }
                                        >
                                            {
                                                messages.map((message, index) => (
                                                    <div
                                                        key={message.id}
                                                        style={{
                                                            display: 'flex',
                                                            gap: '8px',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            alignSelf: 'stretch',
                                                            borderRadius: '6px',
                                                            width: '100%',
                                                            padding: (
                                                                (
                                                                    messages[index + 1] &&
                                                                    messages[index + 1].sender === message.sender &&
                                                                    format(new Date(message.timestamp), 'dd/MM/yyyy') === format(new Date(messages[index + 1].timestamp), 'dd/MM/yyyy')
                                                                ) || (
                                                                        messages[index - 1] &&
                                                                        messages[index - 1].sender === message.sender &&
                                                                        format(new Date(message.timestamp), 'dd/MM/yyyy') === format(new Date(messages[index - 1].timestamp), 'dd/MM/yyyy')
                                                                    ) ? '2px 12px' : '8px 12px')
                                                        }}>
                                                        {
                                                            (!messages[index + 1] || (messages[index + 1] && format(new Date(message.timestamp), 'dd/MM/yyyy') !== format(new Date(messages[index + 1].timestamp), 'dd/MM/yyyy'))) &&
                                                            <span className='text-text-md font-medium text-neutral-750'>
                                                                {new Date().getFullYear() === new Date(message.timestamp).getFullYear() && (format(new Date(message.timestamp), 'eee ', { locale: it }))}
                                                                {(format(new Date(message.timestamp), 'dd MMM', { locale: it }))}
                                                                {new Date().getFullYear() !== new Date(message.timestamp).getFullYear() && format(new Date(message.timestamp), ' yyyy')}
                                                            </span>
                                                        }
                                                        <div className="text-text-lg font-medium text-neutral-800"
                                                            style={{ flexGrow: 1, flexBasis: '45%', maxWidth: '45%', alignSelf: (message.sender === keycloak.subject ? 'flex-end' : 'flex-start') }}>
                                                            <MessageComponent
                                                                sameSanderAsPrevious={
                                                                    messages[index + 1] &&
                                                                    messages[index + 1].sender === message.sender &&
                                                                    format(new Date(message.timestamp), 'dd/MM/yyyy') === format(new Date(messages[index + 1].timestamp), 'dd/MM/yyyy')
                                                                }
                                                                message={message.message}
                                                                sender={authState.findByUserIdResponse?.find(user => user.userId === message.sender)}
                                                                sent={message.sender === keycloak.subject}
                                                                date={message.timestamp}
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </InfiniteScroll>
                                    </div>
                            }
                        </div>
                        <div style={{ padding: '16px', gap: 8, display: 'flex', flexDirection: 'row', alignItems: 'center', height: '72px' }}>
                            <InputComponent
                                value={textMessage}
                                onChangeText={e => setTextMessage(e)}
                                id={'chat'}
                                type={'text'}
                                placeholder={'Inserisci un messaggio'}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && keycloak.tokenParsed !== undefined && keycloak.tokenParsed.exp !== undefined && textMessage !== '') {
                                        if (keycloakState.socket && keycloakState.socket.readyState === keycloakState.socket.OPEN) {
                                            keycloakState.socket.send(chatState.selectedChat + ':' + textMessage)
                                            setTextMessage('')
                                            setTimeout(() => {
                                                setMessages([])
                                                dispatch(findMessages({ chatId: chatState.selectedChat, filter: { page: 0, itemsPerPage: (chat && chat.notSeenMessages > 0 ? (chat.notSeenMessages + 25) : 25), order: chatState.messageFilter.order, sort: chatState.messageFilter.sort } }))
                                                dispatch(findByUserId(chatState.usersListAfterSend))
                                                dispatch(findChats(companyState.findByIdCompanyResponse?.id ?? authState.findMeCompanyResponse?.id!))
                                            }, 200)
                                        }
                                    }
                                }}
                                disabled={!chat?.active || keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("operator") ||
                                    companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                    (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                    (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator")) ||
                                    (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                    (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                                }
                            />
                            <ButtonComponent
                                key={chatState.selectedChat + '-chat-button-message'}
                                label={''}
                                onClick={() => {
                                    if (keycloak.tokenParsed !== undefined && keycloak.tokenParsed.exp !== undefined && textMessage !== '') {
                                        if (keycloakState.socket && keycloakState.socket.readyState === keycloakState.socket.OPEN) {
                                            keycloakState.socket.send(chatState.selectedChat + ':' + textMessage)
                                            setTextMessage('')
                                            setTimeout(() => {
                                                setMessages([])
                                                dispatch(findMessages({ chatId: chatState.selectedChat, filter: { page: 0, itemsPerPage: (chat && chat.notSeenMessages > 0 ? (chat.notSeenMessages + 25) : 25), order: chatState.messageFilter.order, sort: chatState.messageFilter.sort } }))
                                                dispatch(findByUserId(chatState.usersListAfterSend))
                                                dispatch(findChats(companyState.findByIdCompanyResponse?.id ?? authState.findMeCompanyResponse?.id!))
                                            }, 200)
                                        }
                                    }
                                }}
                                color={"blue"}
                                variant={"solid"}
                                size={"md"}
                                iconPosition={"only"}
                                icon={<ArrowRightIcon size={"20"} color={colors.white} />}
                                disabled={!chat?.active || keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("operator") ||
                                    companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                    (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                    (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator")) ||
                                    (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                    (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                                } />
                        </div>
                    </div>
                )
            }
        </div>
    )
}