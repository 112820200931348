import React from "react";
import { useAppSelector } from "../../lib/redux/hook.ts";

export function LogoIcon() {
    const layoutState = useAppSelector(state => state.layout)
    return (
        <svg width="32" height="32" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="28" height="28" rx="6.72" fill={layoutState.theme[500]} />
            <path fillRule="evenodd" clipRule="evenodd" d="M21.9624 5.28496L21.893 5.09743C21.8569 4.99943 21.7181 4.99943 21.6819 5.09743L21.6124 5.28496C21.4523 5.71777 21.1111 6.05902 20.6782 6.21918L20.4907 6.28861C20.3927 6.32501 20.3927 6.46361 20.4907 6.49973L20.6782 6.56918C21.1111 6.72927 21.4523 7.07051 21.6124 7.50339L21.6819 7.69121C21.7181 7.78921 21.8569 7.78921 21.893 7.69121L21.9624 7.50339C22.1226 7.07051 22.4638 6.72927 22.8966 6.56918L23.0842 6.49973C23.1822 6.46361 23.1822 6.32501 23.0842 6.28861L22.8966 6.21918C22.4638 6.05902 22.1226 5.71777 21.9624 5.28496ZM10.3985 7.62219C10.2705 7.70822 10.1485 7.80419 10.0342 7.90863C9.63375 8.27375 9.32631 8.74191 9.18925 9.25228L6.65119 18.6947H5.86369C5.19225 18.6947 4.70372 18.0574 4.87781 17.4087L7.35406 8.19619C7.82453 6.44619 9.62878 5.0275 11.384 5.0275H18.2087C18.7938 5.0275 19.1409 5.50028 18.9843 6.08366L18.7005 7.18175H11.8758C11.3639 7.18175 10.8457 7.32091 10.3985 7.62219ZM20.2513 9.26663H21.2701C21.8252 9.26663 22.2291 9.7931 22.0853 10.3292L19.5481 19.7978C19.0779 21.5534 17.2737 22.9761 15.5182 22.9761H8.69372C8.10866 22.9761 7.76125 22.5019 7.91819 21.9168L8.22241 20.7816H15.0267C16.1968 20.7816 17.3997 19.9087 17.7132 18.7385L20.2513 9.26663ZM8.7598 18.6865H14.5491C15.1341 18.6865 15.7359 18.2123 15.8925 17.6272L18.1325 9.26683H12.3432C11.7581 9.26683 11.1567 9.74109 10.9998 10.3262L8.7598 18.6865Z" fill="white" />
        </svg>
    )
}