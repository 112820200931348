import React from "react"
import { useAppSelector } from "../../../lib/redux/hook.ts"
import clsx from "clsx"
import { BulletPointProps, getBulletSize } from "./dto.ts"
import { colors } from "../../colors.ts"

export function BulletPoint(props: BulletPointProps) {
  const layoutState = useAppSelector(state => state.layout)
  let completed = props.valueToCompare >= props.stepNumber 
  let masterCompleted = props.valueToCompare > props.stepNumber
 
   function getColor(condition: boolean) {
    if (condition) {
      switch (props.color) {
        case "neutral": return colors.neutral[400]
        case "primary": return layoutState.theme[500]
        case "success": return colors.green[500]
      }
    }
    else {
      switch (props.color) {
        case "neutral": return colors.neutral[100];
        case "primary": return layoutState.theme[200]
        case "success": return colors.green[200]
      }
    }
  }

  return (
    <>
    <div style={{
      backgroundColor: getColor(completed),
      height: getBulletSize(props.size), width: getBulletSize(props.size)
    }}
      className={clsx(`rounded-full flex flex-row items-center justify-center text-white font-bold `, {
         'text-text-sm' : props.size === 'sm',
         'text-text-md' : props.size === 'md',
         'text-text-lg' : props.size === 'lg'
      })}>
       { props.symbol === 'number' && <span> {props.stepNumber}</span> }
       { props.symbol === 'dot' && <div className=" bg-white rounded-full size-[45%]" />}
       { props.symbol === 'check' && <span>✓</span> }
    </div>
   { props.stepNumber < props.mapLength &&
    <div style={{ backgroundColor : getColor(masterCompleted)}} className=' flex-grow h-0.5' />}
    </>
    )
}