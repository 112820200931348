import clsx from "clsx";
import React from "react";
interface Props {
    options: { label: string, value: string }[];
    supportingText?: string;
    onChange?: (selectedValue: string) => void;
    selectedValue: string;
    disabled?: boolean
}

export function PaymentSwitch(props: Props) {
    const { options, selectedValue, onChange, supportingText, disabled } = props;

    const body = options.map((option, index) => {
        const isSelected = option.value === selectedValue;

        return (
            <div
                key={index}
                className={clsx("font-semibold cursor-pointer w-[50%] rounded-md flex flex-row items-center justify-center py-1 px-2 gap-2", {
                    "bg-white": isSelected
                })}
                onClick={() => disabled ? undefined : onChange && onChange(option.value)}
            >
                <span className={clsx("text-text-sm font-semibold", {
                    "text-neutral-800": isSelected,
                    "text-neutral-500": !isSelected,
                })}>
                    {option.label}
                </span>
            </div>
        );
    });

    return (
        <div className='flex flex-row items-center gap-4'>
            {
                supportingText &&
                <span className='font-semibold text-text-sm text-brandSecondary-500 text-center'>
                    {supportingText}
                </span>
            }
            <div className='flex flex-row rounded-md p-0.5 bg-brandSecondary-200'>
                {body}
            </div>
        </div>
    );
}
