import React, { useEffect, useState } from "react"
import { keycloak } from "../../../lib/keycloak/index.ts"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts"
import { PromiseStatuses } from "../../../lib/utilis/index.ts"
import { ChatIcon } from "../../../ui/icons/chatIcon.tsx"
import { LayoutComponent } from "../../../ui/layout/index.tsx"
import { SpinnerComponent } from "../../../ui/molecules/spinner/index.tsx"
import { MenuItems } from "../../../ui/organisms/sidebar/dto.ts"
import { ChatComponent } from "./chat.tsx"
import { MessageFilterDTO } from "./dto.ts"
import { findChats, findLastMessage } from "./slice.ts"
import { UsersList } from "./usersList.tsx"

export function Chat() {
    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)
    const layoutState = useAppSelector(state => state.layout)
    const chatState = useAppSelector(state => state.chat)

    const dispatch = useAppDispatch()

    const [status, setStatus] = useState<PromiseStatuses>("idle")

    useEffect(() => {
        if (companyState.findByIdCompanyStatus === 'successfully' && companyState.findByIdCompanyResponse !== undefined && companyState.selectedOperationTab === 2) {
            setStatus("loading")
            dispatch(findChats(companyState.findByIdCompanyResponse.id))
        }
    }, [companyState.findByIdCompanyStatus, companyState.selectedOperationTab])

    useEffect(() => {
        if (authState.findMeCompanyStatus === 'successfully' && authState.findMeCompanyResponse !== undefined) {
            setStatus("loading")
            dispatch(findChats(authState.findMeCompanyResponse.id))
        }
    }, [authState.findMeCompanyStatus])

    useEffect(() => {
        if (companyState.findByIdCompanyStatus === 'successfully' && companyState.findByIdCompanyResponse !== undefined && keycloak.hasRealmRole("administrative_company")) {
            setStatus("loading")
            dispatch(findChats(companyState.findByIdCompanyResponse.id))
        }
    }, [companyState.findByIdCompanyStatus, keycloak.hasRealmRole])

    useEffect(() => {
        if (chatState.findChatsStatus === "successfully" && status === "loading") {
            let request: { chatId: number, filter: MessageFilterDTO }[] = []
            chatState.findChatResponse.map((chat) => (
                request.push({
                    chatId: chat.id, filter: {
                        page: 0,
                        itemsPerPage: (chat && chat.notSeenMessages > 0 ? (chat.notSeenMessages + 25) : 25),
                        order: chatState.messageFilter.order,
                        sort: chatState.messageFilter.sort
                    }
                })
            ))
            dispatch(findLastMessage(request))
            setStatus("successfully")
        }
    }, [chatState.findChatsStatus, status])

    if (status === "loading") {
        return (
            <div className="flex justify-center items-center flex-grow">
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <>
            {
                keycloak.hasRealmRole("company") || keycloak.hasRealmRole("administrative_company") ?
                    <LayoutComponent
                        menuItem={MenuItems.CHAT}
                        headingLabel={"Messaggi"}
                        headingButtons={[]}
                        breadcrumbItems={["Messaggi"]}
                        breadcrumbIcon={<ChatIcon size={'20'} color={layoutState.theme[500]} />}
                    >
                        <div className="w-full flex flex-row gap-4" style={{ height: 'calc(100vh - 250px)' }}>
                            <UsersList />
                            <ChatComponent />
                        </div>
                    </LayoutComponent>
                    :
                    <div className="w-full flex flex-row gap-4" style={{ height: 'calc(100vh - 250px)' }}>
                        <UsersList />
                        <ChatComponent />
                    </div>
            }
        </>
    )
}