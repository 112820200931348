import clsx from "clsx";
import React, { useState } from "react";
import { useAppSelector } from "../../../lib/redux/hook.ts";
import { colors } from "../../colors.ts";

interface Props {
    selected: boolean
    label: String
    onClick: () => void
}

export function SegmentControl(props: Props) {
    const { selected } = props;
    const { label } = props;
    const { onClick } = props

    const layoutState = useAppSelector(state => state.layout)

    const [hover, setHover] = useState(false);

    const backgroundColor = selected && !hover
        ? layoutState.theme[500] : selected && hover ? layoutState.theme[600]
            : hover && !selected
                ? layoutState.theme[200]
                : layoutState.theme[100];

    return (
        <div className="w-full rounded-[50px] p-[2px] gap-2" onClick={onClick}>
            <div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className={
                    clsx("h-[32px] top-4 left-4 rounded-[40px] px-4 py-1 text-label-xs font-semibold flex items-center justify-center cursor-pointer", {
                        " text-brandSecondary-50": selected,
                        " text-brandSecondary-600 ": !selected
                    })}
                style={{ backgroundColor: backgroundColor }}
            >
                {label}
            </div>
        </div>
    )
}