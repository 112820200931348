import { FilterFindProductDTO, FinancialAdvisorDTO, FinancialAdvisorPromoResponseDTO, FinancialAdvisorStripeSubscriptionDTO, FinancialAdvisorSubscriptionDTO, FinancialAdvisorUrlResponseDTO, FindAllFinancialAdvisorFiltersDTO, findAllFinancialAdvisorResponseDTO, FindStripeProductResponseDTO } from "./dto.ts";
import { FinancialAdvisorServiceImpl } from "./serviceImpl.ts";

export interface FinancialAdvisorService {
    findAllFinancialAdvisor(filters: FindAllFinancialAdvisorFiltersDTO): Promise<findAllFinancialAdvisorResponseDTO>
    createFinancialAdvisor(request: FormData): Promise<void>
    deleteFinancialAdvisor(id: string): Promise<void>
    findByIdFinancialAdvisor(id: string): Promise<FinancialAdvisorDTO>
    editFinancialAdvisor(id: string, request: FormData): Promise<void>
    findStripeProduct(filter: FilterFindProductDTO): Promise<FindStripeProductResponseDTO[]>
    valideStripePromoCode(promoCode: string): Promise<FinancialAdvisorPromoResponseDTO>
    selectPlanStripe(id: string, request: FinancialAdvisorStripeSubscriptionDTO): Promise<FinancialAdvisorUrlResponseDTO>
    deletePlanStripe(id: string): Promise<void>
    financialAdvisorActiveSubscription(id: string): Promise<FinancialAdvisorSubscriptionDTO>
}

export function NewFinancialAdvisorService(): FinancialAdvisorService {
    return new FinancialAdvisorServiceImpl();
}
