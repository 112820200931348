import { useAppSelector } from "../../../lib/redux/hook.ts"
import { colors } from "../../colors.ts"

export enum ProgressBarColor {
    PRIMARY = 'PRIMARY',
    SUCCESS = 'SUCCESS'
}

export const ProgressBarColorMap = (color: ProgressBarColor) => {
    const layoutState = useAppSelector(state => state.layout)

    return new Map<ProgressBarColor, string>([
        [ProgressBarColor.PRIMARY, layoutState.theme[500]],
        [ProgressBarColor.SUCCESS, colors.green[500]]
    ]).get(color)
} 