import React from "react";
import { useAppSelector } from "../../lib/redux/hook.ts";
import { DashboardIcon } from "../../ui/icons/dashboardIcon.tsx";
import { LayoutComponent } from "../../ui/layout/index.tsx";
import { MenuItems } from "../../ui/organisms/sidebar/dto.ts";

export function Dashboard() {
    const layoutState = useAppSelector(state => state.layout)

    return (
        <LayoutComponent
            menuItem={MenuItems.DASHBOARD}
            breadcrumbItems={['Dashboard']}
            headingLabel={"Dashboard"}
            headingButtons={[]}
            breadcrumbIcon={<DashboardIcon color={layoutState.theme[500]} size="24" />}
        >
            <div className={"flex flex-col gap-3 w-full"}>
                <div className={"flex flex-row gap-6 justify-between pb-6"}>
                </div>
            </div>
        </LayoutComponent>
    )
}