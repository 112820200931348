import clsx from "clsx";
import React, { ReactElement, cloneElement, useState } from "react";
import { useAppSelector } from "../../../lib/redux/hook.ts";
import { colors } from "../../colors.ts";
import { Badges } from "../../molecules/badge/index.tsx";

interface Props {
    icon?: ReactElement | undefined
    label: string
    selectedPrimary?: boolean
    selectedSecondary?: boolean
    onClick: () => void
    type: 'primary' | 'secondary'
    disabled?: boolean
    labelBadge?: string
}

export function Tab(props: Props) {
    const { icon } = props;
    const { label } = props;
    const { selectedPrimary } = props;
    const { selectedSecondary } = props;
    const { onClick } = props;
    const { type } = props;
    const { disabled } = props;
    const { labelBadge } = props;

    const layoutState = useAppSelector(state => state.layout)

    const [isHover, setIsHover] = useState(false)

    return (
        <div
            onMouseEnter={() => !disabled && setIsHover(true)}
            onMouseLeave={() => !disabled && setIsHover(false)}
            style={{
                color: (type === 'primary' || type === 'secondary') && isHover ? layoutState.theme[500] : selectedPrimary && type === 'primary' ? layoutState.theme[500] : selectedSecondary && type === 'secondary' ? layoutState.theme[500] : '',
                borderColor: selectedPrimary && type === 'primary' ? layoutState.theme[500] : '',
                backgroundColor: selectedSecondary && type === 'secondary' ? layoutState.theme[100] : ''
            }}
            className={
                clsx("flex flex-row items-center gap-2",
                    {
                        "px-6 h-[44px] cursor-pointer": type === 'primary',
                        "border-b-2  text-label-sm  font-medium": selectedPrimary && type === 'primary',
                        "text-label-sm text-brandSecondary-600 font-medium": !selectedPrimary && type === 'primary',
                        "rounded-lg px-3 py-[10px] gap-2 text-label-sm font-medium cursor-pointer hover:text-brandPrimary-500": type === 'secondary',
                        "text-brandSecondary-500": !selectedSecondary && type === 'secondary',
                        "cursor-not-allowed opacity-50": disabled
                    }
                )
            }
            onClick={() => !disabled && onClick()}
        >
            {icon && cloneElement(icon, { color: isHover || (selectedPrimary || selectedSecondary) ? layoutState.theme[500] : colors.brandSecondary[500], size: 20 })}
            {label}
            {
                labelBadge &&
                <Badges size={"xs"} label={labelBadge} outline={false} emphasis={false} color={"red"} />
            }
        </div>
    )
}