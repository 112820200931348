import React, { ReactNode } from "react";
import { NewObjectService } from "../../../lib/object/service.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { colors } from "../../../ui/colors.ts";
import { DownloadIcon } from "../../../ui/icons/downloadIcon.tsx";
import { HeadingSize } from "../../../ui/organisms/heading/dto.ts";
import { HeadingComponent } from "../../../ui/organisms/heading/index.tsx";
import { InfoTextPopup } from "../../../ui/organisms/infoTextPopup/index.tsx";
import { acubeGetTransactions, setAcubeGetTransactionsStatus } from "./slice.ts";

export function BankStatements() {
    const dispatch = useAppDispatch()
    const bankState = useAppSelector(state => state.bank)

    const objectService = NewObjectService()

    let acubeBankStatements: ReactNode[] = []

    if (
        bankState.findByIdAcubeGetAccountStatus === 'successfully' &&
        bankState.findByIdAcubeGetAccountResponse !== undefined &&
        bankState.findByIdAcubeGetAccountResponse !== null &&
        bankState.findByIdAcubeGetAccountResponse.length > 0
    ) {
        bankState.findByIdAcubeGetAccountResponse.forEach((bank, index) => {
            const trimestreMap = ["I", "II", "III", "IV"];

            for (let i = 0; i < 8; i++) {
                const year = i < 4 ? 2024 : 2023;
                const quarterNumber = (i % 4) + 1;
                const quarterText = trimestreMap[quarterNumber - 1];
                const fileName = `${bank.name} - ${year} - ${quarterText}`;

                let fromDate, toDate;
                switch (quarterNumber) {
                    case 1:
                        fromDate = `${year}-01-01`;
                        toDate = `${year}-03-31`;
                        break;
                    case 2:
                        fromDate = `${year}-04-01`;
                        toDate = `${year}-06-30`;
                        break;
                    case 3:
                        fromDate = `${year}-07-01`;
                        toDate = `${year}-09-30`;
                        break;
                    case 4:
                        fromDate = `${year}-10-01`;
                        toDate = `${year}-12-31`;
                        break;
                }

                acubeBankStatements.push(
                    <tr
                        className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                        key={`${bank.uuid}-${bank.name}-${i}`}
                    >
                        <td className="px-2 py-4 text-text-sm font-semibold text-neutral-850">
                            {bank.name}
                        </td>
                        <td className="px-2 py-4 text-text-sm text-neutral-600">
                            {bank.iban}
                        </td>
                        <td className="px-2 py-4 text-text-sm text-neutral-600">
                            {fileName}
                        </td>
                        <td className="px-2 py-4 text-text-sm text-neutral-600">
                            {year}
                        </td>
                        <td className="px-2 py-4 text-text-sm text-neutral-600">
                            {quarterText + " trimestre"}
                        </td>
                        <td className="w-[40px]">
                            <div className="hover:bg-brandPrimary-50 py-2 px-2 rounded-md">
                                <InfoTextPopup
                                    label={"Scarica"}
                                    children={
                                        <DownloadIcon
                                            size={"24"}
                                            color={colors.brandSecondary[500]}
                                            onClick={() => {
                                                dispatch(acubeGetTransactions({ fiscalId: bank.fiscalId, uuid: bank.uuid, madeOnAfter: fromDate, madeOnBefore: toDate })).then((e) => {
                                                    //@ts-ignore
                                                    objectService.downloadBase64WithoutExtension(fileName, e.payload, 'text/csv')
                                                    dispatch(setAcubeGetTransactionsStatus('idle'))
                                                })
                                            }}
                                        />
                                    }
                                    position={"bottom-left"}
                                />
                            </div>
                        </td>
                    </tr>
                );
            }
        });
    }

    return (
        <div className="pt-8 flex flex-col gap-3 flex-grow">
            <HeadingComponent
                label={"Ultimi estratti conto"}
                buttons={[]}
                size={HeadingSize.SM}
            />
            <div className="w-full flex flex-col gap-4 rounded-tr-xl rounded-tl-xl overflow-auto">
                <div>
                    <table className="w-full mb-3">
                        <thead className="h-[56px] bg-neutral-100 font-normal text-neutral-500 text-text-xs">
                            <th className="text-left">
                                <div className="flex py-4 px-4 gap-1">
                                    NOME
                                </div>
                            </th>
                            <th className="text-left">
                                <div className="flex py-4 px-2 gap-1">
                                    IBAN
                                </div>
                            </th>
                            <th className="text-left">
                                <div className="flex py-4 px-2 gap-1">
                                    NOME FILE
                                </div>
                            </th>
                            <th className="text-center">
                                <div className="flex py-4 px-2 gap-1">
                                    ANNO
                                </div>
                            </th>
                            <th className="text-center">
                                <div className="flex py-4 px-2 gap-1">
                                    TRIMESTRE
                                </div>
                            </th>
                            <th className="text-left">
                                <div className="flex py-4 px-2 gap-1" />
                            </th>
                            <th className="text-left">
                            </th>
                        </thead>
                        <tbody>
                            {acubeBankStatements}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}