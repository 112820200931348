import axios from "axios"
import { SpontaneousFinancialAdvisorService } from "./service"
import { PlanSelectionRequest, PlanSelectionResponse } from "./dto"
import { FinancialAdvisorPromoResponseDTO } from "../dto"

export class SpontaneousFinancialadvisorServiceImpl implements SpontaneousFinancialAdvisorService {
    public createNewSpontaneous(request: FormData):Promise<string> {
        let url: string = 'api/financialAdvisors/spontaneous'
        return axios({
            url,
            method: 'POST',
            data: request,
            headers: {
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }
        ).then(response => response.data)
    }

    public selectPlanSpontaneous(id: string, request: PlanSelectionRequest): Promise<PlanSelectionResponse> {
         let url : string = 'api/financialAdvisors/spontaneous/select-plan/'+id
         return axios({
            url,
            method: 'PUT',
            data: request,
            headers: {
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
         }).then(response=>response.data)
    }

    public valideStripePromoCode(promoCode: string): Promise<FinancialAdvisorPromoResponseDTO> {
        let url: string = "/api/financialAdvisors/subscription/promo-code-verification/" + promoCode
        return axios({
            url: url,
            method: "GET",
            headers: {
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}

