import React from 'react'

export function SuccessIcon() {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="80" height="80" rx="40" fill="#41CC94" />
            <path d="M58 28L36 50L26 40" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}
