import React, { useEffect, useState } from "react";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { setFindDocumentStatus } from "../../../lib/object/slice.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { toBase64 } from "../../../lib/utilis/index.ts";
import { colors } from "../../../ui/colors.ts";
import { EmailIcon } from "../../../ui/icons/emailIcon.tsx";
import { PhoneIcon } from "../../../ui/icons/phoneIcon.tsx";
import Avatar from "../../../ui/molecules/avatar/index.tsx";
import { FileInputComponent } from "../../../ui/molecules/fileInput/index.tsx";
import { InputComponent } from "../../../ui/molecules/input/index.tsx";
import { SpinnerComponent } from "../../../ui/molecules/spinner/index.tsx";
import { FormRow } from "../../../ui/organisms/formRow/formRow.tsx";
import { findMeCollaborator, setFindMeCollaboratorStatus } from "../../auth/slice.ts";
import { setEditCollaboratorFile, setEditCollaboratorName, setEditCollaboratorNote, setEditCollaboratorPhoneNumber, setEditCollaboratorSurname } from "./slice.ts";

export function RegistryCollaborator() {
    const dispatch = useAppDispatch()

    const collaboratorState = useAppSelector(state => state.collaborator)
    const authState = useAppSelector(state => state.auth)
    const objectState = useAppSelector(state => state.object)

    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    useEffect(() => {
        if (authState.findMeCollaboratorStatus === "successfully" || collaboratorState.editCollaboratorStatus === "failed") {
            dispatch(setEditCollaboratorName(authState.findMeCollaboratorResponse?.name))
            dispatch(setEditCollaboratorSurname(authState.findMeCollaboratorResponse?.surname))
            dispatch(setEditCollaboratorPhoneNumber(authState.findMeCollaboratorResponse?.phoneNumber))
        }
    }, [authState.findMeCollaboratorStatus])

    useEffect(() => {
        if (collaboratorState.editCollaboratorStatus === "successfully") {
            dispatch(findMeCollaborator())
        }
    }, [collaboratorState.editCollaboratorStatus])

    useEffect(() => {
        if (authState.findMeCollaboratorStatus === "successfully") {
            dispatch(setFindMeCollaboratorStatus("idle"))
        }
    }, [authState.findMeCollaboratorStatus])

    useEffect(() => {
        if (objectState.findDocumentStatus === "successfully") {
            setAvatarToBase64(objectState.document!)
            dispatch(setFindDocumentStatus('idle'))
        }
    }, [objectState.findDocumentStatus])


    if (authState.findMeCollaboratorStatus === "loading" || collaboratorState.editCollaboratorStatus === "loading" || objectState.findDocumentStatus === "loading") {
        return (
            <div className={"flex justify-center items-center"}>
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <div className={"flex flex-col gap-5"}>
            <FormRow
                label="Nome"
                children={[
                    <>
                        <InputComponent
                            type={"text"}
                            placeholder="Nome"
                            value={collaboratorState.editCollaboratorRequest.name}
                            onChangeText={(value) => {
                                dispatch(setEditCollaboratorName(value))
                            }}
                            disabled={(!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))}
                        />
                        <InputComponent
                            type={"text"}
                            placeholder="Cognome"
                            value={collaboratorState.editCollaboratorRequest.surname}
                            onChangeText={(value) => {
                                dispatch(setEditCollaboratorSurname(value))
                            }}
                            disabled={(!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))}
                        />
                    </>
                ]}
            />
            <FormRow
                label="Email"
                children={[
                    <InputComponent
                        type={"text"}
                        placeholder="Email"
                        startIcon={<EmailIcon size={"20"} color={colors.neutral[400]} />}
                        value={authState.findMeCollaboratorResponse?.email}
                        disabled
                    />
                ]}
            />
            <FormRow
                label="Telefono"
                children={[
                    <InputComponent
                        type={"text"}
                        placeholder="Telefono"
                        startIcon={<PhoneIcon size={"20"} color={colors.neutral[400]} />}
                        value={collaboratorState.editCollaboratorRequest.phoneNumber}
                        onChangeText={(value) => {
                            dispatch(setEditCollaboratorPhoneNumber(value))
                        }}
                        disabled={(!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))}
                    />
                ]}
            />
            <FormRow
                label="Avatar"
                subLabel="Verrà visualizzata sul vostro profilo"
                children={[
                    <div key={"profile-edit-avatar-upload"} className={"flex items-center justify-center gap-4 w-full"}>
                        <Avatar
                            type="single"
                            size="2xl"
                            shape="circle"
                            imageUrl={avatarToBase64}
                            altTextInitials={(collaboratorState.editCollaboratorRequest.name[0] || '') + (collaboratorState.editCollaboratorRequest.surname[0] || '')}
                        />
                        <FileInputComponent
                            id={"create-financial-advisor-avatar"}
                            onChange={(e) => {
                                dispatch(setEditCollaboratorFile(e[0]))
                                toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data))
                            }}
                            color={"gray"}
                            iconSize="32"
                            iconBackground={colors.neutral[100]}
                            infoText
                            disabled={(!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))}
                        />
                    </div>
                ]}
            />
            <FormRow
                label="Note"
                subLabel="Inserisci le note dell'utente"
                children={[
                    <InputComponent
                        type={"text"}
                        placeholder="Un po' di informazioni su di te"
                        supportingText={275-collaboratorState.editCollaboratorRequest.note.length + " caratteri rimasti"}
                        multiple
                        defaultValue={authState.findMeCollaboratorResponse?.note}
                        onChangeText={(value) => dispatch(setEditCollaboratorNote(value))}
                        disabled={(!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))}
                    />
                ]}
            />
        </div>
    )
}