import clsx from 'clsx'
import React from 'react'
import { getRadioSizeProperties, radioSize } from './dto.ts'
import { colors } from '../../colors.ts'
import { useAppSelector } from '../../../lib/redux/hook.ts'

interface Props {
    isDisabled?: boolean,
    isChecked?: boolean,
    onChange?: (value: boolean) => void,
    placeholder?: string,
    radioGroup: string,
    size: radioSize,
    supportingText?: string,
    value: string
}
export function Radio(props: Props) {
    const { size, radioGroup, onChange, placeholder, supportingText } = props
    const blockSize = getRadioSizeProperties(size)
    const layoutState = useAppSelector(state => state.layout)

    function Hovering(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.currentTarget.style.borderColor = layoutState.theme[500]
        e.currentTarget.style.backgroundColor = props.isChecked ? layoutState.theme[500] : 'white'
        const radio: HTMLInputElement = e.currentTarget.querySelector('#' + props.value)!
        radio.style.backgroundColor = props.isChecked ? 'white' : ''
    }

    function notHovering(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.currentTarget.style.borderColor = props.isChecked ? layoutState.theme[500] : colors.brandSecondary[400]
        e.currentTarget.style.backgroundColor = 'white'
        const radio: HTMLInputElement = e.currentTarget.querySelector('#' + props.value)!
        radio.style.backgroundColor = props.isChecked ? layoutState.theme[500] : ''
    }

    return (
        <div className={`flex flex-col `}>
            <div className={`flex flex-row items-center`}>
                <div onMouseEnter={(e) => Hovering(e)} onMouseLeave={e => notHovering(e)} style={{ borderColor: colors.brandSecondary[400] }} className={clsx('rounded-full has-[:disabled]:opacity-20 has-[:focused]:shadow-md  flex flex-row justify-center items-center  border ',
                    { 'size-4': blockSize === 4, 'size-6': blockSize === 6 })
                }>
                    <input
                        style={{ backgroundColor: props.isChecked ? layoutState.theme[500] : 'white' }}
                        defaultChecked={props.isChecked}
                        checked={props.isChecked}
                        id={props.value}
                        value={props.value}
                        disabled={props.isDisabled}
                        onChange={(value) => onChange && onChange(value.target.checked)}
                        type='radio'
                        className={clsx('appearance-none rounded-full ', { 'size-2': blockSize === 4, 'size-4': blockSize === 6 })} name={radioGroup} radioGroup={radioGroup}
                    />
                    <label htmlFor={props.value} />
                </div>
                {placeholder && <div className='ml-2'>{placeholder}</div>}
            </div>
            {supportingText && <div className='text-neutral-500 text-text-lg'>{supportingText}</div>}
        </div>
    );
}

