import { format } from "date-fns";
import React, { ReactNode, useEffect, useState } from "react";
import { keycloak } from "../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { colors } from "../../ui/colors.ts";
import { EditIcon } from "../../ui/icons/editIcon.tsx";
import { TrashIcon } from "../../ui/icons/trashIcon.tsx";
import { Pills } from "../../ui/molecules/pills/index.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { ConfirmModal } from "../../ui/organisms/confirmModal/index.tsx";
import { Dropdown } from "../../ui/organisms/dropDown/index.tsx";
import { ErrorPopup } from "../../ui/organisms/errorPopup/index.tsx";
import { InfoTextPopup } from "../../ui/organisms/infoTextPopup/index.tsx";
import { Pagination } from "../../ui/organisms/pagination/index.tsx";
import { SuccessPopup } from "../../ui/organisms/successPopup/index.tsx";
import { NoticeStatus, NoticeTypeColorMap, NoticeTypeMap } from "./dto.ts";
import { deleteNotice, findAllNotice, findByIdNotice, setCreateNoticeStatus, setDeleteNoticeStatus, setDraft, setEditNoticeRequestStatus, setEditNoticeStatus, setNoticeFilterPage, setOpenConfirmOrDenyModal, setSaveOrEditNotice, setSelectedTab } from "./slice.ts";

export function ListSent() {
    const dispatch = useAppDispatch()
    const noticeState = useAppSelector(state => state.notice)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const collaboratorState = useAppSelector(state => state.collaborator)
    const companyState = useAppSelector(state => state.company)
    const referrerState = useAppSelector(state => state.referrers)
    const operatorState = useAppSelector(state => state.operator)
    const authState = useAppSelector(state => state.auth)

    const [isDelete, setIsDelete] = useState<string | undefined>()

    let noticeRows: ReactNode[] = []

    useEffect(() => {
        dispatch(findAllNotice(noticeState.noticeFilters))
    }, [])

    useEffect(() => {
        dispatch(findAllNotice(noticeState.noticeFilters))
    }, [noticeState.noticeFilters.page])

    useEffect(() => {
        if (noticeState.createNoticeStatus === "successfully") {
            dispatch(findAllNotice(noticeState.noticeFilters))
        }
    }, [noticeState.createNoticeStatus])

    useEffect(() => {
        if (noticeState.deleteNoticeStatus === 'successfully') {
            dispatch(findAllNotice(noticeState.noticeFilters))
        }
    }, [noticeState.deleteNoticeStatus])

    if (noticeState.findAllNoticeStatus === "loading" ||
        financialAdvisorState.findAllFinancialAdvisorStatus === "loading" ||
        collaboratorState.findAllCollaboratorStatus === 'loading' ||
        companyState.findAllCompanyStatus === 'loading' ||
        referrerState.findAllReferrersStatus === "loading" ||
        noticeState.createNoticeStatus === "loading" ||
        noticeState.deleteNoticeStatus === 'loading' ||
        operatorState.findAllOperatorStatus === 'loading'
    ) {
        return (
            <div className={"flex justify-center items-center flex-grow"}>
                <SpinnerComponent />
            </div>
        )
    }

    if (noticeState.findAllNoticeStatus === "successfully" &&
        noticeState.findAllNoticeResponse !== undefined) {
        noticeState.findAllNoticeResponse.data.forEach((item) => {
            let advisors: string[] = []
            let collaborators: string[] = []
            let companies: string[] = []
            let referrers: string[] = []
            let sentBy: string = "admin"

            if (keycloak.hasRealmRole("admin") &&
                financialAdvisorState.findAllFinancialAdvisorStatus === 'successfully' &&
                financialAdvisorState.findAllFinancialAdvisorResponse !== undefined &&
                collaboratorState.findAllCollaboratorStatus === 'successfully' &&
                collaboratorState.findAllCollaboratorResponse !== undefined &&
                operatorState.findAllOperatorStatus === "successfully" &&
                operatorState.findAllOperatorResponse !== undefined
            ) {
                operatorState.findAllOperatorResponse.data.forEach((operator) => {
                    if (operator.userId === item.userId) {
                        sentBy = operator.name + ' ' + operator.surname
                    }
                })

                financialAdvisorState.findAllFinancialAdvisorResponse?.data.forEach((advisor) => {
                    if (advisor.userId === item.userId) {
                        sentBy = advisor.commercialName
                    }
                })

                collaboratorState.findAllCollaboratorResponse?.data.forEach((collaborator) => {
                    if (collaborator.userId === item.userId) {
                        sentBy = collaborator.name + ' ' + collaborator.surname
                    }
                })
            } else if (
                keycloak.hasRealmRole("operator") &&
                financialAdvisorState.findAllFinancialAdvisorStatus === 'successfully' &&
                financialAdvisorState.findAllFinancialAdvisorResponse !== undefined &&
                collaboratorState.findAllCollaboratorStatus === 'successfully' &&
                collaboratorState.findAllCollaboratorResponse !== undefined &&
                authState.findMeOperatorStatus === "successfully" &&
                authState.findMeOperatorResponse !== undefined
            ) {
                financialAdvisorState.findAllFinancialAdvisorResponse?.data.forEach((advisor) => {
                    if (advisor.userId === item.userId) {
                        sentBy = advisor.commercialName
                    }
                })

                collaboratorState.findAllCollaboratorResponse?.data.forEach((collaborator) => {
                    if (collaborator.userId === item.userId) {
                        sentBy = collaborator.name + ' ' + collaborator.surname
                    }
                })

                if (item.userId === authState.findMeOperatorResponse.userId) {
                    sentBy = authState.findMeOperatorResponse.name + ' ' + authState.findMeOperatorResponse.surname
                }
            }

            if (financialAdvisorState.findAllFinancialAdvisorStatus === 'successfully' &&
                financialAdvisorState.findAllFinancialAdvisorResponse !== undefined && !keycloak.hasRealmRole("financial_advisor")) {
                advisors = financialAdvisorState.findAllFinancialAdvisorResponse?.data.filter(advisor => item.userIds.includes(advisor.userId)).map(name => name.commercialName) || []
            }

            if (collaboratorState.findAllCollaboratorStatus === 'successfully' &&
                collaboratorState.findAllCollaboratorResponse !== undefined && !keycloak.hasRealmRole("financial_advisor_collaborator")) {
                collaborators = collaboratorState.findAllCollaboratorResponse?.data.filter(collaborator => item.userIds.includes(collaborator.userId)).map(name => `${name.name} ${name.surname}`) || []
            }

            if (companyState.findAllCompanyStatus === "successfully" &&
                companyState.findAllCompanyResponse !== undefined && !keycloak.hasRealmRole("company")) {
                companies = companyState.findAllCompanyResponse?.data.filter(advisor => item.userIds.includes(advisor.userId)).map(name => name.companyName) || []
            }

            if (referrerState.findAllReferrersStatus === "successfully" &&
                referrerState.findAllReferrersResponse !== undefined && !keycloak.hasRealmRole("administrative_company")) {
                referrers = referrerState.findAllReferrersResponse?.data.filter(collaborator => item.userIds.includes(collaborator.userId)).map(name => `${name.name} ${name.surname}`) || []
            }

            let receivers = [...advisors, ...collaborators, ...companies, ...referrers]
            noticeRows.push(
                <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100" key={item.id}>
                    <td className="py-[16px] px-[16px]">
                        <Pills
                            size={"sm"}
                            label={NoticeTypeMap.get(item.type)!}
                            outline={false}
                            emphasis={true}
                            color={NoticeTypeColorMap.get(item.type)!}
                        />
                    </td>
                    <td className="py-[16px] px-[8px] text-text-sm font-normal text-neutral-600">{item.subjectLine}</td>
                    {
                        (keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("operator")) &&
                        <td className="py-[16px] px-[8px] text-text-sm font-normal text-neutral-600">{sentBy}</td>
                    }
                    <td className="py-[16px] px-[8px]">
                        <Pills
                            size={"sm"}
                            label={receivers.join(', ').length < 140 ? receivers.join(', ') : receivers.join(', ').substring(0, 140).concat("...")}
                            outline={false}
                            emphasis={false}
                            color={"gray"}
                        />
                    </td>
                    <td className="py-[16px] px-[8px] text-text-sm font-normal text-neutral-600">{item.date && format(new Date(item.date), "dd/MM/yyyy")}</td>
                    {
                        (authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                        (authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) ||
                        (authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                        (authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator")) ||
                        (keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("operator")) &&
                        <td className="w-[40px]">
                            <div className="hover:bg-brandPrimary-50 py-2 px-2 rounded-md">
                                <InfoTextPopup
                                    label={"Cancella"}
                                    children={
                                        <TrashIcon
                                            size={"24"}
                                            color={colors.brandSecondary[500]}
                                            onClick={() => {
                                                setIsDelete(item.id.toString())
                                                dispatch(setOpenConfirmOrDenyModal(true))
                                            }}
                                        />
                                    }
                                    position={"bottom"}
                                />
                            </div>
                        </td>
                    }
                    <td className="w-[40px] cursor-pointer">
                        <Dropdown items={[
                            {
                                label: 'Modifica',
                                icon: <EditIcon color="" size={""} />,
                                onClick: () => {
                                    dispatch(setEditNoticeRequestStatus(NoticeStatus.SENT))
                                    dispatch(setDraft(false))
                                    dispatch(setSaveOrEditNotice('edit'))
                                    dispatch(findByIdNotice(item.id.toString()))
                                    dispatch(setSelectedTab(undefined))
                                }
                            },
                            {
                                label: 'Cancella',
                                icon: <TrashIcon color="" size={""} />,
                                onClick: () => {
                                    setIsDelete(item.id.toString())
                                    dispatch(setOpenConfirmOrDenyModal(true))
                                },
                                visible: (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                    (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) ||
                                    (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                    (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                            }
                        ]} />
                    </td>
                </tr>
            )
        })
    }

    return (
        <>
            <SuccessPopup
                active={noticeState.createNoticeStatus === "successfully"}
                close={() => dispatch(setCreateNoticeStatus('idle'))}
                message={"Avviso inviato correttamente."}
            />
            <SuccessPopup
                active={noticeState.editNoticeStatus === "successfully"}
                close={() => dispatch(setEditNoticeStatus('idle'))}
                message={"Avviso inviato correttamente."}
            />
            <SuccessPopup
                active={noticeState.deleteNoticeStatus === "successfully"}
                close={() => dispatch(setDeleteNoticeStatus('idle'))}
                message={"Avviso eliminato correttamente."}
            />
            <ErrorPopup
                active={noticeState.deleteNoticeStatus === "failed"}
                close={() => dispatch(setDeleteNoticeStatus('idle'))}
                message={"Si é verificato un errore durante l'eliminazione."}
            />
            <div className="w-full flex flex-col gap-4 rounded-tr-xl rounded-tl-xl rounded-b-lg overflow-auto">
                <div>
                    <table className="w-full mb-3">
                        <thead className="h-[56px] bg-neutral-100 font-semibold text-neutral-500 text-text-xs">
                            <tr>
                                <th className="text-left">
                                    <div className="flex py-4 px-4 gap-1">
                                        {noticeState.findAllNoticeResponse?.total !== 0 ? "TIPOLOGIA" : "AVVISI"}
                                    </div>
                                </th>
                                {
                                    noticeState.findAllNoticeResponse?.total !== 0 &&
                                    <>
                                        <th className="text-left">
                                            <div className="flex py-4 px-2 gap-1">
                                                AVVISI
                                            </div>
                                        </th>
                                        {
                                            (keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("operator")) &&
                                            <th className="text-left">
                                                <div className="flex py-4 px-2 gap-1">
                                                    INVIATO
                                                </div>
                                            </th>
                                        }
                                        <th className="text-left">
                                            <div className="flex py-4 px-2 gap-1">
                                                DESTINATARI
                                            </div>
                                        </th>
                                        <th className="text-left">
                                            <div className="flex py-4 px-2 gap-1">
                                                DATA
                                            </div>
                                        </th>
                                        {
                                            (authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                            (authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) ||
                                            (authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                            (authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator")) ||
                                            (keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("operator")) &&
                                            <th className="text-left">
                                                <div className="flex py-4 px-2 gap-1" />
                                            </th>
                                        }
                                        <th className="text-left">
                                            <div className="flex py-4 px-2 gap-1" />
                                        </th>
                                    </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                noticeState.findAllNoticeResponse?.total === 0 ?
                                    <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100">
                                        <td className="py-4 px-4 text-text-sm font-semibold text-neutral-850">Nessun avviso</td>
                                    </tr>
                                    :
                                    noticeRows
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {
                noticeState.findAllNoticeResponse !== undefined &&
                noticeState.findAllNoticeResponse.total > 0 &&
                <div className="flex bg-white drop-shadow-sm items-center justify-center p-2">
                    <Pagination
                        pages={noticeState.findAllNoticeResponse?.totalPage || 1}
                        currentPage={noticeState.noticeFilters.page}
                        setPage={(page) => dispatch(setNoticeFilterPage(page))}
                    />
                </div>
            }
            <ConfirmModal
                open={noticeState.openConfirmOrDenyModal}
                handleClose={() => dispatch(setOpenConfirmOrDenyModal(false))}
                title={"Elimina avviso"}
                description={"Sei sicuro di voler eliminare questo avviso?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    isDelete && dispatch(deleteNotice(isDelete))
                    dispatch(setOpenConfirmOrDenyModal(false))
                }}
            />
        </>
    )
}