import { createSlice } from "@reduxjs/toolkit"
import { colors } from "../colors.ts"
import { MenuItems } from "../organisms/sidebar/dto.ts"
import { MenuStatus } from "./dto.ts"

interface LayoutState {
    menuItems: MenuItems[]
    menuStatus: MenuStatus
    theme: {
        900: string,
        850: string,
        800: string,
        700: string,
        600: string,
        500: string,
        400: string,
        300: string,
        200: string,
        100: string,
        50: string
    }
}

const initialState: LayoutState = {
    menuItems: [],
    menuStatus: MenuStatus.OPEN,
    theme: colors.brandPrimary
}

const layoutSlice = createSlice({
    name: 'layout/slice',
    initialState,
    reducers: {
        setMenuItems: (state, action) => {
            state.menuItems = action.payload
        },
        setMenuStatus: (state, action) => {
            state.menuStatus = action.payload
        },
        setTheme: (state, action) => {
            state.theme = action.payload
        }
    },
})

export const {
    setMenuItems,
    setMenuStatus,
    setTheme
} = layoutSlice.actions

export default layoutSlice.reducer
