import clsx from "clsx";
import React from "react";
import { useAppSelector } from "../../../lib/redux/hook.ts";
import { ProgressBarColor, ProgressBarColorMap } from './dto.ts';

interface Props {
    value: number
    color: ProgressBarColor
    label?: boolean
    active?: number
    total?: string
}

export function ProgressBar(props: Props) {
    const { value } = props
    const { color } = props
    const { label } = props
    const { active } = props
    const { total } = props

    const layoutState = useAppSelector(state => state.layout)

    const formattedActive = active !== undefined ? String(active).padStart(2, '0') : '00';
    const formattedTotal = total !== undefined && total !== "∞" ? total.padStart(2, '0') : total === "∞" ? "∞" : '00';

    return (
        <div style={{ display: 'flex', alignItems: 'center', }}>
            <div style={{
                height: '4px',
                backgroundColor: layoutState.theme[200],
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                borderRadius: '16px',
            }}>
                <div
                    style={{
                        height: '4px',
                        backgroundColor: ProgressBarColorMap(color),
                        width: value + '%',
                        borderRadius: '16px',
                        transition: 'width ease-in-out 1s'
                    }}
                />
            </div>
            {
                label &&
                <span style={{ marginLeft: '12px' }} className='text-label-sm font-medium text-neutral-400'>
                    {value.toFixed(0)}%
                </span>
            }
            {
                (total !== undefined && active !== undefined) && (
                    <div className="flex flex-row items-center gap-1">
                        <span
                            style={{ marginLeft: '12px' }}
                            className='text-label-sm font-medium text-neutral-400'>
                            {formattedActive}
                        </span>
                        <span className='text-label-sm font-medium text-neutral-400'>di</span>
                        <span className={clsx('text-label-sm font-medium text-neutral-400', { "font-sans": formattedTotal === "∞" })}>{formattedTotal}</span>
                    </div>
                )
            }
        </div>
    )
}