import React from "react";
import Select from 'react-select';
import { colors } from "../../colors.ts";
import "./style.css";
import { useAppSelector } from "../../../lib/redux/hook.ts";

interface Props {
    placeholder: string
    options: { value: string | number, label: string | number }[]
    value?: string | string[] | number | null
    defaultValue?: string | string[]
    onChange?: (value?: string) => void
    error?: boolean
    errorLabel?: string
    disabled?: boolean
    isMulti?: boolean
    label?: string
    required?: boolean
}

export function SelectComponent(props: Props) {
    const { placeholder } = props;
    const { options } = props;
    const { onChange } = props;
    const { defaultValue } = props;
    const { error } = props;
    const { errorLabel } = props;
    const { disabled } = props;
    const { isMulti } = props;
    const { label } = props;
    const { required } = props;
    const { value } = props;

    const layoutState = useAppSelector(state => state.layout)

    return (
        <div className={"w-full flex flex-col gap-[6px]"}>
            {
                label &&
                <span className="text-label-sm font-semibold text-neutral-700">
                    {label + (label && required ? ' *' : '')}
                </span>
            }
            <Select
                //@ts-ignore
                onChange={(e) => onChange && onChange(isMulti ? e.map(e => e.value) : e?.value)}
                defaultValue={
                    defaultValue !== undefined ?
                        isMulti ?
                            options?.filter(opt => defaultValue?.includes(opt.value.toString()))
                            :
                            options?.find(opt => opt.value === defaultValue)
                        :
                        null
                }
                value={
                    value !== undefined && value !== null && value !== "" && value !== 0 ?
                        isMulti && typeof value !== "string" && typeof value !== "number" ?
                            options?.filter(opt => value.map(val => val.toString())?.includes(opt.value.toString()))
                            :
                            options?.find(opt => opt.value === value)
                        :
                        null
                }
                isMulti={isMulti}
                isDisabled={disabled}
                isSearchable={false}
                placeholder={placeholder}
                options={options}
                styles={{
                    container: (baseStyles) => ({
                        ...baseStyles,
                        width: '100%'
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: '40px',
                        width: '100%',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        border: (error ? '1px solid ' + colors.red[300] : state.isFocused ? '1px solid ' + colors.neutral[300] : '1px solid ' + colors.neutral[300]),
                        fontFamily: 'Manrope',
                        '&:hover': {
                            border: '1px neutral ' + colors.red[300]
                        },
                        backgroundColor: state.isDisabled ? colors.neutral[100] : colors.white
                    }),
                    indicatorSeparator: (baseStyles, state) => ({
                        display: 'none'
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                        color: state.isFocused ? colors.neutral[200] : colors.neutral[200]
                    }),
                    placeholder: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: colors.neutral[400],
                        fontWeight: 500
                    }),
                    option: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: props.isSelected ? colors.neutral[800] : colors.neutral[600],
                        fontWeight: 500,
                        backgroundColor: props.isSelected ? colors.neutral[400] : colors.white,
                        '&:hover': {
                            backgroundColor: layoutState.theme[200]
                        }
                    }),
                    input: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: colors.neutral[600],
                        fontWeight: 500
                    }),
                    singleValue: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: colors.neutral[600],
                        fontWeight: 500
                    }),
                    multiValue: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: colors.neutral[700],
                        fontWeight: 500,
                        padding: '0px 8px',
                        borderRadius: 8,
                        backgroundColor: layoutState.theme[200],
                        border: '1px solid ' + layoutState.theme[400],
                    }),
                    multiValueRemove: (baseStyles, props) => ({
                        ...baseStyles,
                        color: props.isDisabled ? colors.neutral[300] : colors.neutral[700],
                        '&:hover': {
                            color: layoutState.theme[600],
                            backgroundColor: layoutState.theme[200],
                        }
                    }),
                }}
            />
            {
                error &&
                <span className="text-text-sm text-red-500">{errorLabel}</span>
            }
        </div>
    )
}