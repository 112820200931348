import React from 'react';
import { IconsProps } from './dto.ts';
export function ShoppingBagIcon(props: IconsProps) {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 8.50008L8 3.16675H24L28 8.50008M4 8.50008V27.1667C4 27.874 4.28095 28.5523 4.78105 29.0524C5.28115 29.5525 5.95942 29.8334 6.66667 29.8334H25.3333C26.0406 29.8334 26.7189 29.5525 27.219 29.0524C27.719 28.5523 28 27.874 28 27.1667V8.50008M4 8.50008H28M21.3333 13.8334C21.3333 15.2479 20.7714 16.6045 19.7712 17.6047C18.771 18.6048 17.4145 19.1667 16 19.1667C14.5855 19.1667 13.229 18.6048 12.2288 17.6047C11.2286 16.6045 10.6667 15.2479 10.6667 13.8334" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}




