import clsx from "clsx";
import React, { ReactElement, ReactNode } from "react";
import { useAppSelector } from "../../../lib/redux/hook.ts";

interface Props {
    items: string[]
    icon: ReactElement
}
export function Breadcrumb(props: Props) {
    const { items } = props
    const { icon } = props

    const layoutState = useAppSelector(state => state.layout)

    let breadcrumbItems: ReactNode[] = []

    items.forEach((item, index) => {
        breadcrumbItems.push(
            <p
                key={item + index}
                style={{ margin: 0, color: (index + 1) === items.length ? layoutState.theme[500] : '' }}
                className={clsx
                    (
                        "text-sm font-medium",
                        {
                            "text-neutral-500": (index + 1) !== items.length,
                        }
                    )
                }
            >
                {item}
                {
                    (index + 1) !== items.length && (
                        <span
                            style={{ marginLeft: '8px', marginRight: '8px' }}
                            className="text-md font-medium text-neutral-200">
                            /
                        </span>
                    )
                }
            </p>
        )
    })

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {icon}
            <div style={{ marginRight: '8px' }} />
            {breadcrumbItems}
        </div>
    )
}