import clsx from 'clsx'
import { format } from "date-fns"
import React, { ReactElement, useEffect, useState } from "react"
import { keycloak } from "../../../lib/keycloak/index.ts"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts"
import { colors } from "../../../ui/colors.ts"
import { CompanyIcon } from "../../../ui/icons/companyIcon.tsx"
import { GroupIcon } from "../../../ui/icons/groupIcon.tsx"
import { Badges } from "../../../ui/molecules/badge/index.tsx"
import { findByUserId } from "../../auth/slice.ts"
import { ChatDTO } from "./dto.ts"
import { findMessages, setChatList, setChatSeen, setSelectedChat, setUsersListAfterSend } from "./slice.ts"

export function UsersList() {
    const layoutState = useAppSelector(state => state.layout)
    const chatState = useAppSelector(state => state.chat)
    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch()

    const [isHover, setIsHover] = useState<number | null>(0)
    const [selected, setSelected] = useState<number>(chatState.selectedChat)

    let chatList: { chat: ChatDTO, name: string, icon: ReactElement, members: number, lastMessage: string | undefined }[] = []

    useEffect(() => {
        let userList: string[] = []

        if (chatState.findChatsStatus === 'successfully' && chatState.findChatResponse.length > 0) {
            chatState.findChatResponse.forEach(user => {
                user.users.forEach(userId => {
                    if (!user.active) {
                        userList.push('')
                    } else if (!userList.includes(userId)) {
                        userList.push(userId)
                    }
                })
            })
        }
        if (chatState.usersListAfterSend.length === 0)
            dispatch(findByUserId(userList))
        dispatch(setUsersListAfterSend([...userList]))
    }, [chatState.findChatsStatus])

    if (
        (chatState.findChatsStatus === "successfully" &&
            chatState.findChatResponse !== undefined) ||
        (authState.findMeCompanyStatus === "successfully" &&
            authState.findMeCompanyResponse !== undefined) ||
        (companyState.findByIdCompanyStatus === "successfully" && companyState.findByIdCompanyResponse !== undefined)
    ) {

        // chatList.push({
        //     chat: { id: 0, users: [], lastMessageDate: new Date(), topic: "", active: false, notSeenMessages: 0 },
        //     name: "Chat Bot",
        //     icon: <UserIcon size={"32"} color={layoutState.theme[50]} />
        // });

        chatState.findChatResponse.forEach((chat) => {
            let company: boolean = false
            if (keycloak.hasRealmRole("company"))
                company = chat.users.includes(authState.findMeCompanyResponse !== undefined ? authState.findMeCompanyResponse.userId : '');
            else
                company = chat.users.includes(companyState.findByIdCompanyResponse !== undefined ? companyState.findByIdCompanyResponse.userId : '');

            chatList.push({
                chat: chat,
                name: company ? 'Chat Azienda' : 'Gruppo di lavoro',
                icon: company ? <CompanyIcon size={"24"} color={layoutState.theme[50]} /> : <GroupIcon size={"32"} color={layoutState.theme[50]} />,
                members: chat.users.length,
                lastMessage: chatState.findLastMessageResponse?.find(messages => messages.topic === chat.id)?.messages.data.length === 0 ? '' : chatState.findLastMessageResponse?.find(messages => messages.topic === chat.id)?.messages.data[0].message ?? ''
            });
        });
    }

    return (
        <div className="bg-white w-[40%] py-4 px-4 rounded-xl border border-rgba(225, 227, 234, 1) overflow-auto flex-grow">
            <div className="flex flex-col w-full gap-4 flex-grow">
                <div style={{ overflow: 'auto', height: 'calc(100% - 50px)' }}>
                    {
                        chatList.map((chat) => (
                            <div
                                key={chat.chat.id}
                                onMouseEnter={() => setIsHover(chat.chat.id)}
                                onMouseLeave={() => setIsHover(null)}
                                onClick={() => {
                                    if (chat.chat.id !== chatState.selectedChat) {
                                        setSelected(chat.chat.id)
                                        dispatch(findMessages({
                                            chatId: chat.chat.id,
                                            filter:
                                            {
                                                page: 0,
                                                itemsPerPage: (chat && chat.chat.notSeenMessages > 0 ? (chat.chat.notSeenMessages + 25) : 25),
                                                order: chatState.messageFilter.order,
                                                sort: chatState.messageFilter.sort
                                            }
                                        }))
                                        dispatch(setSelectedChat(chat.chat.id))
                                        dispatch(setChatSeen(chat.chat.id))
                                        dispatch(setChatList(chat))
                                    }
                                }}
                                style={{ borderColor: selected === chat.chat.id ? layoutState.theme[200] : colors.white, backgroundColor: isHover === chat.chat.id || selected === chat.chat.id ? layoutState.theme[100] : colors.white }}
                                className={clsx("cursor-pointer flex flex-row items-center justify-between w-full gap-3 p-2 rounded-lg", {
                                    "border": selected === chat.chat.id
                                })}
                            >
                                <div className="flex flex-row items-center gap-3 flex-grow">
                                    <div className="w-[48px] h-[48px] rounded-[240px] flex justify-center items-center" style={{ backgroundColor: layoutState.theme[500] }}>
                                        {chat.icon}
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="text-text-md font-bold text-neutral-800">
                                            {chat.name}
                                        </span>
                                        <span className="text-text-sm font-normal text-neutral-500">
                                            {chat.lastMessage}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex flex-col items-end gap-[6px]">
                                    <div className='text-label-xs text-neutral-500'>
                                        {chat.chat.lastMessageDate && (format(new Date(chat.chat.lastMessageDate), 'dd/MM/yyyy') === format(new Date(), 'ddMMyyyy') ? format(new Date(chat.chat.lastMessageDate), 'dd/mm') : format(new Date(chat.chat.lastMessageDate), 'dd/MM'))}
                                    </div>
                                    {
                                        chat.chat.notSeenMessages > 0 &&
                                        <div className="w-auto">
                                            <Badges size={"xs"} label={chat.chat.notSeenMessages > 100 ? '99+' : chat.chat.notSeenMessages.toString()} outline={false} emphasis={true} color={"red"} />
                                        </div>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}