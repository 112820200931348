import React, { ReactElement, cloneElement, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { HeadingSize, headingSizeMap, headingTitleMap } from "./dto.ts";

interface Props {
    label: string | ReactElement,
    buttons: ReactElement[]
    subLabel?: string
    size: HeadingSize
}

export function HeadingComponent(props: Props) {
    const { label } = props
    const { buttons } = props
    const { subLabel } = props
    const { size } = props

    const [parsedButton, setParsedButton] = useState<ReactElement[]>([])

    useEffect(() => {
        let newButtonList = [...buttons]
        if (buttons.length > 0) {
            setParsedButton([])
            newButtonList.forEach((button, index) => newButtonList[index] = cloneElement(button, { size: headingSizeMap.get(size), key: uuidv4() }))
        }
        setParsedButton(newButtonList)
    }, [buttons])

    return (
        <div className={"rounded-3xl"}>
            <div className={"gap-3"}>
                <div className={"flex flex-row justify-between items-center"}>
                    <p className="m-0 p-0 items-center flex" style={{ textAlign: 'left' }}>
                        <span className={headingTitleMap.get(size)}>{label}</span>
                        <br />
                        <span className="text-text-md font-normal text-neutral-400">{subLabel}</span>
                    </p>
                    {
                        parsedButton.length > 0 && (
                            <div className="m-0 p-0">
                                <div className="flex flex-row" style={{ gap: '12px', transition: 'all 0.2s ease-in-out' }}>{parsedButton}</div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )

}
