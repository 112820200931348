import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { OperatorIcon } from "../../ui/icons/operatorIcon.tsx";
import { LayoutComponent } from "../../ui/layout/index.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { ErrorPopup } from "../../ui/organisms/errorPopup/index.tsx";
import { Pagination } from "../../ui/organisms/pagination/index.tsx";
import { MenuItems } from "../../ui/organisms/sidebar/dto.ts";
import { SuccessPopup } from "../../ui/organisms/successPopup/index.tsx";
import { OperatorFilters } from "./filters.tsx";
import { OperatorList } from "./list.tsx";
import { NewOperatorModal } from "./newOperatorModal.tsx";
import { findAllOperator, setCreateOperatorRequestEmail, setCreateOperatorRequestName, setCreateOperatorRequestSurname, setCreateOperatorStatus, setDeleteOperatorStatus, setEditOperatorStatus, setOpenNewOperatorModal, setOperatorFilterPage } from "./slice.ts";

export function Operator() {
    const dispatch = useAppDispatch()
    const operatorState = useAppSelector(state => state.operator)
    const layoutState = useAppSelector(state => state.layout)

    useEffect(() => {
        dispatch(findAllOperator(operatorState.operatorFilters))
    }, [operatorState.operatorFilters.page])

    return (
        <LayoutComponent
            breadcrumbItems={['Operatori']}
            headingLabel={"Operatori"}
            headingButtons={[
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', gap: 16 }}>
                    <ButtonComponent
                        label={"Nuovo operatore"}
                        onClick={() => {
                            dispatch(setCreateOperatorRequestName(""))
                            dispatch(setCreateOperatorRequestSurname(""))
                            dispatch(setCreateOperatorRequestEmail(""))
                            dispatch(setOpenNewOperatorModal(true))
                        }}
                        color={"blue"}
                        variant={"solid"}
                        size={"md"}
                        iconPosition="off"
                    />
                </div>
            ]}
            menuItem={MenuItems.OPERATOR}
            breadcrumbIcon={<OperatorIcon color={layoutState.theme[500]} size={"20"} />}
        >
            <ErrorPopup
                active={operatorState.createOperatorStatus === 'failed'}
                close={() => dispatch(setCreateOperatorStatus('idle'))}
                message="Si è verificato un errore durante la creazione dell'operatore"
            />
            <SuccessPopup
                active={operatorState.createOperatorStatus === 'successfully'}
                close={() => dispatch(setCreateOperatorStatus('idle'))}
                message="Operatore aggiunto"
            />
            <ErrorPopup
                active={operatorState.deleteOperatorStatus === 'failed'}
                close={() => dispatch(setDeleteOperatorStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione dell'operatore"
            />
            <SuccessPopup
                active={operatorState.deleteOperatorStatus === 'successfully'}
                close={() => dispatch(setDeleteOperatorStatus('idle'))}
                message="Operatore eliminato"
            />
            <ErrorPopup
                active={operatorState.editOperatorStatus === 'failed'}
                close={() => dispatch(setEditOperatorStatus('idle'))}
                message="Si è verificato un errore durante la modifica dell'operatore"
            />
            <SuccessPopup
                active={operatorState.editOperatorStatus === 'successfully'}
                close={() => dispatch(setEditOperatorStatus('idle'))}
                message="Operatore modificato"
            />
            <OperatorFilters />
            <OperatorList />
            {
                operatorState.findAllOperatorResponse !== undefined &&
                operatorState.findAllOperatorResponse?.total > 0 &&
                <div className="flex bg-white drop-shadow-sm items-center justify-center p-2">
                    <Pagination
                        pages={operatorState.findAllOperatorResponse?.totalPage || 1}
                        currentPage={operatorState.operatorFilters.page}
                        setPage={(page) => dispatch(setOperatorFilterPage(page))}
                    />
                </div>
            }
            <NewOperatorModal />
        </LayoutComponent>
    )
}