import { format } from "date-fns";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { keycloak } from "../../lib/keycloak/index.ts";
import { NewObjectService } from "../../lib/object/service.ts";
import { findAvatarFinancialByObjectId, findFileById, setFindDocumentStatus } from "../../lib/object/slice.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { colors } from "../../ui/colors.ts";
import { CellulareIcon } from "../../ui/icons/cellularIcon.tsx";
import { ChatIcon } from "../../ui/icons/chatIcon.tsx";
import { CloseIcon } from "../../ui/icons/closeIcon.tsx";
import { DashboardIcon } from "../../ui/icons/dashboardIcon.tsx";
import { DownloadIcon } from "../../ui/icons/downloadIcon.tsx";
import { EmailIcon } from "../../ui/icons/emailIcon.tsx";
import { OtherIcon } from "../../ui/icons/otherIcon.tsx";
import { PdfIcon } from "../../ui/icons/pdfIcon.tsx";
import { RadioIcon } from "../../ui/icons/radioIcon.tsx";
import { ReceivedFileIcon } from "../../ui/icons/receivedFileIcon.tsx";
import { XlsxIcon } from "../../ui/icons/xlsxIcon.tsx";
import { ZipIcon } from "../../ui/icons/zipIcon.tsx";
import { LayoutComponent } from "../../ui/layout/index.tsx";
import Avatar from "../../ui/molecules/avatar/index.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { EmptyList } from "../../ui/organisms/emptyList/index.tsx";
import { GroupedRow } from "../../ui/organisms/groupedRow/index.tsx";
import { SegmentControl } from "../../ui/organisms/segmentControl/index.tsx";
import { MenuItems } from "../../ui/organisms/sidebar/dto.ts";
import { DocumentType } from "../company/documents/dto.ts";
import { findAllDocuments, findAllReceivedDocuments, findByIdDocument, findExtensionsFiles, setDocumentFilterItemsPerPage, setDocumentFilterType, setFindAllDocumentsStatus, setFindAllReceivedDocumentsStatus, setFindExtensionsFilesStatus } from "../company/documents/slice.ts";
import { Events } from "../company/events/index.tsx";
import { findAllNotice } from "../notice/slice.ts";
import { setSelectedDocumentType } from "./slice.ts";

export function DashboardCompany() {
    const authState = useAppSelector(state => state.auth)
    const noticeState = useAppSelector(state => state.notice)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const documentState = useAppSelector(state => state.document)
    const dashboardState = useAppSelector(state => state.dashboard)
    const companyState = useAppSelector(state => state.company)
    const objectState = useAppSelector(state => state.object)
    const layoutState = useAppSelector(state => state.layout)
    const chatState = useAppSelector(state => state.chat)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const objectService = NewObjectService()

    const [closeNotice, setCloseNotice] = useState(false)

    let documentRow: ReactNode[] = []
    let receivedDocumentRow: ReactNode[] = []

    const iconMap = new Map<string, ReactElement>([
        [".png", <ZipIcon size={"24"} color={colors.brandPrimary[500]} />],
        [".jpg", <ZipIcon size={"24"} color={colors.brandPrimary[500]} />],
        [".jpeg", <ZipIcon size={"24"} color={colors.brandPrimary[500]} />],
        [".pdf", <PdfIcon size={"24"} color={colors.red[500]} />],
        [".xlsx", <XlsxIcon size={"24"} color={colors.green[500]} />],
        [".xls", <XlsxIcon size={"24"} color={colors.green[500]} />],
        [".zip", <ZipIcon size={"24"} color={colors.brandPrimary[500]} />],
    ]);

    useEffect(() => {
        if (authState.findMeCompanyStatus === "successfully" || (authState.findMeReferrersStatus === "successfully" && companyState.findByIdCompanyStatus === "successfully")) {
            dispatch(setDocumentFilterItemsPerPage(5))
            dispatch(findAllNotice(noticeState.noticeFilters))
            dispatch(findAllDocuments({
                companyId: keycloak.hasRealmRole("company") ? Number(authState.findMeCompanyResponse?.id) : Number(authState.findMeReferrersResponse?.company),
                itemsPerPage: 5,
                order: false,
                page: 0,
                receiverGroup: "",
                referenceYear: 0,
                referenceMonth: 0,
                senderGroup: "",
                sort: "",
                type: DocumentType.balance,
                typology: "",
                fileName: "",
                status: ""
            }))

        }
    }, [authState.findMeCompanyStatus, authState.findMeReferrersStatus, companyState.findByIdCompanyStatus])

    useEffect(() => {
        dispatch(findAvatarFinancialByObjectId(financialAdvisorState.findByIdFinancialAdvisorResponse?.avatarObjectId!))
    }, [financialAdvisorState.findByIdFinancialAdvisorResponse])

    useEffect(() => {
        if (documentState.findAllDocumentsStatus === "successfully" && documentState.findAllDocumentsResponse !== undefined) {
            const updatedRequest = {
                ...documentState.findExtensionsFilesRequest,
                ids: [
                    ...documentState.findExtensionsFilesRequest.ids,
                    ...documentState.findAllDocumentsResponse.data.map(data => String(data.objectId))
                ]
            };
            dispatch(findExtensionsFiles(updatedRequest))
        }
    }, [documentState.findAllDocumentsStatus])

    useEffect(() => {
        if (documentState.findAllReceivedDocumentsStatus === "successfully" && documentState.findAllReceivedDocumentsResponse !== undefined) {
            const updatedRequest = {
                ...documentState.findExtensionsFilesRequest,
                ids: [
                    ...documentState.findExtensionsFilesRequest.ids,
                    ...documentState.findAllReceivedDocumentsResponse.data.map(data => String(data.objectId))
                ]
            };
            dispatch(findExtensionsFiles(updatedRequest))
        }
    }, [documentState.findAllDocumentsStatus])

    if (authState.findMeCompanyStatus === "loading" ||
        financialAdvisorState.findByIdFinancialAdvisorStatus === "loading" ||
        noticeState.findAllNoticeStatus === "loading"
    ) {
        return (
            <div className={"flex justify-center items-center"}>
                <SpinnerComponent />
            </div>
        )
    }

    if (documentState.findAllDocumentsStatus === "successfully" &&
        documentState.findAllDocumentsResponse !== undefined &&
        documentState.findAllDocumentsResponse.data !== undefined &&
        documentState.findExtensionsFilesStatus === "successfully"
    ) {
        documentState.findAllDocumentsResponse.data.forEach((item, index) => {
            documentRow.push(
                <GroupedRow
                    key={item.id}
                    toRead={item.companiesDownloadDate !== null}
                    icon={iconMap.get(documentState.findExtensionsFilesResponse?.extensions[index]!)!}
                    label={item.fileName}
                    subLabel={format(item.date, "dd-MM-yyyy")}
                    secondIcon={
                        <DownloadIcon
                            size={"24"}
                            color={layoutState.theme[500]}
                            onClick={() => {
                                dispatch(findFileById(item.objectId.toString())).then((e) => {
                                    //@ts-ignore
                                    objectService.downloadBase64WithExtension(item.fileName, e.payload !== null ? e.payload : '')
                                    dispatch(findByIdDocument(item.id.toString())).then(() => {
                                        dispatch(setFindDocumentStatus('idle'))
                                        dispatch(findAllDocuments(documentState.documentFilters))
                                    })
                                })
                            }}
                        />}
                />
            )
        })
    }

    if (documentState.findAllReceivedDocumentsStatus === "successfully" &&
        documentState.findAllReceivedDocumentsResponse !== undefined &&
        documentState.findAllReceivedDocumentsResponse.data !== undefined &&
        documentState.findExtensionsFilesStatus === "successfully" &&
        documentState.findExtensionsFilesResponse !== undefined
    ) {
        documentState.findAllReceivedDocumentsResponse.data.forEach((item, index) => {
            receivedDocumentRow.push(
                <GroupedRow
                    key={item.id}
                    toRead={item.companiesDownloadDate !== null}
                    icon={iconMap.get(documentState.findExtensionsFilesResponse?.extensions[index]!)!}
                    label={item.fileName}
                    subLabel={format(item.date, "dd-MM-yyyy")}
                    secondIcon={<DownloadIcon
                        size={"24"}
                        color={layoutState.theme[500]}
                        onClick={() => {
                            dispatch(findFileById(item.objectId.toString())).then((e) => {
                                //@ts-ignore
                                objectService.downloadBase64WithExtension(item.fileName, e.payload !== null ? e.payload : '')
                                dispatch(findByIdDocument(item.id.toString())).then(() => {
                                    dispatch(setFindDocumentStatus('idle'))
                                    dispatch(findAllReceivedDocuments({
                                        companyId: authState.findMeCompanyResponse?.id !== undefined ? Number(authState.findMeCompanyResponse?.id) : Number(authState.findMeReferrersResponse?.company),
                                        itemsPerPage: 5,
                                        order: false,
                                        page: 0,
                                        receiverGroup: "companies",
                                        referenceYear: 0,
                                        referenceMonth: 0,
                                        senderGroup: "",
                                        sort: "",
                                        type: DocumentType.accounting,
                                        typology: "",
                                        fileName: "",
                                        status: ""
                                    }))
                                })
                            })
                        }}
                    />}
                />
            )
        })
    }

    return (
        <LayoutComponent
            menuItem={MenuItems.DASHBOARD}
            breadcrumbItems={['Dashboard']}
            headingLabel={"Dashboard"}
            headingButtons={[]}
            breadcrumbIcon={<DashboardIcon color={layoutState.theme[500]} size="24" />}
        >
            <div className={"flex flex-col gap-4"}>
                <div className="h-[64px] rounded-xl border-[0.5px] flex flex-row header-widget">
                    <div
                        style={{
                            backgroundColor: layoutState.theme[50],
                            borderTopLeftRadius: 12,
                            borderBottomLeftRadius: 12,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: 16,
                            padding: "12px 32px 12px 16px"
                        }}
                    >
                        <div className="flex gap-3 flex-row items-center">
                            <Avatar type="single" size="sm" shape="circle" imageUrl={objectState.findAvatarFinancialResponse!} altTextInitials={(financialAdvisorState.findByIdFinancialAdvisorResponse?.name[0]! + financialAdvisorState.findByIdFinancialAdvisorResponse?.surname[0])} />
                            <div className="flex gap-1 flex-col">
                                <span className="text-label-xs font-medium text-neutral-500">
                                    Il tuo Commercialista
                                </span>
                                <span className={"text-text-md text-neutral-850 font-bold"}>
                                    {financialAdvisorState.findByIdFinancialAdvisorResponse?.name + ' ' + financialAdvisorState.findByIdFinancialAdvisorResponse?.surname}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="border-l border-brandSecondary-100 bg-white px-4 py-3 flex flex-row gap-[6px] items-center">
                        <EmailIcon size={"20"} color={layoutState.theme[300]} />
                        <span className="text-label-sm font-medium" style={{ color: layoutState.theme[500] }}>{financialAdvisorState.findByIdFinancialAdvisorResponse?.email}</span>
                    </div>
                    <div className="border-l border-brandSecondary-100 bg-white px-4 py-3 flex flex-row gap-[6px] items-center">
                        <CellulareIcon size={"20"} color={layoutState.theme[300]} />
                        <span className="text-label-sm font-medium" style={{ color: layoutState.theme[500] }}>{financialAdvisorState.findByIdFinancialAdvisorResponse?.phoneNumber}</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", padding: "12px 24px 12px 24px", gap: 24, borderLeftWidth: 1, borderColor: colors.brandSecondary[100] }}>
                        <div className="gap-[6px] flex flex-row items-center">
                            <ChatIcon size={"20"} color={colors.brandSecondary[400]} />
                            <span className="text-label-sm font-bold text-neutral-850">
                                Ultimo messaggio
                            </span>
                        </div>
                        <div className="gap-1 flex flex-row items-center">
                            <span className="text-text-sm font-normal text-neutral-850">
                                {chatState.unSeenMessagesResponse?.find(unseen => unseen.unseen)?.unseen === undefined ? "Non hai ricevuto nuovi messaggi" : chatState.unSeenMessagesResponse?.find(message => message.lastMessage)?.lastMessage}
                            </span>
                            {
                                chatState.unSeenMessagesResponse?.find(unseen => unseen.unseen)?.unseen !== undefined &&
                                <span className="text-text-sm font-semibold cursor-pointer" style={{ color: layoutState.theme[500] }} onClick={() => navigate('/message')}>
                                    leggi tutto →
                                </span>
                            }
                        </div>
                    </div>
                </div>
                {
                    !closeNotice &&
                    <div
                        className="h-[48px] p-3 rounded-xl gap-6 flex flex-row"
                        style={{ backgroundColor: layoutState.theme[50], borderWidth: 0.5, borderColor: layoutState.theme[200] }}
                    >
                        <div className="flex flex-row gap-2 items-center">
                            <RadioIcon size={"24"} color={layoutState.theme[500]} />
                            <span className="text-label-md text-neutral-850 font-semibold">Avviso</span>
                        </div>
                        <div className="gap-1 flex flex-row items-center justify-between w-full">
                            <div>
                                <span className="text-text-sm font-normal text-neutral-850">
                                    {
                                        noticeState.findAllNoticeResponse?.data.length! > 0 ? noticeState.findAllNoticeResponse?.data[0].subjectLine : "Nessun avviso ricevuto!"
                                    }
                                </span>
                                {
                                    noticeState.findAllNoticeResponse?.data.length! > 0 &&
                                    <span className="text-text-sm font-semibold cursor-pointer" style={{ color: layoutState.theme[500] }} onClick={() => navigate('/notice')}>
                                        {" leggi tutto →"}
                                    </span>
                                }
                            </div>
                            <div className={"rounded-[20.57px] border-[0.86px] gap-[6.86px] cursor-pointer"} style={{ borderColor: layoutState.theme[200], background: layoutState.theme[50] }}>
                                <CloseIcon size={"24"} color={layoutState.theme[700]} onClick={() => setCloseNotice(true)} />
                            </div>
                        </div>
                    </div>
                }
                <div className="flex flex-row gap-6">
                    <div className="p-6 rounded-xl flex flex-col gap-6 w-full" style={{ boxShadow: "0px 4px 24px -8px rgba(26, 37, 255, 0.08)" }}>
                        <div className="flex flex-row gap-2 items-center">
                            <OtherIcon size={"24"} color={layoutState.theme[400]} />
                            <span className="text-label-md font-bold text-neutral-800">Documenti</span>
                        </div>
                        <div className="flex flex-row justify-start gap-3 w-1/2">
                            <SegmentControl
                                selected={dashboardState.selectedDocumentType === 0}
                                label={"Bilanci"}
                                onClick={() => {
                                    dispatch(setFindExtensionsFilesStatus("idle"))
                                    dispatch(setDocumentFilterType(DocumentType.balance))
                                    dispatch(setDocumentFilterItemsPerPage(5))
                                    dispatch(setFindAllReceivedDocumentsStatus("idle"))
                                    dispatch(setSelectedDocumentType(0))
                                    dispatch(findAllDocuments({
                                        companyId: authState.findMeCompanyResponse?.id !== undefined ? Number(authState.findMeCompanyResponse?.id) : Number(authState.findMeReferrersResponse?.company),
                                        itemsPerPage: 5,
                                        order: false,
                                        page: 0,
                                        receiverGroup: "",
                                        referenceYear: 0,
                                        referenceMonth: 0,
                                        senderGroup: "",
                                        sort: "",
                                        type: DocumentType.balance,
                                        typology: "",
                                        fileName: "",
                                        status: ""
                                    }))
                                }}
                            />
                            <SegmentControl
                                selected={dashboardState.selectedDocumentType === 1}
                                label={"F24"}
                                onClick={() => {
                                    dispatch(setFindExtensionsFilesStatus("idle"))
                                    dispatch(setDocumentFilterType(DocumentType.f24))
                                    dispatch(setDocumentFilterItemsPerPage(5))
                                    dispatch(setFindAllReceivedDocumentsStatus("idle"))
                                    dispatch(setSelectedDocumentType(1))
                                    dispatch(findAllDocuments({
                                        companyId: authState.findMeCompanyResponse?.id !== undefined ? Number(authState.findMeCompanyResponse?.id) : Number(authState.findMeReferrersResponse?.company),
                                        itemsPerPage: 5,
                                        order: false,
                                        page: 0,
                                        receiverGroup: "",
                                        referenceYear: 0,
                                        referenceMonth: 0,
                                        senderGroup: "",
                                        sort: "",
                                        type: DocumentType.f24,
                                        typology: "",
                                        fileName: "",
                                        status: ""
                                    }))
                                }}
                            />
                            <SegmentControl
                                selected={dashboardState.selectedDocumentType === 2}
                                label={"Dipendenti"}
                                onClick={() => {
                                    dispatch(setFindExtensionsFilesStatus("idle"))
                                    dispatch(setDocumentFilterType(DocumentType.employees))
                                    dispatch(setDocumentFilterItemsPerPage(5))
                                    dispatch(setFindAllReceivedDocumentsStatus("idle"))
                                    dispatch(setSelectedDocumentType(2))
                                    dispatch(findAllDocuments({
                                        companyId: authState.findMeCompanyResponse?.id !== undefined ? Number(authState.findMeCompanyResponse?.id) : Number(authState.findMeReferrersResponse?.company),
                                        itemsPerPage: 5,
                                        order: false,
                                        page: 0,
                                        receiverGroup: "",
                                        referenceYear: 0,
                                        referenceMonth: 0,
                                        senderGroup: "",
                                        sort: "",
                                        type: DocumentType.employees,
                                        typology: "",
                                        fileName: "",
                                        status: ""
                                    }))
                                }}
                            />
                            <SegmentControl
                                selected={dashboardState.selectedDocumentType === 3}
                                label={"Altro"}
                                onClick={() => {
                                    dispatch(setFindExtensionsFilesStatus("idle"))
                                    dispatch(setDocumentFilterType(DocumentType.other))
                                    dispatch(setDocumentFilterItemsPerPage(5))
                                    dispatch(setFindAllReceivedDocumentsStatus("idle"))
                                    dispatch(setSelectedDocumentType(3))
                                    dispatch(findAllDocuments({
                                        companyId: authState.findMeCompanyResponse?.id !== undefined ? Number(authState.findMeCompanyResponse?.id) : Number(authState.findMeReferrersResponse?.company),
                                        itemsPerPage: 5,
                                        order: false,
                                        page: 0,
                                        receiverGroup: "",
                                        referenceYear: 0,
                                        referenceMonth: 0,
                                        senderGroup: "",
                                        sort: "",
                                        type: DocumentType.other,
                                        typology: "",
                                        fileName: "",
                                        status: ""
                                    }))
                                }}
                            />
                            <SegmentControl
                                selected={dashboardState.selectedDocumentType === 4}
                                label={"Contabili"}
                                onClick={() => {
                                    dispatch(setFindExtensionsFilesStatus("idle"))
                                    dispatch(setDocumentFilterType(DocumentType.accounting))
                                    dispatch(setDocumentFilterItemsPerPage(5))
                                    dispatch(setFindAllDocumentsStatus("idle"))
                                    dispatch(setSelectedDocumentType(4))
                                    dispatch(findAllReceivedDocuments({
                                        companyId: authState.findMeCompanyResponse?.id !== undefined ? Number(authState.findMeCompanyResponse?.id) : Number(authState.findMeReferrersResponse?.company),
                                        itemsPerPage: 5,
                                        order: false,
                                        page: 0,
                                        receiverGroup: "companies",
                                        referenceYear: 0,
                                        referenceMonth: 0,
                                        senderGroup: "",
                                        sort: "",
                                        type: DocumentType.accounting,
                                        typology: "",
                                        fileName: "",
                                        status: ""
                                    }))
                                }}
                            />
                        </div>
                        <div className="flex flex-col rounded-2xl gap-3 flex-grow">
                            <div className="flex flex-row gap-2 px-2 items-center">
                                <ReceivedFileIcon size={"20"} color={layoutState.theme[400]} />
                                <span className="text-label-md font-bold text-neutral-800">Ultimi ricevuti</span>
                            </div>
                            {
                                (documentState.findAllDocumentsStatus === "loading" ||
                                    documentState.findExtensionsFilesStatus === "loading") ?
                                    <div className="flex justify-center items-center flex-grow">
                                        <SpinnerComponent />
                                    </div>
                                    :
                                    documentState.findAllDocumentsStatus === "successfully" && documentState.findAllDocumentsResponse?.total === 0 ?
                                        <div className="flex justify-center items-center flex-grow">
                                            <EmptyList />
                                        </div>
                                        :
                                        <div className="w-full flex flex-col gap-2 max-h-[430px] overflow-y-auto">
                                            {documentRow}
                                        </div>
                            }
                            {
                                documentState.findAllReceivedDocumentsStatus === "loading" ?
                                    <div className="flex justify-center items-center flex-grow">
                                        <SpinnerComponent />
                                    </div>
                                    :
                                    documentState.findAllReceivedDocumentsStatus === "successfully" && documentState.findAllReceivedDocumentsResponse?.total === 0 ?
                                        <div className="flex justify-center items-center flex-grow">
                                            <EmptyList />
                                        </div>
                                        :
                                        <div className="w-full flex flex-col gap-2 max-h-[430px] overflow-y-auto">
                                            {receivedDocumentRow}
                                        </div>
                            }
                        </div>
                    </div>
                    <div className="p-6 rounded-xl bg-white flex flex-col gap-6 w-full" style={{ boxShadow: "0px 4px 24px -8px rgba(26, 37, 255, 0.08)" }}>
                        <Events />
                    </div>
                </div>
            </div>
        </LayoutComponent>
    )
}