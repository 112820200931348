import React from "react";

export function EmptyList() {

    return (
        <div className={'empty-list flex flex-col items-center justify-center flex-grow m-0'}>
            <div className="text-heading-sm font-medium text-neutral-800">
                Nessun risultato trovato
            </div>
            <div className="text-heading-xs font-medium text-neutral-400" style={{ textAlign: 'center' }}>La tua ricerca non ha prodotto risultati.</div>
        </div>
    );
}