import { FindAllReferrersResponseDTO, ReferrersDTO, ReferrersFilterDTO } from "./dto.ts";
import { ReferrersServiceImpl } from "./serviceImpl.ts";

export interface ReferrersService {
    findAllReferrers(filters: ReferrersFilterDTO): Promise<FindAllReferrersResponseDTO>
    findByIdReferrer(id: string): Promise<ReferrersDTO>
    createReferrer(request: FormData): Promise<void>
    editReferrer(id: string, request: FormData): Promise<void>
    deleteReferrer(id: string): Promise<void>
}

export function NewReferrersService(): ReferrersService {
    return new ReferrersServiceImpl();
}
