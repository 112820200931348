import { FinancialAdvisorPromoResponseDTO } from "../dto.ts"
import { PlanSelectionRequest, PlanSelectionResponse } from "./dto.ts"
import { SpontaneousFinancialadvisorServiceImpl } from "./serviceImpl.ts"

export interface SpontaneousFinancialAdvisorService{
    createNewSpontaneous(request:FormData): Promise<string>
    selectPlanSpontaneous(id: string, request: PlanSelectionRequest) : Promise<PlanSelectionResponse>
    valideStripePromoCode(promoCode: string): Promise<FinancialAdvisorPromoResponseDTO>
}

export function NewSpontaneousFinancialAdvisorService():SpontaneousFinancialAdvisorService{
    return new SpontaneousFinancialadvisorServiceImpl()
}