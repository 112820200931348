import React from "react";
import { useAppSelector } from "../../../lib/redux/hook.ts";
import { badgeLabelSizeMap, BadgesColor, badgeSizeMap, BadgesSize, badgeVariantKey, colorKey } from "./style.ts";

interface Props {
    size: BadgesSize;
    label: string
    outline: boolean
    emphasis: boolean
    color: BadgesColor
    onClick?: () => void
}

export function Badges(props: Props) {
    const layoutState = useAppSelector(state => state.layout)
    return (
        <div
            style={{
                ...badgeSizeMap.get(props.size),
                ...colorKey(props.color, layoutState.theme).badgeContainerVariant.get(badgeVariantKey(props.emphasis, props.outline)),
                ...{ display: "flex", justifyContent: 'center', alignItems: 'center', gap: 4, borderWidth: '1px', borderStyle: 'solid' },
            }}
        >
            <span
                style={{
                    ...badgeLabelSizeMap.get(props.size),
                    ...{ color: colorKey(props.color, layoutState.theme).contentColor.get(badgeVariantKey(props.emphasis, props.outline)) }
                }}
            >
                {props.label}
            </span>
        </div>
    )
}