import axios from "axios";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { AcubeAccountDTO, AcubeReconnectResponseDTO } from "./dto.ts";
import { BankService } from "./service.ts";

export class BankServiceImpl implements BankService {
    public findAcubeConnectRequest(id: string): Promise<string> {
        let url: string = "/api/companies/" + id + "/acubeConnectRequest"
        return axios({
            url: url,
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findByIdAcubeGetAccount(id: string, uuid: string): Promise<AcubeAccountDTO> {
        let url: string = "/api/companies/" + id + "/acubeGetAccount/" + uuid
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public acubeReconnectRequest(id: string, uuid: string): Promise<AcubeReconnectResponseDTO> {
        let url: string = "/api/companies/" + id + "/acubeReconnectRequest/" + uuid
        return axios({
            url: url,
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public acubeGetTransaction(fiscalId: string, uuid: string, madeOnAfter: string, madeOnBefore: string): Promise<string> {
        let url: string = "/api/companies/" + fiscalId + "/acubeGetTransactions?account=" + uuid + "&madeOnAfter=" + madeOnAfter + "&madeOnBefore=" + madeOnBefore
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}