import clsx from "clsx";
import React, { ReactElement } from "react";
import { useAppSelector } from "../../../lib/redux/hook.ts";

interface Props {
    toRead: boolean
    icon: ReactElement
    label: string
    subLabel: string
    secondIcon: ReactElement
    menu?: ReactElement
}

export function GroupedRow(props: Props) {
    const { toRead } = props;
    const { icon } = props;
    const { subLabel } = props;
    const { label } = props;
    const { secondIcon } = props;
    const { menu } = props;

    const layoutState = useAppSelector(state => state.layout)

    return (
        <div
            style={{ backgroundColor: toRead ? layoutState.theme[50] : layoutState.theme[100] }}
            className={
                clsx("rounded-3xl p-4 gap-1 w-full flex flex-row items-center justify-between")}
        >
            <div className="flex flex-row gap-2 items-center">
                {icon}
                <div className={"flex flex-col gap-2"}>
                    <span className="text-label-sm text-brandSecondary-600 font-semibold">{label}</span>
                    <span className="text-label-xs text-neutral-500 font-medium">{subLabel}</span>
                </div>
            </div>
            <div className="flex flex-row items-center gap-3 cursor-pointer">
                {secondIcon}
                {menu}
            </div>
        </div>
    )
}