import { format, isValid } from "date-fns";
import React, { useEffect, useState } from "react";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { colors } from "../../../ui/colors.ts";
import { TrashIcon } from "../../../ui/icons/trashIcon.tsx";
import { UploadFileIcon } from "../../../ui/icons/uploadFileIcon.tsx";
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx";
import { FileInputComponent } from "../../../ui/molecules/fileInput/index.tsx";
import { InputComponent } from "../../../ui/molecules/input/index.tsx";
import { SelectComponent } from "../../../ui/molecules/select/index.tsx";
import { ModalComponent } from "../../../ui/organisms/modal/index.tsx";
import { DocumentCreationDTO, DocumentType, DocumentTypology } from "./dto.ts";
import { saveDocument, setOpenAddDocumentsModal } from "./slice.ts";

interface Props {
    document: "balance" | "f24" | "employee" | "accountant" | "other"
}

export function AddDocumentsModal(props: Props) {
    const { document } = props

    const dispatch = useAppDispatch()
    const documentState = useAppSelector(state => state.document)
    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)
    const layoutState = useAppSelector(state => state.layout)

    const [file, setFile] = useState<(File)[]>([])
    const [name, setName] = useState<string[]>([])
    const [formDatas, setFormDatas] = useState<FormData[]>([])
    const [typology, setTypology] = useState<DocumentTypology>("" as DocumentTypology)
    const [date, setDate] = useState<Date>(new Date())
    const [month, setMonth] = useState<string>('')
    const [year, setYear] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [receiverGroup, setReceiverGroup] = useState<'operators' | 'companies' | "">(keycloak.hasRealmRole("financial_advisor") || keycloak.hasRealmRole("financial_advisor_collaborator") ? 'operators' : 'companies')

    useEffect(() => {
        if (documentState.openAddDocumentsModal) {
            setFile([])
            setName([])
            setFormDatas([])
            setTypology("" as DocumentTypology)
            setDate(new Date())
            setMonth('')
            setYear('')
            setDescription('')
            setReceiverGroup("")
        }
    }, [documentState.openAddDocumentsModal])

    let years: { value: number, label: number }[] = []
    const startYear = 2022

    for (let i = startYear; i <= (new Date().getFullYear() + 1); i++) {
        years.push({ value: i, label: i })
    }

    let options: { value: string, label: string }[] = []
    switch (document) {
        case "balance":
            options =
                [
                    { value: DocumentTypology.balanceDocument, label: "Bilancio" },
                    { value: DocumentTypology.document770, label: "770" },
                    { value: DocumentTypology.iva, label: "IVA" },
                    { value: DocumentTypology.unique, label: "Unico" }
                ]
            break;
        case "f24":
            options =
                [
                    { value: DocumentTypology.contributions, label: "Contributi" },
                    { value: DocumentTypology.iva, label: "IVA" },
                    { value: DocumentTypology.directTax, label: "Imposte dirette" },
                    { value: DocumentTypology.other, label: "Altro" }
                ]
            break;
        case "employee":
            options =
                [
                    { value: DocumentTypology.paySlip, label: "Busta paga" },
                    { value: DocumentTypology.attendance, label: "Presenze" },
                    { value: DocumentTypology.cu, label: "CU" },
                    { value: DocumentTypology.other, label: "Altro" }
                ]
            break;
        case "accountant":
            options =
                [
                    { value: DocumentTypology.bankStatement, label: "Estratti conto" },
                    { value: DocumentTypology.invoice, label: "Fatture" },
                    { value: DocumentTypology.receipt, label: "Ricevute" },
                    { value: DocumentTypology.insurance, label: "Assicurazioni" },
                    { value: DocumentTypology.loan, label: "Finanziamenti" },
                    { value: DocumentTypology.paypal, label: "Paypal, Stripe, ecc" },
                    { value: DocumentTypology.other, label: "Altro" },
                ]
            break;
        case "other":
            options =
                [
                    { value: DocumentTypology.inspection, label: "Visura" },
                    { value: DocumentTypology.durc, label: "DURC" }
                ]
            break;
        default:
            break;
    }

    return (
        <ModalComponent
            width="large"
            open={documentState.openAddDocumentsModal}
            handleClose={() => {
                dispatch(setOpenAddDocumentsModal(false));
            }}
        >
            <div className={"p-5 bg-white rounded-xl flex flex-col gap-5 w-full"}
                style={{
                    boxShadow: '0px 12px 24px 0px rgba(22, 42, 100, 0.12)',
                }}
            >
                <div className="flex items-center justify-between">
                    <span className="text-heading-xs font-semibold text-neutral-800">Aggiungi file</span>
                </div>
                <div className="flex flex-row justify-between gap-4">
                    <div className={"flex flex-col gap-4 w-full"}>
                        <FileInputComponent
                            color={"blue"}
                            iconSize={"32"}
                            multiple
                            id={"create-financial-advisor-avatar"}
                            onChange={e => {
                                if (e !== null && e !== undefined) {
                                    const newFiles = [...file]
                                    setFile([...newFiles, ...e])
                                    const newNames = [...name]
                                    setName([...newNames, ...e.map(file => file.name)])
                                    const newformDatas = [...formDatas]
                                    let formData: FormData[] = []
                                    e.forEach(_ => formData.push(new FormData()))
                                    setFormDatas([...newformDatas, ...formData])
                                }
                            }}
                            colorIcon={layoutState.theme[500]}
                            iconBackground={layoutState.theme[100]}
                        />
                        {
                            file.length !== 0 &&
                            <div className={"rounded-xl border p-4 flex gap-1 border-green-300 bg-green-50 w-full flex-col overflow-auto"}>
                                {
                                    file.map((_, index) => (
                                        <div className={"w-full gap-2 flex flex-row"} key={index}>
                                            <InputComponent
                                                key={"accounting-area-documents-modal-input-name"}
                                                onChangeText={e => {
                                                    const newNames = [...name]
                                                    newNames[index] = e
                                                    setName([...newNames])
                                                }}
                                                id={"type"}
                                                value={name[index]}
                                                type={"text"}
                                                placeholder={"es. Fatture Acquisti Marzo 2023"}
                                                startIcon={<UploadFileIcon />}
                                                endIcon={<TrashIcon size={"20"} color={colors.neutral[500]} />}
                                                onClickEndIcon={() => {
                                                    const newFiles = [...file]
                                                    const newNames = [...name]
                                                    const newformDatas = [...formDatas]
                                                    setFile(newFiles.filter((_, _index) => _index !== index))
                                                    setName(newNames.filter((_, _index) => _index !== index))
                                                    setFormDatas(newformDatas.filter((_, _index) => _index !== index))
                                                }}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                    <div className="flex flex-col items-start gap-4 w-full">
                        <SelectComponent
                            label="Tipologia"
                            placeholder={"Seleziona la tipologia..."}
                            key={'add-document-typology'}
                            options={options}
                            onChange={(e) => setTypology(e as DocumentTypology)}
                            value={typology}
                        />
                        {
                            document === "f24" &&
                            <InputComponent
                                label="Scadenza"
                                placeholder={"Seleziona data"}
                                type="date"
                                key={"add-document-expiration-f24"}
                                value={format((date), 'yyyy-MM-dd')}
                                onChangeText={e => {
                                    if (isValid(new Date(e)))
                                        setDate(new Date(e))
                                    else {
                                        setDate(new Date())
                                    }
                                }}
                            />
                        }
                        {
                            document !== "f24" && document !== "other" &&
                            <SelectComponent
                                label="Anno di riferimento"
                                placeholder={"Seleziona l'anno..."}
                                key={'add-document-year'}
                                options={years}
                                value={year.toString()}
                                onChange={e => setYear(e!)}
                            />
                        }
                        {
                            document !== "balance" && document !== "f24" && document !== "other" &&
                            <SelectComponent
                                label="Mese scadenza"
                                placeholder={"Seleziona il mese..."}
                                key={'add-document-month'}
                                value={month.toString()}
                                options={[
                                    { value: 1, label: "Gennaio" },
                                    { value: 2, label: "Febbraio" },
                                    { value: 3, label: "Marzo" },
                                    { value: 4, label: "Aprile" },
                                    { value: 5, label: "Maggio" },
                                    { value: 6, label: "Giugno" },
                                    { value: 7, label: "Luglio" },
                                    { value: 8, label: "Agosto" },
                                    { value: 9, label: "Settembre" },
                                    { value: 10, label: "Ottobre" },
                                    { value: 11, label: "Novembre" },
                                    { value: 12, label: "Dicembre" },
                                ]}
                                onChange={(e) => { setMonth(e!) }}
                            />
                        }
                        {
                            document === "accountant" &&
                            <SelectComponent
                                label="Destinatario"
                                placeholder={"Seleziona il destinatario..."}
                                key={'add-document-received-group'}
                                options={
                                    keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("operator") ?
                                        [
                                            { value: "operators", label: "Commercialista" },
                                            { value: "companies", label: "Azienda" }
                                        ]
                                        :
                                        keycloak.hasRealmRole("financial_advisor") || keycloak.hasRealmRole("financial_advisor_collaborator") ?
                                            [
                                                { value: "companies", label: "Azienda" }
                                            ]
                                            :
                                            [
                                                { value: "operators", label: "Commercialista" },
                                            ]
                                }
                                value={receiverGroup}
                                onChange={(value) => setReceiverGroup(value as 'operators' | 'companies' | "")}
                            />
                        }
                        <InputComponent
                            type={"text"}
                            label="Note"
                            placeholder="Aggiungi qui una descrizione"
                            multiple
                            defaultValue={description}
                            onChangeText={(value) => setDescription(value)}
                        />
                    </div>
                </div>
                <div className={"flex gap-3 items-center"}>
                    <ButtonComponent
                        label="Annulla"
                        size={"sm"}
                        iconPosition={"off"}
                        variant={"outline"}
                        color={"gray"}
                        onClick={() => dispatch(setOpenAddDocumentsModal(false))}
                        fullWidth
                    />
                    <ButtonComponent
                        label={"Avvia procedura"}
                        onClick={() => {
                            formDatas.forEach(form => form.delete('file'))
                            formDatas.forEach(form => form.delete('documentCreationDTO'))
                            if (file.length > 0) {
                                formDatas.forEach((form, index) => {
                                    if (file[index] !== null) {
                                        //@ts-ignore
                                        form.append('file', file[index])
                                    }
                                });
                            }
                            let documentCreationDTO: DocumentCreationDTO = {
                                type: document === 'balance' ? DocumentType.balance : document === 'f24' ? DocumentType.f24 : document === 'employee' ? DocumentType.employees : document === 'accountant' ? DocumentType.accounting : DocumentType.other,
                                typology: typology,
                                date: format((new Date()), 'yyyy-MM-dd'),
                                referenceYear: Number(year),
                                referenceMonth: Number(month),
                                note: description,
                                fileName: 'name',
                                companyId: companyState.findByIdCompanyResponse !== undefined ? Number(companyState.findByIdCompanyResponse.id) : authState.findMeCompanyResponse !== undefined ? Number(authState.findMeCompanyResponse.id) : Number(authState.findMeReferrersResponse?.id),
                                referenceDate: format((date), 'yyyy-MM-dd'),
                                senderGroup: keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('operators') ? 'admin' : keycloak.hasRealmRole("financial_advisor") || keycloak.hasRealmRole("financial_advisor_collaborator") ? 'operators' : 'companies',
                                receiverGroup: receiverGroup as "companies" | "operators"
                            }
                            dispatch(setOpenAddDocumentsModal(false))
                            formDatas.forEach((form, index) => form.append('documentCreationDTO', JSON.stringify({ ...documentCreationDTO, fileName: name[index] })));
                            dispatch(saveDocument(formDatas))
                        }}
                        disabled={
                            file.some(f => f === null) ||
                                file.some(f => f?.size !== undefined && f.size > (10 * 1024 * 1024)) ||
                                name.some(n => n === '') ||
                                document === "f24" ?
                                (typology === '' as DocumentTypology)
                                : document === "employee" ?
                                    (typology === '' as DocumentTypology || month === '' || year === '')
                                    : document === "balance" ?
                                        (typology === '' as DocumentTypology || year === '') :
                                        document === "accountant" ?
                                            (typology === '' as DocumentTypology || month === '' || year === '' || receiverGroup === '')
                                            : (typology === '' as DocumentTypology)
                        }
                        color={"blue"}
                        variant={"solid"}
                        size={"sm"}
                        iconPosition={"off"}
                        fullWidth
                    />
                </div>
            </div>
        </ModalComponent>
    )
}