import React, { useEffect } from "react"
import { keycloak } from "../../../lib/keycloak/index.ts"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts"
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx"
import { SpinnerComponent } from "../../../ui/molecules/spinner/index.tsx"
import { ErrorPopup } from "../../../ui/organisms/errorPopup/index.tsx"
import { HeadingSize } from "../../../ui/organisms/heading/dto.ts"
import { HeadingComponent } from "../../../ui/organisms/heading/index.tsx"
import { SuccessPopup } from "../../../ui/organisms/successPopup/index.tsx"
import { CompanyStatus } from "../dto.ts"
import { findByIdCompany } from "../slice.ts"
import { BankList } from "./list.tsx"
import { findAcubeConnectRequest, findByIdAcubeGetAccount, setAcubeReconnectRequestStatus, setFindAcubeConnectRequestStatus } from "./slice.ts"

export function BankAccount() {
    const dispatch = useAppDispatch()
    const companyState = useAppSelector(state => state.company)
    const bankState = useAppSelector(state => state.bank)
    const authState = useAppSelector(state => state.auth)

    useEffect(() => {
        if (companyState.findByIdCompanyStatus === 'successfully') {
            dispatch(findByIdAcubeGetAccount({ id: companyState.findByIdCompanyResponse !== undefined ? companyState.findByIdCompanyResponse?.id : '', uuid: companyState.findByIdCompanyResponse !== undefined ? companyState.findByIdCompanyResponse?.acubeAccounts : [] }))
        }
    }, [companyState.findByIdCompanyStatus])

    useEffect(() => {
        if (keycloak.hasRealmRole("company")) {
            dispatch(findByIdAcubeGetAccount({ id: authState.findMeCompanyResponse !== undefined ? authState.findMeCompanyResponse?.id : '', uuid: authState.findMeCompanyResponse !== undefined ? authState.findMeCompanyResponse?.acubeAccounts : [] }))
        } else if (keycloak.hasRealmRole("administrative_company")) {
            dispatch(findByIdCompany(authState.findMeReferrersResponse?.company!))
        }
    }, [keycloak.hasRealmRole])

    useEffect(() => {
        if (bankState.findAcubeConnectRequestStatus === 'successfully' && bankState.findAcubeConnectRequestResponse !== undefined) {
            const link = document.createElement('a');
            link.href = bankState.findAcubeConnectRequestResponse
            link.rel = "noreferrer"
            link.target = "_blank"
            link.click();
            dispatch(setFindAcubeConnectRequestStatus('idle'))
        }
    }, [bankState.findAcubeConnectRequestStatus])

    useEffect(() => {
        if (bankState.acubeReconnectRequestStatus === 'successfully' && bankState.acubeReconnectRequestResponse !== undefined) {
            const link = document.createElement('a');
            link.href = bankState.acubeReconnectRequestResponse.reconnectUrl
            link.rel = "noreferrer"
            link.target = "_blank"
            link.click();
            dispatch(setAcubeReconnectRequestStatus('idle'))
        }
    }, [bankState.acubeReconnectRequestStatus])

    if (bankState.findByIdAcubeGetAccountStatus === "loading") {
        return (
            <div className={"flex justify-center items-center flex-grow"}>
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <div className={"flex flex-col gap-3 flex-grow"}>
            <ErrorPopup
                active={bankState.findAcubeConnectRequestStatus === 'failed'}
                close={() => dispatch(setFindAcubeConnectRequestStatus('idle'))}
                message="Si è verificato un errore nella richiesta"
            />
            <ErrorPopup
                active={bankState.acubeReconnectRequestStatus === 'failed'}
                close={() => dispatch(setAcubeReconnectRequestStatus('idle'))}
                message="Si è verificato un errore nella richiesta"
            />
            <SuccessPopup
                active={bankState.acubeReconnectRequestStatus === 'successfully'}
                close={() => dispatch(setAcubeReconnectRequestStatus('idle'))}
                message="Richiesta mandata con successo"
            />
            <SuccessPopup
                active={bankState.findAcubeConnectRequestStatus === 'successfully'}
                close={() => dispatch(setFindAcubeConnectRequestStatus('idle'))}
                message="Richiesta mandata con successo"
            />
            <HeadingComponent
                label={"Conto corrente"}
                buttons={[
                    <>
                        {
                            (keycloak.hasRealmRole("financial_advisor") || keycloak.hasRealmRole("financial_advisor_collaborator")) &&
                            <ButtonComponent
                                label={"Richiedi accesso"}
                                onClick={() => {
                                    dispatch(findAcubeConnectRequest(companyState.findByIdCompanyResponse?.id!))
                                }}
                                color={"blue"}
                                variant={"outline"}
                                size={"md"}
                                iconPosition="off"
                                disabled={companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                    (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                    (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                                }
                            />
                        }
                    </>
                ]}
                size={HeadingSize.MD}
            />
            <BankList />
        </div>
    )
}