import { createSlice } from "@reduxjs/toolkit";

interface DashboardState {
    selectedDocumentType: number
}

const initialState: DashboardState = {
    selectedDocumentType: 0
}

const DashboardSlice = createSlice({
    name: 'dashboard/slice',
    initialState,
    extraReducers(builder) {

    },
    reducers: {
        setSelectedDocumentType: (state, action) => {
            state.selectedDocumentType = action.payload
        }
    },
})

export const {
    setSelectedDocumentType
} = DashboardSlice.actions

export default DashboardSlice.reducer

