import clsx from 'clsx';
import { format } from 'date-fns';
import React, { ReactNode, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../lib/redux/hook.ts';
import { PromiseStatuses } from '../../lib/utilis/index.ts';
import { colors } from '../../ui/colors.ts';
import { CompanyIcon } from '../../ui/icons/companyIcon.tsx';
import { ShoppingBagIcon } from '../../ui/icons/shoppingBagIcon.tsx';
import { TickIcon } from '../../ui/icons/tickIcon.tsx';
import { ButtonComponent } from '../../ui/molecules/button/index.tsx';
import { InputComponent } from '../../ui/molecules/input/index.tsx';
import { PaymentSwitch } from '../../ui/molecules/paymentSwitch/index.tsx';
import { ProgressBarColor } from '../../ui/molecules/progressBar/dto.ts';
import { ProgressBar } from '../../ui/molecules/progressBar/index.tsx';
import { SpinnerComponent } from '../../ui/molecules/spinner/index.tsx';
import { ConfirmModal } from '../../ui/organisms/confirmModal/index.tsx';
import { SubscriptionChoiceCard } from '../../ui/organisms/subscriptionChoiceCard/index.tsx';
import { SuccessPopup } from '../../ui/organisms/successPopup/index.tsx';
import { findAllCompany } from '../company/slice.ts';
import { SubscriptionPeriod, subscriptionPeriodMap, SubscriptionTierLimit, subscriptionTierLimitMap, SubscriptionType } from './dto.ts';
import { deletePlanStripe, financialAdvisorActiveSubscription, findStripeProduct, selectPlanStripe, setDeletePlanStatus, setFilterFindProductPeriod, setPromoCode, setSelectedPlan, setSelectPlanStripeStatus, setUnsubscribeModal, valideStripePromoCode } from './slice.ts';
import { ErrorPopup } from '../../ui/organisms/errorPopup/index.tsx';

const cache = {}

export function SubscriptionManagement() {
  const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
  const authState = useAppSelector(state => state.auth)
  const layoutState = useAppSelector(state => state.layout)
  const companyState = useAppSelector(state => state.company)
  const dispatch = useAppDispatch()

  const [status, setStatus] = useState<PromiseStatuses>("loading")

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  let listProduct: ReactNode[] = []
  let promo: number = 0
  let finalPrice: number = 0

  const labels = [
    { label: "Annuale", value: SubscriptionType.year },
    { label: "Trimestrale", value: SubscriptionType.month },
  ];

  const formatPrice = (amount: number, type: string, name: string) => {
    let unitAmount;

    if (type === SubscriptionType.year) {
      let price = amount / 100
      let price3 = price / 12
      unitAmount = price3
    } else {
      let price = amount / 100
      let price2 = price / 3
      unitAmount = name === "Free" || name === "Start" ? price2 : price2.toFixed(1)
    }

    return unitAmount
  }

  useEffect(() => {
    if (financialAdvisorState.findByIdFinancialAdvisorStatus === "successfully" && financialAdvisorState.findByIdFinancialAdvisorResponse !== undefined) {
      dispatch(financialAdvisorActiveSubscription(financialAdvisorState.findByIdFinancialAdvisorResponse?.id))
      dispatch(findAllCompany({
        itemsPerPage: 0,
        order: false,
        page: 0,
        sort: '',
        companyName: '',
        financialAdvisorId: financialAdvisorState.findByIdFinancialAdvisorResponse?.id,
        status: ''
      }))
    }
  }, [financialAdvisorState.findByIdFinancialAdvisorStatus])

  useEffect(() => {
    if (authState.findMeFinancialAdvisorStatus === "successfully" && authState.findMeFinancialAdvisorResponse !== undefined) {
      dispatch(financialAdvisorActiveSubscription(authState.findMeFinancialAdvisorResponse?.id))
      dispatch(findAllCompany({
        itemsPerPage: 0,
        order: false,
        page: 0,
        sort: '',
        companyName: '',
        financialAdvisorId: authState.findMeFinancialAdvisorResponse?.id,
        status: ''
      }))
    }
  }, [authState.findMeFinancialAdvisorStatus])

  useEffect(() => {
    if (financialAdvisorState.deletePlanStripeStatus === "successfully") {
      dispatch(financialAdvisorActiveSubscription(financialAdvisorState.findByIdFinancialAdvisorResponse?.id!))
    }
  }, [financialAdvisorState.deletePlanStripeStatus])

  useEffect(() => {
    if (financialAdvisorState.financialAdvisorActiveSubscriptionStatus === "successfully") {
      dispatch(setFilterFindProductPeriod(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionPeriod))
      dispatch(findStripeProduct({
        period: financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionPeriod!,
        sort: true
      }))
    }
  }, [financialAdvisorState.financialAdvisorActiveSubscriptionStatus])

  useEffect(() => {
    if (
      financialAdvisorState.financialAdvisorActiveSubscriptionStatus === "successfully" &&
      financialAdvisorState.findStripeProductStatus === "successfully"
    ) {
      const cacheKey = `findStripeProduct-${financialAdvisorState.filterFindProduct.period}-${financialAdvisorState.filterFindProduct.sort}`;

      let plan;

      if (cache[cacheKey]) {
        plan = cache[cacheKey].find(name => name.name === subscriptionTierLimitMap.get(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit!));
      } else {
        plan = financialAdvisorState.findStripeProductResponse?.filter(name => name.name === subscriptionTierLimitMap.get(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit!)).find(plan => plan);

        cache[cacheKey] = financialAdvisorState.findStripeProductResponse;
      }

      dispatch(setSelectedPlan({
        id: plan?.id,
        price: formatPrice(Number(plan?.unitAmount), financialAdvisorState.filterFindProduct.period, plan?.name!),
        name: plan?.name,
        period: financialAdvisorState.filterFindProduct.period
      }));

      setStatus('successfully')
    }
  }, [financialAdvisorState.financialAdvisorActiveSubscriptionStatus, financialAdvisorState.findStripeProductStatus]);

  useEffect(() => {
    if (financialAdvisorState.selectPlanStripeStatus === "successfully" && financialAdvisorState.selectPlanStripeResponse !== undefined) {
      const link = document.createElement('a');
      link.href = financialAdvisorState.selectPlanStripeResponse.url
      link.rel = "noreferrer"
      link.target = "_blank"
      link.click();
      dispatch(setSelectPlanStripeStatus('idle'))
    }
  }, [financialAdvisorState.selectPlanStripeStatus])

  if (financialAdvisorState.valideStripePromoCodeStatus === "successfully" &&
    financialAdvisorState.valideStripePromoCodeResponse?.discountPercentage !== undefined
  ) {
    promo = Number(financialAdvisorState.selectedPlan?.price) / 100 * financialAdvisorState.valideStripePromoCodeResponse?.discountPercentage
    finalPrice = Number(financialAdvisorState.selectedPlan?.price) - promo
  }

  if (financialAdvisorState.findStripeProductResponse !== undefined) {
    financialAdvisorState.findStripeProductResponse.forEach((product) => {
      listProduct.push(
        <SubscriptionChoiceCard
          isLoading={financialAdvisorState.findStripeProductStatus === "loading" || !financialAdvisorState.selectedPlan}
          name={product.name}
          price={formatPrice(Number(product.unitAmount), financialAdvisorState.filterFindProduct.period, product.name)}
          onChange={() => {
            dispatch(setSelectedPlan({ id: product.id, price: formatPrice(Number(product.unitAmount), financialAdvisorState.filterFindProduct.period, product.name), name: product.name, period: financialAdvisorState.filterFindProduct.period }))
          }}
          checked={financialAdvisorState.selectedPlan?.id === product.id}
          infoProduct={
            product.name === "Free" ?
              <div key={product.id}>
                <div className='flex flex-row items-center gap-2'>
                  <TickIcon />
                  <span className=' font-bold text-neutral-800'>
                    1 azienda
                  </span>
                </div>
                <div className='flex flex-row items-center gap-2'>
                  <TickIcon />
                  <span className='text-text-sm text-neutral-600 font-medium'>
                    50 doc per azienda
                  </span>
                </div>
              </div>
              : product.name === "Start" ?
                <div key={product.id}>
                  <div className='flex flex-row items-center gap-2'>
                    <TickIcon />
                    <div>
                      <span className=' font-medium text-neutral-600 text-text-sm'>
                        {"Fino a "}
                      </span>
                      <span className=' font-bold text-neutral-800 text-text-sm'>
                        20 aziende
                      </span>
                    </div>
                  </div>
                  <div className='flex flex-row items-center gap-2'>
                    <TickIcon />
                    <span className='text-text-sm text-neutral-600 font-medium'>
                      50 doc per azienda
                    </span>
                  </div>
                </div>
                : product.name === "Professional" ?
                  <div key={product.id}>
                    <div className='flex flex-row items-center gap-2'>
                      <TickIcon />
                      <div>
                        <span className=' font-medium text-neutral-600 text-text-sm'>
                          {"Fino a "}
                        </span>
                        <span className=' font-bold text-neutral-800 text-text-sm'>
                          50 aziende
                        </span>
                      </div>
                    </div>
                    <div className='flex flex-row items-center gap-2'>
                      <TickIcon />
                      <span className='text-text-sm text-neutral-600 font-medium'>
                        150 doc per azienda
                      </span>
                    </div>
                  </div>
                  : product.name === "Business" ?
                    <div key={product.id}>
                      <div className='flex flex-row items-center gap-2'>
                        <TickIcon />
                        <div>
                          <span className=' font-medium text-neutral-600 text-text-sm'>
                            {"Fino a "}
                          </span>
                          <span className=' font-bold text-neutral-800 text-text-sm'>
                            100 aziende
                          </span>
                        </div>
                      </div>
                      <div className='flex flex-row items-center gap-2'>
                        <TickIcon />
                        <span className='text-text-sm text-neutral-600 font-medium'>
                          300 doc per azienda
                        </span>
                      </div>
                    </div>
                    :
                    <div key={product.id}>
                      <div className='flex flex-row items-center gap-2'>
                        <TickIcon />
                        <span className=' font-medium text-neutral-600 text-text-sm'>
                          Aziende illimitate
                        </span>
                      </div>
                      <div className='flex flex-row items-center gap-2'>
                        <TickIcon />
                        <span className='text-text-sm text-neutral-600 font-medium'>
                          Documenti illimitati
                        </span>
                      </div>
                      <div className='flex flex-row items-center gap-2'>
                        <TickIcon />
                        <span className='text-text-sm text-neutral-600 font-medium'>
                          Mobile App
                        </span>
                      </div>
                      <div className='flex flex-row items-center gap-2'>
                        <TickIcon />
                        <span className='text-text-sm text-neutral-600 font-medium'>
                          Artificial Intelligence
                        </span>
                      </div>
                    </div>
          }
        />
      )
    })
  }

  if (financialAdvisorState.financialAdvisorActiveSubscriptionStatus === "loading" || companyState.findAllCompanyStatus === "loading" || status === "loading" || financialAdvisorState.deletePlanStripeStatus === "loading") {
    return (
      <div className='flex justify-center items-center flex-grow'>
        <SpinnerComponent />
      </div>
    )
  }

  return (
    <div className='flex flex-col gap-6'>
      <SuccessPopup
        active={financialAdvisorState.deletePlanStripeStatus === "successfully"}
        close={() => dispatch(setDeletePlanStatus("idle"))}
        message='Abbonamento disdetto con successo. Potrai continuare ad usare il tuo abbonamento fino alla scadenza.'
      />
      <ErrorPopup
        active={financialAdvisorState.deletePlanStripeStatus === "failed"}
        close={() => dispatch(setDeletePlanStatus("idle"))}
        message='Si è verificato un errore durante la cancellazione del tuo abbonamento.'
      />
      <div className='w-full flex flex-row justify-around flex-grow items-center border rounded-xl p-6' style={{ borderColor: layoutState.theme[200] }}>
        <div className='flex flex-col gap-3 w-[50%]'>
          <ShoppingBagIcon size={'32'} color={layoutState.theme[400]} />
          <div className='flex flex-col'>
            <span className='text-text-sm font-medium text-neutral-500'>Il tuo piano</span>
            <span className='text-heading-xs font-bold text-neutral-850'>{"Piano " + subscriptionTierLimitMap.get(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit!)}</span>
          </div>
        </div>
        <div className='flex flex-col gap-3 w-full'>
          <div className='flex flex-col gap-2'>
            <div className='flex flex-row gap-[6px]'>
              <CompanyIcon size={'18'} color={colors.brandSecondary[500]} />
              <span className='text-text-sm font-semibold text-brandSecondary-800'>Aziende</span>
            </div>
            <ProgressBar
              value={
                financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit === SubscriptionTierLimit.unlimited ? 0 :
                  financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies && companyState.findAllCompanyResponse?.total
                    ? (companyState.findAllCompanyResponse?.total / financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies) * 100
                    : 0
              }
              color={ProgressBarColor.PRIMARY}
              active={companyState.findAllCompanyResponse?.total}
              total={financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit === SubscriptionTierLimit.unlimited ? "∞" : financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies !== undefined ? financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies.toString() : ""}
            />
          </div>
        </div>
        <div className='pl-1 flex flex-col gap-3 justify-end items-end w-[50%]'>
          <div className='flex flex-col gap-[6px]'>
            <div className='flex flex-row gap-2 items-center justify-end'>
              <span className='text-text-sm font-medium text-brandSecondary-400'>Attivazione</span>
              <span className='text-text-sm font-medium text-brandSecondary-800'>
                {financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.renewalDate && format(new Date(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.renewalDate), 'dd/MM/yyyy')}
              </span>
            </div>
            <div className='flex flex-row gap-2 items-center justify-end'>
              <span className='text-text-sm font-medium text-brandSecondary-400'>
                Rinnovo
              </span>
              <span className='text-text-sm font-medium text-brandSecondary-800'>
                {financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionExpirationDate && format(new Date(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionExpirationDate), 'dd/MM/yyyy')}
              </span>
            </div>
            <ButtonComponent
              size={'sm'}
              iconPosition={'off'}
              variant={'outline'}
              color={'blue'}
              label='Gestione abbonamento'
              onClick={() => { dispatch(setUnsubscribeModal(true)); }}
              disabled={financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionType === SubscriptionType.canceled}
            />
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-3'>
        <div className='flex flex-row justify-between items-center'>
          <span className='text-text-md font-bold text-brandSecondary-800'>Modifica il tuo piano</span>
          <PaymentSwitch
            options={labels}
            supportingText={financialAdvisorState.filterFindProduct.period === SubscriptionPeriod.year ? "10% di risparmio" : ""}
            onChange={(value) => {
              dispatch(setFilterFindProductPeriod(value))
              dispatch(findStripeProduct({
                period: value as SubscriptionPeriod,
                sort: true
              }));
            }}
            selectedValue={financialAdvisorState.filterFindProduct.period}
            disabled={financialAdvisorState.findStripeProductStatus === "loading"}
          />
        </div>
        <div className={clsx('flex flex-row gap-2 w-full')}>
          {listProduct}
        </div>
      </div>
      {
        (subscriptionTierLimitMap.get(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit!) !== financialAdvisorState.selectedPlan?.name || financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionPeriod !== financialAdvisorState.selectedPlan?.period) &&
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-2 border rounded-xl p-6 shadow-xs' style={{ borderColor: layoutState.theme[200] }}>
            <span className='font-bold text-text-md text-brandSecondary-800'>Codice sconto</span>
            <div className='flex flex-row gap-2 w-full'>
              <InputComponent
                onChangeText={(text) => dispatch(setPromoCode(text))}
                placeholder='es. NuovoCliente2024'
                type='text'
                value={financialAdvisorState.promoCode}
                typeOfText={financialAdvisorState.valideStripePromoCodeResponse?.codeFound === undefined ? "" : financialAdvisorState.valideStripePromoCodeResponse?.codeFound ? "success" : "error"}
                supportingText={financialAdvisorState.valideStripePromoCodeResponse?.codeFound === undefined ? "" : financialAdvisorState.valideStripePromoCodeResponse?.codeFound ? "Il codice sconto è stato applicato con successo!" : "Codice sconto non valido!"}
              />
              <div>
                <ButtonComponent
                  onClick={() => { dispatch(valideStripePromoCode(financialAdvisorState.promoCode)) }}
                  label={'Applica'}
                  size={'md'}
                  iconPosition={'off'}
                  variant={'outline'}
                  color={'blue'}
                  fullWidth
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-4 px-6 pb-6'>
            <span className='text-text-md font-bold text-brandSecondary-800'>Carrello</span>
            <div className='flex flex-row justify-between font-medium text-text-md border-t border-dashed border-brandSecondary-200 py-2  '>
              <span className='text-text-md font-medium text-neutral-500'>{"Piano " + financialAdvisorState.selectedPlan?.name}</span>
              <span className='font-semibold text-text-sm text-neutral-600'>
                {financialAdvisorState.findStripeProductStatus === "loading" ? <SpinnerComponent size={20} /> : "€ " + financialAdvisorState.selectedPlan?.price}
              </span>
            </div>
            {
              financialAdvisorState.valideStripePromoCodeResponse?.codeFound &&
              <div className='flex flex-row justify-between border-t border-dashed border-brandSecondary-200 py-2 '>
                <span className='text-text-md font-medium text-neutral-500'>Sconto(-{financialAdvisorState.valideStripePromoCodeResponse?.discountPercentage}%)</span>
                <span className='font-semibold text-text-sm text-neutral-600'>
                  {financialAdvisorState.findStripeProductStatus === "loading" ?
                    <SpinnerComponent size={20} /> : "€ " + promo.toFixed(2)}
                </span>
              </div>
            }
            <div className='flex flex-row justify-between border-t border-dashed border-brandSecondary-200 py-2'>
              <div className='flex flex-col '>
                <span className='font-bold text-text-lg text-neutral-800'>Totale</span>
              </div>
              {
                financialAdvisorState.findStripeProductStatus === "loading" ?
                  <SpinnerComponent size={20} /> :
                  <span className='font-bold text-heading-sm text-neutral-800'>
                    <span className='text-sm font-medium'>
                      {"€ "}
                    </span>
                    {
                      financialAdvisorState.valideStripePromoCodeResponse?.codeFound ?
                        finalPrice.toFixed(2) :
                        financialAdvisorState.selectedPlan?.price
                    }
                  </span>
              }
            </div>
            <div className='pt-6 flex flex-row gap-2'>
              <div className='w-full flex flex-row justify-between items-center'>
                <div className='text-text-xs text-neutral-500 font-semibold flex flex-col'>
                  <span>
                    {"· Scandenza " + format(new Date(currentYear, currentMonth + 1, currentDay), "dd/MM/yyyy") + ", poi per ogni mese"}
                  </span>
                  <span>
                    · È possibile controllare la data di rinnovo o annullare in qualsiasi momento.
                  </span>
                </div>
                {
                  financialAdvisorState.selectPlanStripeStatus === "loading" ?
                    <SpinnerComponent size={30} />
                    :
                    <ButtonComponent
                      label='Procedi al pagamento'
                      onClick={() => {
                        dispatch(selectPlanStripe({
                          id: financialAdvisorState.findByIdFinancialAdvisorResponse?.id!,
                          request: {
                            stripeProductId: financialAdvisorState.selectedPlan?.id!,
                            promotionalCode: financialAdvisorState.promoCode,
                            subscriptionType: subscriptionPeriodMap.get(financialAdvisorState.filterFindProduct.period)!,
                            subscriptionTierLimit: financialAdvisorState.selectedPlan?.name.toLowerCase() as SubscriptionTierLimit,
                            subscriptionPeriod: financialAdvisorState.filterFindProduct.period
                          }
                        }))
                      }}
                      size={'md'}
                      iconPosition={'off'}
                      variant={'outline'}
                      color={'blue'}
                    />
                }
              </div>
            </div>
          </div>
        </div>
      }
      <ConfirmModal
        open={financialAdvisorState.unsubscribeStripeModal}
        handleClose={() => dispatch(setUnsubscribeModal(false))}
        title={"Vuoi disdire il tuo abbonamento?"}
        description={
          <>
            Se decidi di disdire il tuo abbonamento, potrai comunque continuare a<br />
            utilizzare FiscalBright fino alla scadenza dell’attuale periodo di abbonamento.
          </>
        }
        labelDeny={"Annulla"}
        labelConfirm={"Disattiva"}
        actionConfirm={() => {
          dispatch(deletePlanStripe(financialAdvisorState.findByIdFinancialAdvisorResponse?.id!))
          dispatch(setUnsubscribeModal(false))
        }}
        isRedConfirmButton
      />
    </div>
  );
}