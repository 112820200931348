import { DocumentDTO, DocumentF24StatusUpdateDTO, DocumentFilters, DocumentReportDTO, FindAllDocumentsResponseDTO, FindExtensionsFileRequestDTO, FindExtensionsFileResponseDTO } from "./dto.ts";
import { DocumentServiceImpl } from "./serviceImpl.ts";

export interface DocumentService {
    findAllDocuments(filters: DocumentFilters): Promise<FindAllDocumentsResponseDTO>
    findByIdDocument(id: string): Promise<DocumentDTO>
    saveDocument(form: FormData): Promise<void>
    deleteDocument(id: string): Promise<void>
    documentReport(id: string): Promise<DocumentReportDTO>
    updateStatusF24(id: string, data: DocumentF24StatusUpdateDTO): Promise<void>
    findExtensionsFiles(request: FindExtensionsFileRequestDTO): Promise<FindExtensionsFileResponseDTO>
}

export function NewDocumentService(): DocumentService {
    return new DocumentServiceImpl();
}