import React from "react";
import { keycloak } from "../../../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hook.ts";
import { ReceivedIcon } from "../../../../ui/icons/receivedIcon.tsx";
import { SendingIcon } from "../../../../ui/icons/sendingIcon.tsx";
import { ButtonComponent } from "../../../../ui/molecules/button/index.tsx";
import { HeadingSize } from "../../../../ui/organisms/heading/dto.ts";
import { HeadingComponent } from "../../../../ui/organisms/heading/index.tsx";
import { HorizontalSecondaryTab } from "../../../../ui/organisms/horizontalSecondaryTab/index.tsx";
import { Pagination } from "../../../../ui/organisms/pagination/index.tsx";
import { Tab } from "../../../../ui/organisms/tab/index.tsx";
import { CompanyStatus } from "../../dto.ts";
import { AddDocumentsModal } from "../addDocumentsModal.tsx";
import { DocumentType } from "../dto.ts";
import { DocumentFilters } from "../filters.tsx";
import { setDocumentFilterFileName, setDocumentFilterMonth, setDocumentFilterPage, setDocumentFilterReceivedGroup, setDocumentFilterSenderGroup, setDocumentFilterType, setDocumentFilterTypology, setDocumentFilterYear, setFindAllDocumentsStatus, setOpenAddDocumentsModal, setSelectedSentOrReceivedTab } from "../slice.ts";
import { DocumentReceived } from "./documentReceived.tsx";
import { DocumentSent } from "./documentSent.tsx";

export function Accountant() {
    const dispatch = useAppDispatch()
    const documentState = useAppSelector(state => state.document)
    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)

    return (
        <>
            {
                (keycloak.hasRealmRole("company") || keycloak.hasRealmRole("administrative_company")) && companyState.selectedCompanyDocumentTab === 3 &&
                <HorizontalSecondaryTab>
                    <Tab
                        icon={<ReceivedIcon size={"20"} color={""} />}
                        label={"Ricevuti"}
                        selectedSecondary={documentState.selectedSentOrReceivedDocumentTab === 1}
                        onClick={() => {
                            dispatch(setFindAllDocumentsStatus("idle"))
                            dispatch(setDocumentFilterPage(0))
                            dispatch(setDocumentFilterFileName(""))
                            dispatch(setDocumentFilterMonth(0))
                            dispatch(setDocumentFilterTypology(""))
                            dispatch(setDocumentFilterYear(0))
                            dispatch(setDocumentFilterType(DocumentType.accounting))
                            dispatch(setDocumentFilterSenderGroup(""))
                            dispatch(setDocumentFilterReceivedGroup("companies"))
                            dispatch(setSelectedSentOrReceivedTab(1))
                        }}
                        type="secondary"
                    />
                    <Tab
                        icon={<SendingIcon size={"20"} color={""} />}
                        label={"Inviati"}
                        selectedSecondary={documentState.selectedSentOrReceivedDocumentTab === 0}
                        onClick={() => {
                            dispatch(setFindAllDocumentsStatus("idle"))
                            dispatch(setDocumentFilterSenderGroup("companies"))
                            dispatch(setDocumentFilterPage(0))
                            dispatch(setDocumentFilterFileName(""))
                            dispatch(setDocumentFilterMonth(0))
                            dispatch(setDocumentFilterTypology(""))
                            dispatch(setDocumentFilterYear(0))
                            dispatch(setDocumentFilterType(DocumentType.accounting))
                            dispatch(setDocumentFilterReceivedGroup(""))
                            dispatch(setSelectedSentOrReceivedTab(0))
                        }}
                        type="secondary"
                    />
                </HorizontalSecondaryTab>
            }
            <div className="flex flex-col gap-3 flex-grow">
                <HeadingComponent
                    label={"Documenti Contabili"}
                    buttons={[
                        <>
                            {
                                documentState.selectedSentOrReceivedDocumentTab === 0 &&
                                <ButtonComponent
                                    label="Aggiungi file"
                                    size={"md"}
                                    iconPosition={"off"}
                                    variant={"solid"}
                                    color={"blue"}
                                    onClick={() => dispatch(setOpenAddDocumentsModal(true))}
                                    disabled={companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                        (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                        (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator")) ||
                                        (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                        (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                                    }
                                />
                            }
                        </>
                    ]}
                    size={HeadingSize.MD}
                />
                <DocumentFilters type={DocumentType.accounting} />
                {
                    documentState.selectedSentOrReceivedDocumentTab === 0 ? <DocumentSent /> : <DocumentReceived />
                }
                {
                    documentState.findAllReceivedDocumentsResponse !== undefined &&
                    documentState.findAllReceivedDocumentsResponse.total > 0 &&
                    documentState.selectedSentOrReceivedDocumentTab === 1 &&
                    <div className="flex bg-white drop-shadow-sm items-center justify-center p-2">
                        <Pagination
                            pages={documentState.findAllReceivedDocumentsResponse.totalPage || 1}
                            currentPage={documentState.findAllReceivedDocumentsResponse?.page}
                            setPage={(page) => dispatch(setDocumentFilterPage(page))}
                        />
                    </div>
                }
                {
                    documentState.findAllSentDocumentsResponse !== undefined &&
                    documentState.findAllSentDocumentsResponse.total > 0 &&
                    documentState.selectedSentOrReceivedDocumentTab === 0 &&
                    <div className="flex bg-white drop-shadow-sm items-center justify-center p-2">
                        <Pagination
                            pages={documentState.findAllSentDocumentsResponse.totalPage || 1}
                            currentPage={documentState.findAllSentDocumentsResponse?.page}
                            setPage={(page) => dispatch(setDocumentFilterPage(page))}
                        />
                    </div>
                }
                <AddDocumentsModal document={"accountant"} />
            </div>
        </>

    )
}