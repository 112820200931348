import { format, isValid, lastDayOfMonth } from "date-fns"
import React, { useEffect, useState } from "react"
import { NewObjectService } from "../../../lib/object/service.ts"
import { useAppDispatch } from "../../../lib/redux/hook.ts"
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx"
import { InputComponent } from "../../../ui/molecules/input/index.tsx"
import { ModalComponent } from "../../../ui/organisms/modal/index.tsx"
import { acubeGetTransactions, setAcubeGetTransactionsStatus } from "./slice.ts"

interface Props {
    open: boolean
    uuid: string
    name: string
    fiscalId: string
    handleClose: (open: boolean) => void
}

export function DownloadFileAcubeAccountsModal(props: Props) {
    const { open } = props
    const { uuid } = props
    const { name } = props
    const { fiscalId } = props
    const { handleClose } = props

    const dispatch = useAppDispatch()
    const objectService = NewObjectService()

    const [fromDate, setFromDate] = useState<string>(format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'))
    const [toDate, setToDate] = useState<string>(format(lastDayOfMonth(new Date(new Date().getFullYear(), new Date().getMonth(), 1)), 'yyyy-MM-dd'))

    useEffect(() => {
        if (fromDate === undefined) {
            setFromDate(format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'))
        }
    }, [fromDate])

    return (
        <ModalComponent
            width="medium"
            open={open}
            handleClose={handleClose}
        >
            <div
                className={"p-5 bg-white rounded-xl flex flex-col gap-5 w-full"}
                style={{
                    boxShadow: '0px 12px 24px 0px rgba(22, 42, 100, 0.12)',
                }}
            >
                <div className="flex gap-1 flex-col">
                    <span className="text-text-lg font-semibold text-neutral-800">Scarica tracciato</span>
                    <span className="text-label-md font-normal text-neutral-500">Scegli intervallo di tempo</span>
                </div>
                <div style={{ margin: 0, padding: 0, gap: '12px' }} className="flex flex-row justify-center">
                    <div
                        className="flex flex-col w-full"
                        style={{ margin: 0, padding: 0 }}
                    >
                        <InputComponent id={""} type={"date"} placeholder={""} value={fromDate} onChangeText={e => {
                            if (isValid(new Date(e)))
                                setFromDate(format(new Date(e), 'yyyy-MM-dd'))
                            else {
                                setFromDate(format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'))
                            }
                        }} />
                    </div>
                    <div
                        className="flex flex-col w-full"
                        style={{ margin: 0, padding: 0 }}
                    >
                        <InputComponent id={""} type={"date"} placeholder={""} value={toDate} onChangeText={e => {
                            if (isValid(new Date(e)))
                                setToDate(format(new Date(e), 'yyyy-MM-dd'))
                            else {
                                setToDate(format(lastDayOfMonth(new Date(new Date().getFullYear(), new Date().getMonth(), 1)), 'yyyy-MM-dd'))
                            }
                        }} />
                    </div>
                </div>
                <div className={"flex gap-3 items-center"}>
                    <ButtonComponent
                        label="Annulla"
                        size={"sm"}
                        iconPosition={"off"}
                        variant={"outline"}
                        color={"gray"}
                        onClick={() => handleClose(false)}
                        fullWidth
                    />
                    <ButtonComponent
                        label={"Avvia procedura"}
                        onClick={() => {
                            dispatch(acubeGetTransactions({ fiscalId: fiscalId, uuid: uuid, madeOnAfter: fromDate, madeOnBefore: toDate })).then((e) => {
                                //@ts-ignore
                                objectService.downloadBase64WithoutExtension('transazioni-' + name, e.payload, 'text/csv')
                                dispatch(setAcubeGetTransactionsStatus('idle'))
                                handleClose(false)
                            })
                        }}
                        color={"blue"}
                        variant={"solid"}
                        size={"sm"}
                        iconPosition={"off"}
                        fullWidth
                    />
                </div>
            </div>
        </ModalComponent >
    )
}