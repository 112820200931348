import { CSSProperties } from "react";
import { useAppSelector } from "../../../lib/redux/hook.ts";
import { colors } from "../../colors.ts";

export type PillsSize = 'xs' | 'sm' | 'md'
export type PillsColor = 'blue' | 'gray' | 'green' | 'orange' | 'red'

export const pillSizeMap = new Map<PillsSize, CSSProperties>([
    ['xs', { height: '20px', borderRadius: '4px', padding: '0px 6px' }],
    ['sm', { height: '24px', borderRadius: '6px', padding: '0px 8px' }],
    ['md', { height: '28px', borderRadius: '8px', padding: '0px 10px' }],
])

export const pillLabelSizeMap = new Map<PillsSize, CSSProperties>([
    ['xs', {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '14px',
        textAlign: 'center'
    }],
    ['sm', {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '16px',
        textAlign: 'center'
    }],
    ['md', {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '16px',
        textAlign: 'center'
    }],
])



export const pillVariantKey = (emphasis: boolean, outline: boolean): string => {
    return `${emphasis}-${outline}`;
};

export const colorKey = (color: PillsColor, theme: typeof colors.brandPrimary): { pillContainerVariant: Map<string, CSSProperties>, contentColor: Map<string, string> } => {
    switch (color) {
        case 'gray':
            return (
                {
                    pillContainerVariant:
                        new Map<string, CSSProperties>([
                            [pillVariantKey(true, true), {
                                backgroundColor: colors.neutral[300],
                                borderColor: colors.neutral[600]
                            }],
                            [pillVariantKey(true, false), {
                                backgroundColor: colors.neutral[500],
                                borderColor: colors.neutral[500]
                            }],
                            [pillVariantKey(false, true), {
                                backgroundColor: colors.neutral[50],
                                borderColor: colors.neutral[200]
                            }],
                            [pillVariantKey(false, false), {
                                backgroundColor: colors.neutral[100],
                                borderColor: colors.neutral[100]
                            }]
                        ]),
                    contentColor:
                        new Map<string, string>([
                            [pillVariantKey(true, true), colors.neutral[700]],
                            [pillVariantKey(true, false), colors.white],
                            [pillVariantKey(false, true), colors.neutral[600]],
                            [pillVariantKey(false, false), colors.neutral[800]]
                        ])
                })
        case 'blue':
            return (
                {
                    pillContainerVariant:
                        new Map<string, CSSProperties>([
                            [pillVariantKey(true, true), {
                                backgroundColor: theme[100],
                                borderColor: theme[500]
                            }],
                            [pillVariantKey(true, false), {
                                backgroundColor: theme[500],
                                borderColor: theme[500]
                            }],
                            [pillVariantKey(false, true), {
                                backgroundColor: theme[50],
                                borderColor: theme[300]
                            }],
                            [pillVariantKey(false, false), {
                                backgroundColor: theme[100],
                                borderColor: theme[100]
                            }]
                        ]),
                    contentColor:
                        new Map<string, string>([
                            [pillVariantKey(true, true), theme[500]],
                            [pillVariantKey(true, false), colors.white],
                            [pillVariantKey(false, true), theme[600]],
                            [pillVariantKey(false, false), theme[800]]
                        ])
                })
        case 'green':
            return (
                {
                    pillContainerVariant:
                        new Map<string, CSSProperties>([
                            [pillVariantKey(true, true), {
                                backgroundColor: colors.green[100],
                                borderColor: colors.green[800]
                            }],
                            [pillVariantKey(true, false), {
                                backgroundColor: colors.green[500],
                                borderColor: colors.green[500]
                            }],
                            [pillVariantKey(false, true), {
                                backgroundColor: colors.green[100],
                                borderColor: colors.green[400]
                            }],
                            [pillVariantKey(false, false), {
                                backgroundColor: colors.green[100],
                                borderColor: colors.green[100]
                            }]
                        ]),
                    contentColor:
                        new Map<string, string>([
                            [pillVariantKey(true, true), colors.green[800]],
                            [pillVariantKey(true, false), colors.white],
                            [pillVariantKey(false, true), colors.green[800]],
                            [pillVariantKey(false, false), colors.green[800]]
                        ])
                })
        case 'orange':
            return (
                {
                    pillContainerVariant:
                        new Map<string, CSSProperties>([
                            [pillVariantKey(true, true), {
                                backgroundColor: colors.warning[100],
                                borderColor: colors.warning[600]
                            }],
                            [pillVariantKey(true, false), {
                                backgroundColor: colors.warning[500],
                                borderColor: colors.warning[500]
                            }],
                            [pillVariantKey(false, true), {
                                backgroundColor: colors.warning[50],
                                borderColor: colors.warning[400]
                            }],
                            [pillVariantKey(false, false), {
                                backgroundColor: colors.warning[100],
                                borderColor: colors.warning[100]
                            }]
                        ]),
                    contentColor:
                        new Map<string, string>([
                            [pillVariantKey(true, true), colors.warning[700]],
                            [pillVariantKey(true, false), colors.white],
                            [pillVariantKey(false, true), colors.warning[600]],
                            [pillVariantKey(false, false), colors.warning[800]]
                        ])
                })
        case 'red':
            return (
                {
                    pillContainerVariant:
                        new Map<string, CSSProperties>([
                            [pillVariantKey(true, true), {
                                backgroundColor: colors.red[100],
                                borderColor: colors.red[700]
                            }],
                            [pillVariantKey(true, false), {
                                backgroundColor: colors.red[500],
                                borderColor: colors.red[500]
                            }],
                            [pillVariantKey(false, true), {
                                backgroundColor: colors.red[100],
                                borderColor: colors.red[400]
                            }],
                            [pillVariantKey(false, false), {
                                backgroundColor: colors.red[100],
                                borderColor: colors.red[100]
                            }]
                        ]),
                    contentColor:
                        new Map<string, string>([
                            [pillVariantKey(true, true), colors.red[700]],
                            [pillVariantKey(true, false), colors.white],
                            [pillVariantKey(false, true), colors.red[600]],
                            [pillVariantKey(false, false), colors.red[800]]
                        ])
                })
        default:
            return ({
                pillContainerVariant: new Map<string, CSSProperties>([]),
                contentColor: new Map<string, string>([])
            })
    }
};
