import clsx from "clsx";
import React from "react";
import { useAppSelector } from "../../../lib/redux/hook.ts";
import { generatePagination } from "../../../lib/utilis/index.ts";
import { ChevronIcon } from "../../icons/pagesIcon.tsx";

interface Props {
    pages: number
    currentPage: number
    setPage: (page: number) => void
}

export function Pagination(props: Props) {
    const { pages } = props
    const { currentPage } = props
    const { setPage } = props

    const layoutState = useAppSelector(state => state.layout)

    return (
        <div className="flex h-[40px] p-[8px] gap-[8px] items-center">
            <div
                className="cursor-pointer flex flex-row gap-2 p-2"
                onClick={() => {
                    if (currentPage > 0) {
                        setPage(currentPage - 1)
                    }
                }}
            >
                <ChevronIcon size={"20"} color={layoutState.theme[700]} direction="l" />
                <span style={{ color: layoutState.theme[800] }} className="text-text-sm font-normal">
                    Precedente
                </span>
            </div>
            {
                generatePagination(currentPage, pages).map(
                    (page, index) =>
                        <div
                            key={'page-' + index}
                            onClick={() => {
                                if (typeof page === "number") {
                                    setPage(page - 1)
                                }
                            }}
                            style={{ backgroundColor: typeof page === "number" && page - 1 === currentPage ? layoutState.theme[100] : '', color: typeof page === "number" && page - 1 === currentPage ? layoutState.theme[500] : '' }}
                            className={
                                clsx(
                                    "w-[36px] text-text-md text-center rounded-lg px-[14px] py-2 flex flex-row gap-2.5 hover:bg-neutral-200", {
                                    "text-neutral-700": typeof page === "number" && page - 1 !== currentPage,
                                    "cursor-pointer": typeof page !== "string"
                                }
                                )}
                        >
                            {page}
                        </div>
                )}
            <div
                className="cursor-pointer flex flex-row gap-2 p-2"
                onClick={() => {
                    if (currentPage < pages - 1) {
                        setPage(currentPage + 1)
                    }
                }}
            >
                <span style={{ color: layoutState.theme[800] }} className="text-text-sm font-normal">
                    Successivo
                </span>
                <ChevronIcon size={"20"} color={layoutState.theme[700]} direction="r" />
            </div>
        </div>
    )
}
