import { CompanyDTO, CompanyFiltersDTO, CompanyStatus, FindAllCompanyResponseDTO } from "./dto.ts";
import { CompanyServiceImpl } from "./serviceImpl.ts";

export interface CompanyService {
    findAllCompany(filters: CompanyFiltersDTO): Promise<FindAllCompanyResponseDTO>
    createCompany(request: FormData): Promise<void>
    deleteCompany(id: string): Promise<void>
    findByIdCompany(id: string): Promise<CompanyDTO>
    editCompany(id: string, request: FormData): Promise<void>
    addCollaboratorsToCompany(id: string, collaboratorIds: string[]): Promise<void>
    changeCompanyStatus(id: string, status: CompanyStatus): Promise<void>
}

export function NewCompanyService(): CompanyService {
    return new CompanyServiceImpl();
}