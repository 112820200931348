import { CSSProperties } from "react";
import { colors } from "../../colors.ts";

export type BadgesSize = 'xs' | 'sm' | 'md' | 'lg'
export type BadgesColor = 'blue' | 'gray' | 'green' | 'orange' | 'red'

export const badgeSizeMap = new Map<BadgesSize, CSSProperties>([
    ['xs', { height: '20px', width: '20px', borderRadius: '16px', padding: '0px 6px' }],
    ['sm', { height: '24px', width: '24px', borderRadius: '16px', padding: '0px 8px' }],
    ['md', { height: '32px', width: '32px', borderRadius: '16px', padding: '0px 10px' }],
    ['lg', { height: '40px', width: '40px', borderRadius: '24px', padding: '0px 10px' }],
])

export const badgeLabelSizeMap = new Map<BadgesSize, CSSProperties>([
    ['xs', {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '14px',
        textAlign: 'center',
    }],
    ['sm', {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '16px',
        textAlign: 'center'
    }],
    ['md', {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '18px',
        textAlign: 'center'
    }],
    ['lg', {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'center'
    }],
])



export const badgeVariantKey = (emphasis: boolean, outline: boolean): string => {
    return `${emphasis}-${outline}`;
};

export const colorKey = (color: BadgesColor, theme: typeof colors.brandPrimary): { badgeContainerVariant: Map<string, CSSProperties>, contentColor: Map<string, string> } => {
    switch (color) {
        case 'gray':
            return (
                {
                    badgeContainerVariant:
                        new Map<string, CSSProperties>([
                            [badgeVariantKey(true, true), {
                                backgroundColor: colors.neutral[50],
                                borderColor: colors.neutral[600]
                            }],
                            [badgeVariantKey(true, false), {
                                backgroundColor: colors.neutral[500],
                                borderColor: colors.neutral[500]
                            }],
                            [badgeVariantKey(false, true), {
                                backgroundColor: colors.neutral[50],
                                borderColor: colors.neutral[200]
                            }],
                            [badgeVariantKey(false, false), {
                                backgroundColor: colors.neutral[100],
                                borderColor: colors.neutral[300]
                            }]
                        ]),
                    contentColor:
                        new Map<string, string>([
                            [badgeVariantKey(true, true), colors.neutral[700]],
                            [badgeVariantKey(true, false), colors.white],
                            [badgeVariantKey(false, true), colors.neutral[600]],
                            [badgeVariantKey(false, false), colors.neutral[800]]
                        ])
                })
        case 'blue':
            return (
                {
                    badgeContainerVariant:
                        new Map<string, CSSProperties>([
                            [badgeVariantKey(true, true), {
                                backgroundColor: theme[100],
                                borderColor: theme[700]
                            }],
                            [badgeVariantKey(true, false), {
                                backgroundColor: theme[500],
                                borderColor: theme[500]
                            }],
                            [badgeVariantKey(false, true), {
                                backgroundColor: theme[50],
                                borderColor: theme[300]
                            }],
                            [badgeVariantKey(false, false), {
                                backgroundColor: theme[100],
                                borderColor: theme[100]
                            }]
                        ]),
                    contentColor:
                        new Map<string, string>([
                            [badgeVariantKey(true, true), theme[700]],
                            [badgeVariantKey(true, false), colors.white],
                            [badgeVariantKey(false, true), theme[600]],
                            [badgeVariantKey(false, false), theme[800]]
                        ])
                })
        case 'green':
            return (
                {
                    badgeContainerVariant:
                        new Map<string, CSSProperties>([
                            [badgeVariantKey(true, true), {
                                backgroundColor: colors.green[100],
                                borderColor: colors.green[800]
                            }],
                            [badgeVariantKey(true, false), {
                                backgroundColor: colors.green[500],
                                borderColor: colors.green[500]
                            }],
                            [badgeVariantKey(false, true), {
                                backgroundColor: colors.green[100],
                                borderColor: colors.green[400]
                            }],
                            [badgeVariantKey(false, false), {
                                backgroundColor: colors.green[100],
                                borderColor: colors.green[100]
                            }]
                        ]),
                    contentColor:
                        new Map<string, string>([
                            [badgeVariantKey(true, true), colors.green[800]],
                            [badgeVariantKey(true, false), colors.white],
                            [badgeVariantKey(false, true), colors.green[600]],
                            [badgeVariantKey(false, false), colors.green[800]]
                        ])
                })
        case 'orange':
            return (
                {
                    badgeContainerVariant:
                        new Map<string, CSSProperties>([
                            [badgeVariantKey(true, true), {
                                backgroundColor: colors.warning[100],
                                borderColor: colors.warning[600]
                            }],
                            [badgeVariantKey(true, false), {
                                backgroundColor: colors.warning[500],
                                borderColor: colors.warning[500]
                            }],
                            [badgeVariantKey(false, true), {
                                backgroundColor: colors.warning[50],
                                borderColor: colors.warning[400]
                            }],
                            [badgeVariantKey(false, false), {
                                backgroundColor: colors.warning[100],
                                borderColor: colors.warning[100]
                            }]
                        ]),
                    contentColor:
                        new Map<string, string>([
                            [badgeVariantKey(true, true), colors.warning[700]],
                            [badgeVariantKey(true, false), colors.white],
                            [badgeVariantKey(false, true), colors.warning[600]],
                            [badgeVariantKey(false, false), colors.warning[800]]
                        ])
                })
        case 'red':
            return (
                {
                    badgeContainerVariant:
                        new Map<string, CSSProperties>([
                            [badgeVariantKey(true, true), {
                                backgroundColor: colors.red[100],
                                borderColor: colors.red[700]
                            }],
                            [badgeVariantKey(true, false), {
                                backgroundColor: colors.red[500],
                                borderColor: colors.red[500]
                            }],
                            [badgeVariantKey(false, true), {
                                backgroundColor: colors.red[100],
                                borderColor: colors.red[400]
                            }],
                            [badgeVariantKey(false, false), {
                                backgroundColor: colors.red[100],
                                borderColor: colors.red[100]
                            }]
                        ]),
                    contentColor:
                        new Map<string, string>([
                            [badgeVariantKey(true, true), colors.red[700]],
                            [badgeVariantKey(true, false), colors.white],
                            [badgeVariantKey(false, true), colors.red[600]],
                            [badgeVariantKey(false, false), colors.red[800]]
                        ])
                })
        default:
            return ({
                badgeContainerVariant: new Map<string, CSSProperties>([]),
                contentColor: new Map<string, string>([])
            })
    }


};
