import clsx from "clsx";
import React, { ReactElement } from "react";
import { OnKeyDown } from "../../../lib/utilis/index.ts";
import { colors } from "../../colors.ts";

interface Props {
    label?: string
    value?: string | number
    defaultValue?: string | number
    onChangeText?: (text: string) => void
    onClickEndIcon?: () => void
    startIcon?: ReactElement
    endIcon?: ReactElement
    disabled?: boolean
    supportingText?: string
    typeOfText?: "error" | "success" | ""
    placeholder?: string
    multiple?: boolean
    onKeyDown?: OnKeyDown
    required?: boolean
    type: string
    id?: string
}

export function InputComponent(props: Props) {
    const { label } = props;
    const { value } = props;
    const { defaultValue } = props;
    const { onChangeText } = props;
    const { onClickEndIcon } = props;
    const { startIcon } = props;
    const { endIcon } = props;
    const { disabled } = props;
    const { supportingText } = props;
    const { typeOfText } = props;
    const { placeholder } = props;
    const { multiple } = props;
    const { onKeyDown } = props;
    const { required } = props;
    const { type } = props;
    const { id } = props;

    return (
        <div className={"flex flex-col justify-center items-start gap-2 w-full"}>
            {
                label && (
                    <span className={clsx("text-label-sm text-neutral-700 font-semibold")}>
                        {label + (label && required ? ' *' : '')}
                    </span>
                )
            }
            <div className={
                clsx("h-10 rounded-lg px-3 py-[10px] border gap-2 w-full flex flex-row",
                    {
                        "bg-neutral-100": disabled,
                        "bg-white": !disabled,
                        "border-green-300": typeOfText === "success",
                        "border-red-300": typeOfText === "error",
                        "border-neutral-300": !typeOfText,
                        "h-[122px]": multiple
                    }
                )}
            >
                {
                    startIcon &&
                    <div style={{ maxHeight: '20px' }}>{startIcon}</div>
                }
                <input
                    id={id}
                    multiple={multiple}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={value}
                    defaultValue={defaultValue}
                    type={type}
                    onChange={e => onChangeText && onChangeText(e.target.value)}
                    onKeyDown={(e) => onKeyDown && onKeyDown(e)}
                    className={'text-text-sm font-medium text-left no-underline ring-0 placeholder:text-label-sm placeholder:text-neutral-400 placeholder:font-regular focus:ring-0 text-neutral-600'}
                    style={{ border: 0, outline: 0, height: '20px', backgroundColor: disabled ? colors.neutral[100] : colors.white, width: '100%' }}
                />
                {
                    endIcon &&
                    <div style={{ maxHeight: '20px', cursor: 'pointer' }} onClick={onClickEndIcon}>{endIcon}</div>
                }
            </div>
            {
                supportingText &&
                <span className={clsx("text-text-sm font-normal text-center", {
                    "text-red-500": typeOfText === "error",
                    "text-green-500": typeOfText === "success",
                    "text-neutral-400": !typeOfText
                })}
                >
                    {supportingText}
                </span>
            }
        </div>
    )
}