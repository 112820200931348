import React, { ReactElement, useState } from "react";
import { useAppSelector } from "../../../lib/redux/hook.ts";
import { ProgressBarColor } from "../../molecules/progressBar/dto.ts";
import { ProgressBar } from "../../molecules/progressBar/index.tsx";

interface Props {
    icon: ReactElement
    label: string
    subLabel: string
    thirdLabel: string
    secondIcon: ReactElement
    value?: number
    onClick?: () => void
}

export function Card(props: Props) {
    const { icon } = props
    const { label } = props
    const { subLabel } = props
    const { thirdLabel } = props
    const { secondIcon } = props
    const { value } = props
    const { onClick } = props

    const layoutState = useAppSelector(state => state.layout)
    const [isHover, setIsHover] = useState(false)

    return (
        <div className={"flex flex-col gap-2 border rounded-3xl flex-grow shadow-md"} style={{ padding: "24px 24px 16px 24px", borderColor: layoutState.theme[200] }}>
            <div className={"flex flex-col items-start gap-[16px]"}>
                <div className="w-[56px] h-[56px] rounded-[999px] p-2 flex items-center justify-center" style={{ backgroundColor: layoutState.theme[200] }}>
                    {icon}
                </div>
                <div className="flex flex-col gap-3 w-full">
                    <div className="flex flex-col gap-1">
                        <span className={"text-text-md text-brandSecondary-850 font-bold"}>{label}</span>
                        <span className={"text-label-sm font-medium text-brandSecondary-400"}>{subLabel}</span>
                    </div>
                    {
                        value !== undefined &&
                        <ProgressBar value={value} color={ProgressBarColor.PRIMARY} />
                    }
                </div>
            </div>
            <div
                className="flex flex-row items-center justify-between cursor-pointer"
                onClick={onClick}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                <span
                    style={{ color: !isHover ? layoutState.theme[500] : layoutState.theme[600] }}
                    className="text-label-sm font-medium">
                    {thirdLabel}
                </span>
                <div
                    style={{ backgroundColor: !isHover ? layoutState.theme[100] : layoutState.theme[200] }}
                    className="h-6 w-6 rounded-[999px] flex items-center justify-center"
                >
                    {secondIcon}
                </div>
            </div>
        </div>
    )
}