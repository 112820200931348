
export type radioSize = 'sm' | 'md'
const radioSizeMap = new Map<radioSize, number>(
    [
        ['sm', 4],
        ['md', 6]
    ]
)
export function getRadioSizeProperties(size: radioSize) {
    return radioSizeMap.get(size)
}



