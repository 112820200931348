import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { EditIcon } from "../../ui/icons/editIcon.tsx";
import { TrashIcon } from "../../ui/icons/trashIcon.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { ConfirmModal } from "../../ui/organisms/confirmModal/index.tsx";
import { Dropdown } from "../../ui/organisms/dropDown/index.tsx";
import { deleteOperator, findAllOperator, resetCreateOperatorRequest, setOpenConfirmModal, setOperatorId } from "./slice.ts";

export function OperatorList() {
    const operatorState = useAppSelector(state => state.operator)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [isDelete, setIsDelete] = useState<string | undefined>()

    useEffect(() => {
        dispatch(findAllOperator(operatorState.operatorFilters))
    }, [])

    useEffect(() => {
        if (operatorState.createOperatorStatus === "successfully") {
            dispatch(findAllOperator(operatorState.operatorFilters))
            dispatch(resetCreateOperatorRequest())
        }
    }, [operatorState.createOperatorStatus])

    useEffect(() => {
        if (operatorState.createOperatorStatus === "failed") {
            dispatch(resetCreateOperatorRequest())
        }
    }, [operatorState.createOperatorStatus])

    useEffect(() => {
        if (operatorState.deleteOperatorStatus === "successfully") {
            dispatch(findAllOperator(operatorState.operatorFilters))
        }
    }, [operatorState.deleteOperatorStatus])

    useEffect(() => {
        if (operatorState.editOperatorStatus === "successfully") {
            dispatch(findAllOperator(operatorState.operatorFilters))
        }
    }, [operatorState.editOperatorStatus])

    if (operatorState.findAllOperatorStatus === "loading" || operatorState.createOperatorStatus === "loading" || operatorState.deleteOperatorStatus === "loading" || operatorState.editOperatorStatus === "loading") {
        return (
            <div className={"flex justify-center items-center flex-grow"}>
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <div className="w-full flex flex-col gap-4 bg-neutral-100 rounded-tr-xl rounded-tl-xl overflow-auto">
            <div>
                <table className="w-full">
                    <thead className="h-[56px] font-semibold text-neutral-500 text-text-xs">
                        <th className="text-left">
                            <div className="flex py-4 px-4 gap-1">
                                NOME
                            </div>
                        </th>
                        {
                            operatorState.findAllOperatorResponse?.total !== 0 &&
                            <>
                                <th className="text-left">
                                    <div className="flex py-4 px-2 gap-1">
                                        COGNOME
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-2 gap-1">
                                        EMAIL
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-2 gap-1" />
                                </th>
                            </>
                        }
                    </thead>
                    <tbody>
                        {
                            operatorState.findAllOperatorResponse?.total === 0 ?
                                <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100 w-full">
                                    <td className="py-4 px-4 text-text-sm font-medium text-neutral-500">
                                        Nessun operatore
                                    </td>
                                </tr>
                                :
                                operatorState.findAllOperatorResponse?.data.map(item =>
                                    <tr
                                        className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                                        key={item.id}
                                    >
                                        <td className="py-4 px-2 text-text-sm font-semibold text-neutral-600">{item.name}</td>
                                        <td className="py-4 px-2 text-text-sm font-semibold text-neutral-600">{item.surname}</td>
                                        <td className="py-4 px-2 text-text-sm font-semibold text-neutral-600">{item.email}</td>
                                        <td className="w-[40px] cursor-pointer">
                                            <Dropdown items={[
                                                {
                                                    label: 'Modifica',
                                                    icon: <EditIcon color="" size={""} />,
                                                    onClick: () => {
                                                        dispatch(setOperatorId(item.id))
                                                        navigate('/operation-operator/' + item.id)
                                                    }
                                                },
                                                {
                                                    label: 'Cancella',
                                                    icon: <TrashIcon color="" size={""} />,
                                                    onClick: () => {
                                                        setIsDelete(item.id)
                                                        dispatch(setOpenConfirmModal(true))
                                                    }
                                                }
                                            ]} />
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
            </div>
            <ConfirmModal
                open={operatorState.openConfirmModal}
                handleClose={() => dispatch(setOpenConfirmModal(false))}
                title={"Elimina operatore"}
                description={"Sei sicuro di voler eliminare questo operatore?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    isDelete && dispatch(deleteOperator(isDelete))
                    dispatch(setOpenConfirmModal(false))
                }}
            />
        </div>
    )
}