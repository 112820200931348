import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hook.ts"
import { ButtonComponent } from "../../../../ui/molecules/button/index.tsx"
import { SelectComponent } from "../../../../ui/molecules/select/index.tsx"
import { ModalComponent } from "../../../../ui/organisms/modal/index.tsx"
import { DocumentF24Status } from "../dto.ts"
import { setUpdateStatusF24Modal, updateStatusF24 } from "../slice.ts"

export function UpdateStatusF24Modal() {
    const documentState = useAppSelector(state => state.document)
    const layoutState = useAppSelector(state => state.layout)
    const dispatch = useAppDispatch()

    const [day, setDay] = useState("")
    const [year, setYear] = useState(new Date().getFullYear())

    let years: { value: number, label: number }[] = []
    const startYear = new Date().getFullYear()

    for (let i = startYear; i <= (new Date().getFullYear() + 1); i++) {
        years.push({ value: i, label: i })
    }

    useEffect(() => {
        if (documentState.updateStatusF24Modal) {
            setDay("")
            setYear(new Date().getFullYear())
        }
    }, [documentState.updateStatusF24Modal])

    return (
        <ModalComponent
            width="medium"
            open={documentState.updateStatusF24Modal}
            handleClose={() => {
                dispatch(setUpdateStatusF24Modal(false))
            }}
        >
            <div
                style={{
                    boxShadow: '0px 12px 24px 0px rgba(22, 42, 100, 0.12)',
                }}
                className={"p-5 bg-white rounded-xl flex flex-col gap-6 w-full"}
            >
                <div className="flex flex-col item-center gap-2">
                    <span className="text-heading-xs font-semibold text-neutral-800">
                        Posticipa il pagamento F24
                    </span>
                    <span className="text-label-md font-semibold text-neutral-500">
                        Inserisci una data approssimativa entro il quale pagare l'F24.
                    </span>
                </div>
                <div className="flex flex-col items-start" style={{ gap: '16px', width: '100%' }}>
                    <SelectComponent
                        placeholder={"Seleziona data"}
                        options={[
                            { label: "16 gennaio", value: "01-16" },
                            { label: "16 febbraio", value: "02-16" },
                            { label: "16 marzo", value: "03-16" },
                            { label: "16 aprile", value: "04-16" },
                            { label: "30 aprile", value: "04-30" },
                            { label: "16 maggio", value: "05-16" },
                            { label: "16 giugno", value: "06-16" },
                            { label: "30 giugno", value: "06-30" },
                            { label: "16 luglio", value: "07-16" },
                            { label: "31 luglio", value: "07-31" },
                            { label: "20 agosto", value: "08-20" },
                            { label: "31 agosto", value: "08-31" },
                            { label: "16 settembre", value: "09-16" },
                            { label: "16 ottobre", value: "10-16" },
                            { label: "16 novembre", value: "11-16" },
                            { label: "30 novembre", value: "11-30" },
                            { label: "16 dicembre", value: "12-16" },
                            { label: "27 dicembre", value: "12-27" }
                        ]}
                        key={"update-status-f24-day"}
                        required
                        onChange={(value) => {
                            setDay(value!)
                        }}
                        value={day}
                    />
                    <SelectComponent
                        placeholder="Anno"
                        onChange={(value) => {
                            setYear(Number(value))
                        }}
                        options={years}
                        value={year}
                        key={"update-status-f24-year"}
                    />
                </div>
                <div className="flex gap-3 items-center">
                    <ButtonComponent
                        label={"Annulla"}
                        onClick={() => {
                            dispatch(setUpdateStatusF24Modal(false))
                        }}
                        color={"gray"}
                        variant={"outline"}
                        size={"sm"}
                        iconPosition="off"
                        fullWidth
                    />
                    <ButtonComponent
                        label={"Avvia procedura"}
                        onClick={() => {
                            dispatch(updateStatusF24({ id: documentState.documentId!, data: { f24Status: DocumentF24Status.Delay, updateDate: year.toString() + '-' + day, } }))
                            dispatch(setUpdateStatusF24Modal(false))
                        }}
                        color={"blue"}
                        variant={"solid"}
                        size={"sm"}
                        iconPosition="off"
                        fullWidth
                    />
                </div>
            </div>
        </ModalComponent >
    )
}