import React, { useEffect } from "react";
import { keycloak } from "../../../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hook.ts";
import { ButtonComponent } from "../../../../ui/molecules/button/index.tsx";
import { HeadingSize } from "../../../../ui/organisms/heading/dto.ts";
import { HeadingComponent } from "../../../../ui/organisms/heading/index.tsx";
import { Pagination } from "../../../../ui/organisms/pagination/index.tsx";
import { CompanyStatus } from "../../dto.ts";
import { AddDocumentsModal } from "../addDocumentsModal.tsx";
import { DocumentType } from "../dto.ts";
import { DocumentFilters } from "../filters.tsx";
import { findAllDocuments, setDocumentFilterPage, setOpenAddDocumentsModal } from "../slice.ts";
import { F24List } from "./list.tsx";

export function F24() {
    const dispatch = useAppDispatch()
    const documentState = useAppSelector(state => state.document)
    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)

    useEffect(() => {
        dispatch(findAllDocuments(documentState.documentFilters))
    }, [documentState.documentFilters.page])

    return (
        <div className="flex gap-3 flex-col flex-grow">
            <HeadingComponent
                label={"F24"}
                buttons={[
                    <>
                        {
                            !keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company") &&
                            <ButtonComponent
                                label="Aggiungi file"
                                size={"md"}
                                iconPosition={"off"}
                                variant={"solid"}
                                color={"blue"}
                                onClick={() => dispatch(setOpenAddDocumentsModal(true))}
                                disabled={companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED || (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                    (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                                }
                            />
                        }
                    </>
                ]}
                size={HeadingSize.MD}
            />
            <DocumentFilters type={DocumentType.f24} />
            <F24List />
            {
                documentState.findAllDocumentsResponse !== undefined &&
                documentState.findAllDocumentsResponse?.total > 0 &&
                <div className="flex bg-white drop-shadow-sm items-center justify-center p-2">
                    <Pagination
                        pages={documentState.findAllDocumentsResponse?.totalPage || 1}
                        currentPage={documentState.findAllDocumentsResponse.page}
                        setPage={(page) => dispatch(setDocumentFilterPage(page))}
                    />
                </div>
            }
            <AddDocumentsModal document={"f24"} />
        </div>
    )
}