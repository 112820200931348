import axios from "axios";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { DocumentDTO, DocumentF24StatusUpdateDTO, DocumentFilters, DocumentReportDTO, FindAllDocumentsResponseDTO, FindExtensionsFileRequestDTO, FindExtensionsFileResponseDTO } from "./dto.ts";
import { DocumentService } from "./service.ts";

export class DocumentServiceImpl implements DocumentService {

    public findAllDocuments(filters: DocumentFilters): Promise<FindAllDocumentsResponseDTO> {
        let url: string = "/api/documents?companyId=" + filters.companyId +
            "&itemsPerPage=" + filters.itemsPerPage +
            "&order=" + filters.order +
            "&page=" + filters.page +
            (filters.referenceMonth !== 0 ? ("&referenceMonth=" + filters.referenceMonth) : '') +
            (filters.referenceYear !== 0 ? ("&referenceYear=" + filters.referenceYear) : '') +
            (filters.senderGroup !== "" ? ("&senderGroup=" + filters.senderGroup) : '') +
            (filters.sort !== "" ? ("&sort=" + filters.sort) : '') +
            "&type=" + filters.type +
            (filters.typology !== "" ? ("&typology=" + filters.typology) : '') +
            (filters.fileName !== "" ? ("&fileName=" + filters.fileName) : '') +
            (filters.receiverGroup !== '' ? ("&receiverGroup=" + filters.receiverGroup) : '') +
            (filters.status !== "" ? ("&status=" + filters.status) : '')
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findByIdDocument(id: string): Promise<DocumentDTO> {
        let url: string = "/api/documents/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public saveDocument(form: FormData): Promise<void> {
        let url: string = "/api/documents"
        return axios({
            url: url,
            method: "POST",
            data: form,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteDocument(id: string): Promise<void> {
        let url: string = "/api/documents/" + id
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }

    public documentReport(id: string): Promise<DocumentReportDTO> {
        let url: string = "/api/documents/report/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public updateStatusF24(id: string, data: DocumentF24StatusUpdateDTO): Promise<void> {
        let url: string = "/api/documents/" + id + "/f24"
        return axios({
            url: url,
            method: "PUT",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findExtensionsFiles(request: FindExtensionsFileRequestDTO): Promise<FindExtensionsFileResponseDTO> {
        let url: string = "/api/objects/find-extension"
        return axios({
            url: url,
            method: "POST",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}
