import clsx from "clsx";
import React, { useState } from "react";
import { useAppSelector } from "../../../lib/redux/hook.ts";
import { colors } from "../../colors.ts";
import { UploadIcon } from "../../icons/uploadIcon.tsx";

interface Props {
    id: string
    infoText?: boolean
    disabled?: boolean
    error?: boolean
    multiple?: boolean
    size?: number
    onChange?: (event: File[]) => void
    colorIcon?: string
    fullHeight?: boolean
    color?: 'blue' | 'gray'
    iconSize: string
    iconBackground?: string
}

export function FileInputComponent(props: Props) {
    const { id } = props
    const { infoText } = props
    const { disabled } = props
    const { error } = props
    const { multiple } = props
    const { onChange } = props
    const { size } = props
    const { colorIcon } = props
    const { fullHeight } = props
    const { color } = props
    const { iconSize } = props
    const { iconBackground } = props

    const layuotState = useAppSelector(state => state.layout)

    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
    const [dragged, setDragged] = useState<boolean>(false);
    const [isHover, setIsHover] = useState(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length > 0) {
            onChange && onChange(Array.from(event.target.files))
        }
    };

    const max = 10 * 1024 * 1024

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!disabled) {
            setDragged(false);
            const files = event.dataTransfer.files;
            onChange && onChange(Array.from(files));
        }
    };

    return (
        <div style={{ width: '100%', opacity: (dragged ? 0.5 : 1) }}>
            <div
                id={id}
                draggable
                onClick={() => inputRef?.click()}
                onDragOver={e => {
                    if (!disabled) {
                        e.preventDefault();
                        e.stopPropagation();
                        e.dataTransfer.dropEffect = 'copy';
                        !disabled && setDragged(true);
                    }
                }}
                onDragEnter={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!disabled) {
                        setDragged(true);
                    }
                }}
                onDragLeave={e => {
                    if (!disabled) {
                        e.preventDefault();
                        e.stopPropagation();
                        !disabled && setDragged(false);
                    }
                }}
                onDrop={handleDrop}
                style={{
                    borderColor: color === "blue" ? layuotState.theme[400] : colors.neutral[100],
                    backgroundColor: color === "blue" && !isHover ? layuotState.theme[50] : color === "blue" && isHover ? layuotState.theme[100] : colors.white
                }}
                className={clsx("w-full rounded-lg flex justify-start cursor-pointer gap-4 flex-col border border-dashed", {
                    "p-2  items-start": !fullHeight,
                    "py-8 px-6  items-center": fullHeight,
                    "opacity-50": disabled
                })}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                <div className={clsx("flex justify-center items-center gap-3", {
                    "flex-row ": !fullHeight,
                    "flex-col w-full": fullHeight
                })}>
                    <UploadIcon size={iconSize} color={colorIcon === undefined ? colors.neutral[500] : colorIcon} backgroundColor={iconBackground} />
                    <span className={"text-text-md font-medium text-neutral-800"}>
                        {'Drag & drop files or '}
                        <span className='text-text-md font-medium' style={{ color: layuotState.theme[500] }}>
                            browse files
                        </span>
                    </span>
                    <input draggable name={id} id={id} ref={ref => { if (!disabled) { setInputRef(ref) } }} multiple={multiple} type="file" style={{ display: 'none' }} onChange={handleChange} />
                </div>
                {
                    infoText && fullHeight ?
                        <span className={"text-text-sm p-2 " + (size && size > max ? ' text-red-400' : ' text-neutral-400')}>PDF, DOC, TXT, ZIP - Max 2MB</span>
                        : infoText && !fullHeight ?
                            <span className={"text-text-sm p-2 " + (size && size > max ? ' text-red-400' : ' text-neutral-500')}>Dimensione massima del file: 10 MB</span>
                            : <></>
                }
            </div>
        </div>
    )
}