import axios from "axios";
import { keycloak } from "../../lib/keycloak/index.ts";
import { CompanyDTO, CompanyFiltersDTO, CompanyStatus, FindAllCompanyResponseDTO } from "./dto.ts";
import { CompanyService } from "./service.ts";

export class CompanyServiceImpl implements CompanyService {

    public findAllCompany(filters: CompanyFiltersDTO): Promise<FindAllCompanyResponseDTO> {
        let url: string = "/api/companies?" +
            "itemsPerPage=" + filters.itemsPerPage +
            "&order=" + filters.order +
            "&page=" + filters.page +
            "&sort=" + filters.sort +
            (filters.companyName !== '' ? ('&companyName=' + filters.companyName) : '') +
            (filters.financialAdvisorId !== '' ? ('&financialAdvisorId=' + filters.financialAdvisorId) : '') +
            (filters.status !== '' ? ('&status=' + filters.status) : '')
        return axios({
            url: url,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public createCompany(request: FormData): Promise<void> {
        let url: string = "/api/companies"
        return axios({
            url: url,
            data: request,
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
    public findByIdCompany(id: string): Promise<CompanyDTO> {
        let idByPath = window.location.pathname.split("/")[2]
        let _id = id;

        if (idByPath !== undefined) {
            _id = idByPath
        }

        let url: string = "/api/companies/" + _id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editCompany(id: string, request: FormData): Promise<void> {
        let url: string = "/api/companies/" + id
        return axios({
            url: url,
            method: "PUT",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteCompany(id: string): Promise<void> {
        let url: string = "/api/companies/" + id
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }

    public addCollaboratorsToCompany(id: string, collaboratorIds: string[]): Promise<void> {
        let url: string = "/api/companies/collaborators?companyId=" + id;

        collaboratorIds.forEach((collaboratorId) => {
            url += `&collaboratorIds=${collaboratorId}`;
        });

        return axios({
            url: url,
            method: "PUT",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }

    public changeCompanyStatus(id: string, status: CompanyStatus): Promise<void> {
        let url: string = "/api/companies/" + id + "/status/" + status
        return axios({
            url: url,
            method: "PUT",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }
}
