import React, { ReactNode } from "react"
import clsx from "clsx"
import { BulletPoint } from "../../molecules/bulletPoint/index.tsx"
import { ProgressStepProps } from "./dto.ts"

export function ProgressStep(props: ProgressStepProps) {
    const points: ReactNode[] = []

    props.stepMap.forEach(
        (value, key) =>{
            points.push(<BulletPoint valueToCompare={props.stepNumber} key = {key} color= {props.color} mapLength={props.stepMap.size} size= {props.size} stepNumber={key} symbol= {props.symbol} />)
        }
    )
  
    return (
      <>
        <div style={{width: 320}} className={clsx(`flex flex-row items-center`, { 'hidden': !props.isVisible })}>
          {points}
        </div>
        {props.stepMap.get(props.stepNumber)}
      </>
    )
  }