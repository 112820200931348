import React from "react";
import { IconsProps } from "./dto";

export function CloseIcon(props: IconsProps) {

    return (
        <svg onClick={props.onClick} width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18M6 6L18 18" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}