import clsx from 'clsx';
import React, { ReactElement, cloneElement, useState } from 'react';
import { useAppSelector } from '../../../lib/redux/hook.ts';
import { colors } from '../../colors.ts';
import { MenuStatus } from '../../layout/dto.ts';
import { MenuItems } from './dto.tsx';


interface Props {
  link: {
    name: string | undefined,
    href: string | undefined,
    icon: ReactElement | undefined,
    menuItem: MenuItems
  }
  menuItem: MenuItems
}

export default function NavLinks(props: Props) {
  const { link } = props
  const { menuItem } = props;
  const linkIcon = link.icon;
  const [hover, setHover] = useState(false)

  const layoutState = useAppSelector(state => state.layout)

  if (linkIcon === undefined) {
    return;
  }

  return (
    <a
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      key={link.name}
      href={link.href}
      className={
        clsx(
          "flex h-[40px] grow items-center rounded-[8px] border-1 focus:outline-none focus:shadow-xs hover:shadow-xs p-3 text-sm font-medium gap-2 w-full",
          {
            'justify-center': layoutState.menuStatus === MenuStatus.CLOSED,
            'justify-start': layoutState.menuStatus !== MenuStatus.CLOSED,
          }
        )}
      style={{ backgroundColor: menuItem === link.menuItem ? layoutState.theme[500] : '', color: menuItem === link.menuItem && !hover ? layoutState.theme[50] : menuItem !== link.menuItem && hover ? layoutState.theme[500] : colors.brandSecondary[500] }}
    >

      <div className='d-flex item-center'>
        {cloneElement(linkIcon, { color: hover && menuItem !== link.menuItem ? layoutState.theme[500] : menuItem === link.menuItem ? layoutState.theme[50] : colors.brandSecondary[500] })}
      </div>
      {layoutState.menuStatus !== MenuStatus.CLOSED &&
        <p className={clsx("md:block text-label-sm align-text-bottom font-medium", {
          "text-white": menuItem === link.menuItem
        })}>
          {link.name}
        </p>
      }
      {/* <Pills size={'sm'} label={'4'} outline={false} emphasis={pathname === link.href ? false : true} color={hover || pathname === link.href ? 'blue' : 'gray'} /> */}
    </a>
  );
}
