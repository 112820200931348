import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../lib/utilis";
import { FinancialAdvisorPromoResponseDTO, SubscriptionPeriod, SubscriptionTierLimit, SubscriptionType } from "../dto.ts";
import { PlanSelectionRequest, PlanSelectionResponse, SpontaneousFinancialAdvisorDTO } from "./dto.ts";
import { NewSpontaneousFinancialAdvisorService } from "./service.ts";

export interface SpontaneousFinancialAdvisorState {
    spontaneousFinancialAdvisorRequest: SpontaneousFinancialAdvisorDTO,
    createNewSpontaneousRequestStatus: PromiseStatuses,
    priceToPay: number,
    promoCode: string,
    valideStripePromoCodeResponse?: FinancialAdvisorPromoResponseDTO,
    valideStripePromoCodeStatus: PromiseStatuses
    selectPlanStripeStatus: PromiseStatuses,
    selectPlanStripeRequest: {
        stripeProductId: string,
        promotionalCode: string,
        subscriptionPeriod : SubscriptionPeriod,
        subscriptionType: SubscriptionType,
        subscriptionTierLimit: SubscriptionTierLimit
    },
    selectedPlan?: { id: string, price: string, name: string, period: SubscriptionType },
    selectPlanStripeSpontaneousResponse?: PlanSelectionResponse
    signupStep: number,
    id: string
}

const initialState: SpontaneousFinancialAdvisorState = {
    spontaneousFinancialAdvisorRequest: {
        name: "",
        surname: "",
        email: "",
        phoneNumber: "",
        commercialName: "",
        vat: "",
        renewalDate: new Date(),
        subscriptionType: SubscriptionType.free,
        subscriptionExpirationDate: new Date(),
        subscriptionTierLimit: SubscriptionTierLimit.free,
        primaryColor: "#3F48F3",
        note: ""
    },
    createNewSpontaneousRequestStatus: 'idle',
    id: '',
    priceToPay: 0,
    promoCode: '',
    signupStep: 1,
    selectPlanStripeRequest: {
        stripeProductId: "",
        promotionalCode: "",
        subscriptionPeriod : SubscriptionPeriod.year,
        subscriptionType: SubscriptionType.free,
        subscriptionTierLimit: SubscriptionTierLimit.free
    },
    selectPlanStripeStatus: 'idle',
    valideStripePromoCodeStatus: 'idle'
}

export const createNewSpontaneous = createAsyncThunk(
    'financialAdvisor/spontaneous',
    async (request: FormData): Promise<string> => {
        const spontaneousFinancialAdvisorService = NewSpontaneousFinancialAdvisorService()

        return spontaneousFinancialAdvisorService.createNewSpontaneous(request)
    })

export const selectPlanStripeSpontaneous = createAsyncThunk(
    'financialAdvisor/spontaneous/selectPlan',
    async (request: { id: string, request: PlanSelectionRequest }): Promise<PlanSelectionResponse> => {
        const spontaneousFinancialAdvisorService = NewSpontaneousFinancialAdvisorService()

        return spontaneousFinancialAdvisorService.selectPlanSpontaneous(request.id, request.request)
    }
)

export const valideStripePromoCode = createAsyncThunk(
    'financialAdvisor/valideStripePromoCode',
    async (promoCode: string): Promise<FinancialAdvisorPromoResponseDTO> => {
        const financialAdvisorService = NewSpontaneousFinancialAdvisorService()

        return financialAdvisorService.valideStripePromoCode(promoCode)
    }
)

const spontaneousFinancialAdvisorSlice = createSlice({
    name: 'financialAdvisor/spontaneous/slice',
    initialState: initialState,
    reducers: {
        resetSelectPlanStripeStatus: (state) => {
            state.selectPlanStripeStatus = 'idle'
        },
        setSelectPlanStripeSpontaneous: (state, action) => {
            state.selectPlanStripeRequest = action.payload
        },
        setSpontaneousFinancialAdvisorsCommercialName: (state, action) => {
            state.spontaneousFinancialAdvisorRequest.commercialName = action.payload
        },
        setSpontaneousFinancialAdvisorsName: (state, action) => {
            state.spontaneousFinancialAdvisorRequest.name = action.payload
        },
        setSpontaneousFinancialAdvisorsSurname: (state, action) => {
            state.spontaneousFinancialAdvisorRequest.surname = action.payload
        },
        setSpontaneousFinancialAdvisorsEmail: (state, action) => {
            state.spontaneousFinancialAdvisorRequest.email = action.payload
        },
        setSpontaneousFinancialAdvisorsPhoneNumber: (state, action) => {
            state.spontaneousFinancialAdvisorRequest.phoneNumber = action.payload
        },
        setSpontaneousFinancialAdvisorsVat: (state, action) => {
            state.spontaneousFinancialAdvisorRequest.vat = action.payload
        },
        setSpontaneousFinancialAdvisorsNote: (state, action) => {
            state.spontaneousFinancialAdvisorRequest.note = action.payload
        },
        setSpontaneousFinancialAdvisorsRenewalDate: (state, action) => {
            state.spontaneousFinancialAdvisorRequest.renewalDate = action.payload
        },
        setSpontaneousFinancialAdvisorsSubscriptionType: (state, action) => {
            state.spontaneousFinancialAdvisorRequest.subscriptionType = action.payload
        },
        setSpontaneousFinancialAdvisorsSubscriptionTierLimit: (state, action) => {
            state.spontaneousFinancialAdvisorRequest.subscriptionTierLimit = action.payload
        },
        setSpontaneousFinancialAdvisorsSubscriptionExpirationDate: (state, action) => {
            state.spontaneousFinancialAdvisorRequest.subscriptionExpirationDate = action.payload
        },
        setSpontaneousFinancialAdvisorsPrimaryColor: (state, action) => {
            state.spontaneousFinancialAdvisorRequest.note = action.payload
        },
        setCreateNewSpontaneousStatus: (state, action) => {
            state.createNewSpontaneousRequestStatus = action.payload
        },
        setId: (state, action) => {
            state.id = action.payload
        },
        setPriceToPay: (state, action) => {
            state.priceToPay = action.payload
        },
        setPromoCode: (state, action) => {
            state.promoCode = action.payload
        },
        setSelectedPlan: (state, action) => {
            state.selectedPlan = action.payload
        },
        setSignUpStep: (state, action) => {
            state.signupStep = action.payload
        }
    },
    extraReducers(builder) {
        builder.addCase(
            createNewSpontaneous.fulfilled, (state, action) => {
                state.createNewSpontaneousRequestStatus = 'successfully'
                state.id = action.payload
            },
        ).addCase(
            createNewSpontaneous.pending, state => {
                state.createNewSpontaneousRequestStatus = 'loading'
            }
        ).addCase(
            createNewSpontaneous.rejected, state => {
                state.createNewSpontaneousRequestStatus = 'failed'
            }
        ).addCase(selectPlanStripeSpontaneous.pending, (state) => {
            state.selectPlanStripeStatus = 'loading'
        }).addCase(selectPlanStripeSpontaneous.fulfilled, (state, action) => {
            state.selectPlanStripeStatus = 'successfully'
            state.selectPlanStripeSpontaneousResponse = action.payload
        }).addCase(selectPlanStripeSpontaneous.rejected, (state) => {
            state.selectPlanStripeStatus = 'failed'
        }).addCase(valideStripePromoCode.pending, (state) => {
            state.valideStripePromoCodeStatus = 'loading'
        }).addCase(valideStripePromoCode.fulfilled, (state, action) => {
            state.valideStripePromoCodeStatus = 'successfully'
            state.valideStripePromoCodeResponse = action.payload
            if (action.payload.codeFound)
                state.selectPlanStripeRequest.promotionalCode = state.promoCode
            else state.selectPlanStripeRequest.promotionalCode = ''
        }).addCase(valideStripePromoCode.rejected, (state) => {
            state.valideStripePromoCodeStatus = 'failed'
            state.selectPlanStripeRequest.promotionalCode = ''
        })
    }
})

export const {
    resetSelectPlanStripeStatus,
    setSelectPlanStripeSpontaneous,
    setSpontaneousFinancialAdvisorsCommercialName,
    setSpontaneousFinancialAdvisorsEmail,
    setSpontaneousFinancialAdvisorsName,
    setSpontaneousFinancialAdvisorsNote,
    setSpontaneousFinancialAdvisorsPhoneNumber,
    setSpontaneousFinancialAdvisorsSurname,
    setSpontaneousFinancialAdvisorsVat,
    setSpontaneousFinancialAdvisorsPrimaryColor,
    setSpontaneousFinancialAdvisorsRenewalDate,
    setSpontaneousFinancialAdvisorsSubscriptionExpirationDate,
    setSpontaneousFinancialAdvisorsSubscriptionTierLimit,
    setSpontaneousFinancialAdvisorsSubscriptionType,
    setCreateNewSpontaneousStatus,
    setId,
    setPriceToPay,
    setPromoCode,
    setSelectedPlan,
    setSignUpStep
} = spontaneousFinancialAdvisorSlice.actions

export default spontaneousFinancialAdvisorSlice.reducer

